import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";
import Footer from "../Footer";

const CaseStudyThree = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation();

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Ensure this is set to smooth
        block: "start", // Align the section at the top of the viewport
        inline: "nearest", // Prevent horizontal scroll if not needed
      });
    }
  };

  return (
    <>
      <div className="case-study-page">
        <Header />
        {/* Banner Section */}
        <motion.section
          className="banner-section"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <div
            className="banner-bg"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "url('/images/AdobeStock_72421384.jpeg') center/cover no-repeat",
              filter: "blur(4px)",
              zIndex: -1,
            }}
          />

          <div className="banner-content">
            <a href="/blogs" className="back-to-list">
              Back to list
            </a>
            <motion.h1
              initial={{ opacity: 0, scale: 1.1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Seamless Migration to D365: Transforming ERP for the Future
            </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>Estimated reading time: {readingMinutes} minutes</span>
            <button className="speaker" onClick={toggleSpeech} style={{backgroundColor: "#F2F2F2", color:"black"}}>
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{ marginTop: "1rem" ,
                backgroundColor: "#F2F2F2",
    border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert 
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          </div>
        </motion.section>

        {/* Main Content Section */}
        <div className="case-study-container">
          {/* Sidebar Navigation */}
          <aside className="case-study-sidebar">
            <ul>
              <li
                className={activeSection === "business-needs" ? "active" : ""}
                onClick={() => handleClick("business-needs")}
              >
                Business Needs
              </li>
              <li
                className={activeSection === "solution" ? "active" : ""}
                onClick={() => handleClick("solution")}
              >
                Solution: Precision-Driven ERP Migration to D365
              </li>
              <li
                className={activeSection === "outcomes" ? "active" : ""}
                onClick={() => handleClick("outcomes")}
              >
                Outcomes: Unmatched ERP Transformation & Efficiency
              </li>
              <li
                className={
                  activeSection === "client-testimonial" ? "active" : ""
                }
                onClick={() => handleClick("client-testimonial")}
              >
                Client Testimonial
              </li>
              <li
                className={activeSection === "future-roadmap" ? "active" : ""}
                onClick={() => handleClick("future-roadmap")}
              >
                Future Roadmap
              </li>
              <li
                className={
                  activeSection === "about-talking-rain" ? "active" : ""
                }
                onClick={() => handleClick("about-talking-rain")}
              >
                About Talking Rain Beverage Company
              </li>
            </ul>
          </aside>

          {/* Article Section */}
          <motion.section
            className="article-section"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <div className="container">
              <motion.h2
                id="business-needs"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3, duration: 1, ease: "easeOut" }}
              >
                Business Needs
              </motion.h2>
              <p>
                As Talking Rain Beverage Company continued to scale, its
                existing Business Central ERP started posing limitations in
                handling growing operational complexities. To support future
                growth and enhance operational efficiency, the company decided
                to migrate to Microsoft Dynamics 365 (D365).
              </p>
              <ul>
                <li>
                  <b>Scalability:</b> Transition to a cloud-based ERP that
                  supports expanding business operations.
                </li>
                <li>
                  <b>Process Optimization:</b> Improve automation across
                  finance, supply chain, and inventory management.
                </li>
                <li>
                  <b>Data Integrity & Consistency:</b> Ensure seamless data
                  migration with zero loss or corruption.
                </li>
                <li>
                  <b>Minimal Downtime:</b> Migrate without disrupting critical
                  business processes.
                </li>
              </ul>

              <motion.h2
  id="solution"
  initial={{ opacity: 0, x: -50 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ delay: 1, duration: 1, ease: "easeOut" }}
  style={{ marginBottom: "1rem" }} // Added spacing
>
  Solution: Precision-Driven ERP Migration to D365
</motion.h2>

<p style={{ marginBottom: "1.5rem", lineHeight: "1.6" }}>
  CyntraLabs partnered with Talking Rain to design a robust migration
  framework that ensured data integrity, business continuity, and system
  optimization.
</p>

<h3 style={{ marginBottom: "1rem" }}>Key Steps in the Migration Process</h3>

<ol style={{ paddingLeft: "1.5rem", marginBottom: "2rem" }}>
  <li style={{ marginBottom: "1.5rem" }}>
    <h3 style={{ marginBottom: "0.75rem" }}>Comprehensive System Assessment</h3>
    <ul className="sub-list" style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Conducted an in-depth analysis of Business Central to map existing
        workflows, reports, and dependencies.
      </li>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Identified critical integrations that required real-time sync with the
        new system.
      </li>
    </ul>
  </li>

  <li style={{ marginBottom: "1.5rem" }}>
    <h3 style={{ marginBottom: "0.75rem" }}>Data Extraction, Cleansing & Transformation</h3>
    <ul className="sub-list" style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Extracted structured and unstructured data from Business Central.
      </li>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Applied ETL (Extract, Transform, Load) processes to cleanse and normalize data.
      </li>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Mapped data structures to D365 to ensure seamless migration with zero corruption.
      </li>
    </ul>
  </li>

  <li style={{ marginBottom: "1.5rem" }}>
    <h3 style={{ marginBottom: "0.75rem" }}>Custom Integration Development</h3>
    <ul className="sub-list" style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Rebuilt integrations with Azure Logic Apps, Power Automate, and Function Apps.
      </li>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Enabled real-time supply chain and finance data sync between D365 and third-party systems.
      </li>
    </ul>
  </li>

  <li style={{ marginBottom: "1.5rem" }}>
    <h3 style={{ marginBottom: "0.75rem" }}>Parallel Testing & Validation</h3>
    <ul className="sub-list" style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Ran Business Central and D365 in parallel to validate transactions, reporting accuracy, and automation workflows.
      </li>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Implemented automated reconciliation scripts to detect anomalies and correct inconsistencies.
      </li>
    </ul>
  </li>

  <li style={{ marginBottom: "1.5rem" }}>
    <h3 style={{ marginBottom: "0.75rem" }}>Phased Go-Live with Zero Downtime</h3>
    <ul className="sub-list" style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Implemented a phased cutover strategy to gradually shift business operations to D365.
      </li>
      <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
        Ensured business continuity by keeping mission-critical functions active throughout the transition.
      </li>
    </ul>
  </li>
</ol>

              {/* <motion.h2
                id="solution"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1, duration: 1, ease: "easeOut" }}
              >
                Solution: Precision-Driven ERP Migration to D365
              </motion.h2>
              <p>
                CyntraLabs partnered with Talking Rain to design a robust
                migration framework that ensured data integrity, business
                continuity, and system optimization.
              </p>
              <h3>Key Steps in the Migration Process</h3>
              <ol>
                <li>
                  <h3>Comprehensive System Assessment</h3>
                  <ul class="sub-list">
                    <li>
                      Conducted an in-depth analysis of Business Central to map
                      existing workflows, reports, and dependencies.
                    </li>
                    <li>
                      Identified critical integrations that required real-time
                      sync with the new system.
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Data Extraction, Cleansing & Transformation</h3>
                  <ul class="sub-list">
                    <li>
                      Extracted structured and unstructured data from Business
                      Central.
                    </li>
                    <li>
                      Applied ETL (Extract, Transform, Load) processes to
                      cleanse and normalize data.
                    </li>
                    <li>
                      Mapped data structures to D365 to ensure seamless
                      migration with zero corruption.
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Custom Integration Development</h3>
                  <ul class="sub-list">
                    <li>
                      Rebuilt integrations with Azure Logic Apps, Power
                      Automate, and Function Apps to maintain business process
                      automation.
                    </li>
                    <li>
                      Enabled real-time supply chain and finance data sync
                      between D365 and third-party systems.
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Parallel Testing & Validation</h3>
                  <ul class="sub-list">
                    <li>
                      Ran Business Central and D365 in parallel to validate
                      transactions, reporting accuracy, and automation
                      workflows.
                    </li>
                    <li>
                      Implemented automated reconciliation scripts to detect
                      anomalies and correct inconsistencies.
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Phased Go-Live with Zero Downtime</h3>
                  <ul class="sub-list">
                    <li>
                      Implemented a phased cutover strategy to gradually shift
                      business operations to D365.
                    </li>
                    <li>
                      Ensured business continuity by keeping mission-critical
                      functions active throughout the transition.
                    </li>
                  </ul>
                </li>
              </ol> */}

              <motion.h2
                id="outcomes"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.4, duration: 1, ease: "easeOut" }}
              >
                Outcomes: Unmatched ERP Transformation & Efficiency
              </motion.h2>
              <ul>
                <li>
                  100% Data Integrity: Zero data loss, corruption, or
                  inconsistency post-migration.
                </li>
                <li>
                  Seamless Transition with Zero Downtime: Business operations
                  remained uninterrupted during the cutover.
                </li>
                <li>
                  40% Faster Processing Speed: D365’s cloud architecture
                  improved reporting and transaction execution times.
                </li>
                <li>
                  Enhanced Scalability: The new ERP system is future-proof,
                  capable of handling Talking Rain’s growing business needs.
                </li>
                <li>
                  Optimized Business Processes: Automated workflows reduced
                  manual interventions and improved productivity.
                </li>
              </ul>

              <motion.h2
                id="client-testimonial"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.8, duration: 1, ease: "easeOut" }}
              >
                Client Testimonial
              </motion.h2>
              <p>
                "CyntraLabs' expertise made our ERP migration seamless and
                risk-free. Their structured approach, technical proficiency, and
                deep understanding of our business needs ensured a successful
                transition to D365. We now operate with better efficiency,
                scalability, and automation than ever before."
              </p>

              <motion.h2
                id="future-roadmap"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 2, duration: 1, ease: "easeOut" }}
              >
                Future Roadmap
              </motion.h2>
              <p>
                With a successful ERP migration, Talking Rain is now positioned
                to:
              </p>
              <ul>
                <li>
                  Enhance AI-driven analytics for smarter decision-making within
                  D365.
                </li>
                <li>
                  Implement advanced automation for financial forecasting and
                  demand planning.
                </li>
                <li>
                  Extend Power BI integration for real-time business insights.
                </li>
              </ul>

              <motion.h2
                id="about-talking-rain"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 2.4, duration: 1, ease: "easeOut" }}
              >
                About Talking Rain Beverage Company
              </motion.h2>
              <p>
                Founded in 1987, Talking Rain is a leader in the U.S. beverage
                industry, best known for its Sparkling Ice products. As a
                company committed to innovation, Talking Rain continuously
                embraces cutting-edge technology to optimize business operations
                and drive growth.
              </p>
            </div>
          </motion.section>
        </div>
        {/* Article Section */}

        <div className="exploreblogs">
          {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
          <div className="explore-blogs">
            <h3>Featured Articles</h3>
            <ul className="explore-blogs-list">
              <li>
                <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                  Revolutionizing CI/CD for Azure Logic Apps
                </a>
              </li>
              <li>
                <a href="/blogs/Seamless-migration-d365">
                  Seamless Migration to D365: Transforming ERP for Future
                </a>
              </li>
              <li>
                <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                  Enterprise-Scale Integration Transformation: From iWay to
                  MuleSoft
                </a>
              </li>
              <li>
                <a href="/blogs/segment-cdp-data-marketing">
                  Driving Digital Transformation: Integrating Segment CDP
                </a>
              </li>

              <li>
                <a href="/blogs/Integrating-QAD-and-EAM">
                  Enhancing Eligibility Verification with Dell Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/top-10-best-reads">
                  Seamless Order Management with Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/SAP-to-Epicor">
                  SAP to Epicor Integration Using Azure Logic Apps
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Contact Us */}

<Footer/>
      </div>
    </>
  );
};

export default CaseStudyThree;
