import React, { useState,useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Facebook, Instagram, Twitter } from "lucide-react";

const Contact = () => {
   const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint
  
    const [isMobile, setIsMobile] = useState(
      window.innerWidth < MOBILE_SCREEN_SIZE
    );
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
      };
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    from_phone: "",
    from_message: "",
    services: [], // Store multiple selected services
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleServiceSelect = (e) => {
    const selectedService = e.target.value;
    if (selectedService && !formData.services.includes(selectedService)) {
      setFormData({
        ...formData,
        services: [...formData.services, selectedService],
      });
    }
  };

  const removeService = (service) => {
    setFormData({
      ...formData,
      services: formData.services.filter((s) => s !== service),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      from_name: formData.from_name,
      from_email: formData.from_email,
      from_phone: formData.from_phone,
      from_message: formData.from_message,
      services: formData.services.join(", "), // Send services as comma-separated string
    };

    emailjs
      .send(
        "service_qnby33m",
        "template_ukr1sko",
        templateParams,
        "ZIRMMGJ17azHlrbR2"
      )
      .then(
        (response) => {
          setStatus("Message sent successfully!");
          setFormData({
            from_name: "",
            from_email: "",
            from_phone: "",
            from_message: "",
            services: [],
          });
        },
        (error) => {
          setStatus("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <>
      <Header />
      <div className="contact-page">
        <div className="contact-container">
          <div className="contact-info-section">
            <h1 className="info-title">Get In Touch</h1>
            <p className="info-description">
              Connect with us today and unlock your business's potential.
            </p>
            <ul className="info-list">
              <li><strong>Phone:</strong> 847-847-4027</li>
              <li><strong>Email:</strong> info@cyntralabs.com</li>
              <li><strong>Socials:</strong> Facebook, Instagram, LinkedIn</li>
              <li><strong>Corporate Office:</strong> 828 1st Street, Secaucus, New Jersey</li>
              <li><strong>Global Development Center:</strong> B-13 Sector-1, Noida, U.P.</li>
            </ul>
            <button className="button-Know" onClick={() => navigate("/blogs")}>
              Know us more &nbsp;&nbsp;&nbsp;
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#010f1a"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>

          <div className="contact-form-section">
            <h2 className="form-title">Let's start something amazing together</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <input
                  type="text"
                  name="from_name"
                  placeholder="Name"
                  className="form-input"
                  value={formData.from_name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="email"
                  name="from_email"
                  placeholder="Email"
                  className="form-input"
                  value={formData.from_email}
                  onChange={handleChange}
                  required
                />
              </div>
              <input
                type="text"
                name="from_phone"
                placeholder="Phone"
                className="form-input"
                value={formData.from_phone}
                onChange={handleChange}
                required
              />

              <label className="form-label">Choose a service</label>
              <select
                onChange={handleServiceSelect}
                className="form-input"
                defaultValue=""
                style={{ width: '100%' }}  
              >
                <option value="" disabled>Select a service</option>
                <option value="Digital Performance & Media">Digital Performance & Media</option>
                <option value="Data & Analytics">Data & Analytics</option>
                <option value="Proprietary Technology">Proprietary Technology</option>
              </select>

              <div className="selected-services">
                {formData.services.map((service, index) => (
                  <div key={index} className="service-chip">
                    {service}
                    <span
                      className="remove-chip"
                      onClick={() => removeService(service)}
                    >
                      &times;
                    </span>
                  </div>
                ))}
              </div>

              <textarea
                name="from_message"
                placeholder="Project details"
                className="form-textarea"
                value={formData.from_message}
                onChange={handleChange}
                required
              ></textarea>

              <button type="submit" className="form-submit">Submit enquiry →</button>
              {status && <p className="contact-status">{status}</p>}
            </form>
            
          </div>
        </div>
        <div>
        <button
  className="button-talkExpert"
  onClick={() => window.open("https://outlook.office365.com/owa/calendar/SanjeevVarshney1@cyntralabs.com/bookings/", "_blank", "noopener,noreferrer")}
>
  Book a call with Expert &nbsp;&nbsp;&nbsp;
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animated-arrow"
  >
    <path
      d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
      stroke="#010f1a"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
</button></div>

           {/* Footer */}
           <section>

        {/* Footer */}
        <motion.footer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, ease: "easeOut", delay: 1 }}
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "1rem",
            fontWeight:"600",
            color: "#ffffff",
            borderTop: "1px solid #333",
            paddingTop: "1.25rem",
            marginTop: "9rem",
          }}
        >
          © 2025 CyntraLabs. All rights reserved. | Privacy Policy | Terms of
          Service | Data Privacy Terms
        </motion.footer>
      </section>
      </div>
    </>
  );
};

export default Contact;
