import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Youtube, Instagram, Linkedin } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";
import Footer from "../Footer";

const CaseStudySeven = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );
  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation(); // Initialize animation controls for framer-motion

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Ensure this is set to smooth
        block: "start", // Align the section at the top of the viewport
        inline: "nearest", // Prevent horizontal scroll if not needed
      });
    }
  };

  return (
    <>
      <div className="case-study-page">
        <Header />
        {/* Banner Section */}
        <motion.section
          className="banner-section"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          {/* Blurred Background Layer */}
          <div
            className="banner-bg"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "url('/images/people-working-lightbulb-color-year-2023.jpg') center/cover no-repeat",
              filter: "blur(4px)" /* ✅ Background blur applied */,
              zIndex: -1,
            }}
          />

          {/* Content inside the banner section */}
          <div className="banner-content">
            <a href="/blogs" className="back-to-list">
              Back to list
            </a>
            <motion.h1
              initial={{ opacity: 0, scale: 1.1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Enhancing Eligibility Verification with Dell Boomi
            </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>Estimated reading time: {readingMinutes} minutes</span>
            <button className="speaker" onClick={toggleSpeech} style={{backgroundColor: "#F2F2F2", color:"black"}}>
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{ marginTop: "1rem" ,
                backgroundColor: "#F2F2F2",
    border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert 
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          </div>
        </motion.section>

        {/* Main Content Section */}
        <div className="case-study-container">
          {/* Sidebar Navigation */}
          <aside className="case-study-sidebar">
            <ul>
              <li
                className={activeSection === "The Challenge" ? "active" : ""}
                onClick={() => handleClick("The Challenge")}
              >
                The Challenge
              </li>
              <li
                className={activeSection === "Our Approach" ? "active" : ""}
                onClick={() => handleClick("Our Approach")}
              >
                Our Approach
              </li>
              <li
                className={activeSection === "Key Enhancements" ? "active" : ""}
                onClick={() => handleClick("Key Enhancements")}
              >
                Key Enhancements
              </li>
              <li
                className={
                  activeSection === "The Business Impact" ? "active" : ""
                }
                onClick={() => handleClick("The Business Impact")}
              >
                The Business Impact
              </li>
              <li
                className={
                  activeSection === "Client Testimonial" ? "active" : ""
                }
                onClick={() => handleClick("Client Testimonial")}
              >
                Client Testimonial
              </li>
              <li
                className={activeSection === "Future Roadmap" ? "active" : ""}
                onClick={() => handleClick("Future Roadmap")}
              >
                Future Roadmap
              </li>
              <li
                className={activeSection === "Conclusion" ? "active" : ""}
                onClick={() => handleClick("Conclusion")}
              >
                Conclusion
              </li>
            </ul>
          </aside>

          {/* Article Section */}
          <motion.section
            className="article-section"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <div className="container">
              <motion.h2
                id="The Challenge"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3, duration: 1, ease: "easeOut" }}
              >
                The Challenge
              </motion.h2>
              <p>
                DHS Georgia needed a modern, automated eligibility verification
                system to streamline government assistance processing. The
                existing approach faced significant hurdles:
              </p>

              <ul>
                <li>
                  Manual inefficiencies led to slow response times and increased
                  operational costs.
                </li>
                <li>
                  Data discrepancies across multiple systems caused inaccurate
                  eligibility determinations.
                </li>
                <li>
                  Scalability concerns prevented the system from handling
                  growing demand efficiently.
                </li>
                <li>
                  Security risks needed to be mitigated to comply with
                  government data protection policies.
                </li>
              </ul>

              <motion.h2
                id="Our Approach"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1, duration: 1, ease: "easeOut" }}
              >
                Our Approach
              </motion.h2>
              <p>
                We designed and implemented a Dell Boomi-powered integration
                solution to provide a seamless, automated, and highly secure
                eligibility verification process. This solution enabled
                real-time data validation and eligibility assessment while
                ensuring compliance with strict regulatory requirements.
              </p>

              <motion.h2
                id="Key Enhancements"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
              >
                Key Enhancements
              </motion.h2>
              <ul>
                <li>
                  <strong>Automated Eligibility Processing:</strong> Eliminated
                  manual interventions, reducing errors and processing time.
                </li>
                <li>
                  <strong>Seamless System Integration:</strong> Connected
                  disparate data sources to ensure accurate, real-time
                  eligibility checks.
                </li>
                <li>
                  <strong>Improved Response Time:</strong> Reduced verification
                  time from 10+ seconds to under 2 seconds through optimized
                  workflows.
                </li>
                <li>
                  <strong>Scalability for Future Needs:</strong> Designed to
                  support thousands of concurrent requests without performance
                  degradation.
                </li>
                <li>
                  <strong>Enhanced Security & Compliance:</strong> Implemented
                  stringent security measures to protect sensitive citizen data.
                </li>
              </ul>
              <motion.h2
                id="The Business Impact"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
              >
                The Business Impact
              </motion.h2>
              <ul>
                <li>
                  <strong>99.9% System Uptime:</strong> Ensured high
                  availability for uninterrupted service.
                </li>
                <li>
                  <strong>2x Faster Processing:</strong> Increased efficiency,
                  reducing operational costs and enhancing citizen experience.
                </li>
                <li>
                  <strong> Higher Accuracy Rates:</strong> Minimized
                  discrepancies, achieving an error rate of less than 0.1%.
                </li>
                <li>
                  <strong> Future-Ready Platform:</strong> Positioned DHS
                  Georgia to easily scale and adapt to evolving program
                  requirements.
                </li>
              </ul>

              <motion.h2
                id="Client Testimonial"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
              >
                Client Testimonial
              </motion.h2>
              <p>
                <i>
                  The new eligibility verification system has been a
                  game-changer for us. It has significantly improved processing
                  speeds, enhanced accuracy, and provided a seamless experience
                  for our teams. We now have a future-proof solution that can
                  handle growing demands efficiently." – DHS Georgia
                  Representative
                </i>
              </p>

              <motion.h2
                id="Future Roadmap"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.9, duration: 1, ease: "easeOut" }}
              >
                Future Roadmap
              </motion.h2>
              <p>
                With a successful ERP migration, Talking Rain is now positioned
                to:
              </p>
              <ul>
                <li>
                  Enhance AI-driven analytics for smarter decision-making within
                  D365.
                </li>
                <li>
                  Implement advanced automation for financial forecasting and
                  demand planning.
                </li>
                <li>
                  Extend Power BI integration for real-time business insights.
                </li>
              </ul>

              <motion.h2
                id="about-talking-rain"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 2.2, duration: 1, ease: "easeOut" }}
              >
                Conclusion
              </motion.h2>
              <p>
                By implementing this Dell Boomi-powered eligibility verification
                system, DHS Georgia has transformed how it processes and
                validates government assistance eligibility. The new solution
                not only enhances operational efficiency and accuracy but also
                sets a benchmark for modern, cloud-based integration in
                government services.
              </p>
            </div>
          </motion.section>
        </div>
        {/* Article Section */}

        <div className="exploreblogs">
          {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
          <div className="explore-blogs">
            <h3>Featured Articles</h3>
            <ul className="explore-blogs-list">
              <li>
                <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                  Revolutionizing CI/CD for Azure Logic Apps
                </a>
              </li>
              <li>
                <a href="/blogs/Seamless-migration-d365">
                  Seamless Migration to D365: Transforming ERP for Future
                </a>
              </li>
              <li>
                <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                  Enterprise-Scale Integration Transformation: From iWay to
                  MuleSoft
                </a>
              </li>
              <li>
                <a href="/blogs/segment-cdp-data-marketing">
                  Driving Digital Transformation: Integrating Segment CDP
                </a>
              </li>

              <li>
                <a href="/blogs/Integrating-QAD-and-EAM">
                  Enhancing Eligibility Verification with Dell Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/top-10-best-reads">
                  Seamless Order Management with Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/SAP-to-Epicor">
                  SAP to Epicor Integration Using Azure Logic Apps
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Contact Us */}
<Footer/>
      </div>
    </>
  );
};

export default CaseStudySeven;
