import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate,Link } from "react-router-dom";

import "./Header.css";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openOfferDropdown, setOpenOfferDropdown] = useState(false);
  const [openKnowledgeDropdown, setOpenKnowledgeDropdown] = useState(false);
  const [openAboutDropdown, setOpenAboutDropdown] = useState(false);
const [openPartnersDropdown, setOpenPartnersDropdown] = useState(false);


   const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 440);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };
// Function to control dropdown delay
const handleDropdownHover = (setIsOpen) => {
  let timeoutId;
  return {
    onMouseEnter: () => {
      clearTimeout(timeoutId);
      setIsOpen(true);
    },
    onMouseLeave: () => {
      timeoutId = setTimeout(() => setIsOpen(false), 800); // Adjust delay time here (800ms = 0.8s)
    },
  };
};

  return (
    <>
      <header className={`homepage-header-v2 ${isScrolled && !isMobile ?  "fixed-header-v2" : ""}`}>
      <Link to="/">  {/* Route to Homepage on click */}
  <img src="/images/Logo 1.svg" alt="CyntraLabs Logo" className="mobile-fixed-logo-v2" />
</Link>

        {isMobile ? (
          <>
          <button
                className="fixed-call-mobile"
                onClick={() => navigate("/Get-In-Touch")}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#4A90D9")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#5BA5E9")
                }
              >
                Schedule a Call
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animated-arrow"
                >
                  <path
                    d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                    stroke="#010f1a"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
     <IconButton 
  onClick={toggleDrawer(true)} 
  className="menu-button-v2"
  style={{ color: "white" }} // This makes the icon white
>
  <MenuIcon fontSize="large" />
</IconButton>

            <SwipeableDrawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <div className="mobile-nav-v2">
                <button onClick={() => setOpenOfferDropdown(true)} className="mobile-nav-button-v2">
                  What We Offer 
                  <span className={`dropdown-icon-v2 ${openOfferDropdown ? "rotate-v2" : ""}`}>
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                  </span>
                </button>
                {openOfferDropdown && (
                  <div className="mobile-dropdown-v2">
                    <ul>
                      {[
                        { name: "Integration Solutions", path: "/Migrating-ERPSolutions" },
                        { name: "Enterprise Integration Solutions", path: "/ERP-Solutions" },
                        { name: "Cloud-Native Excellence: CI/CD, DevOps & Automation", path: "/Devops-Services" },
                        { name: "Application Management & Support", path: "/AMS-Services" },
                        { name: "ERP Consulting & End-to-End Solutions", path: "/ERP-Consulting" },
                      ].map((item, index) => (
                        <li key={index}>
                          <button onClick={() => { navigate(item.path); setDrawerOpen(true); }} className="dropdown-button-v2">
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Knowledge Dropdown */}
                <button onClick={() => setOpenKnowledgeDropdown(!openKnowledgeDropdown)} className="mobile-nav-button-v2">
                  Knowledge
                  <span className={`dropdown-icon-v2 ${openKnowledgeDropdown ? "rotate-v2" : ""}`}>
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                  </span>
                </button>
                {openKnowledgeDropdown && (
                  <div className="mobile-dropdown-v2">
                    <ul>
                      {[
                        { name: "Success Stories", path: "/blogs" },
                        { name: "White Papers", path: "/White-Papers" },
                      ].map((item, index) => (
                        <li key={index}>
                          <button onClick={() => { navigate(item.path); setDrawerOpen(false); }} className="dropdown-button-v2">
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

     {/* About Dropdown */}
     <button onClick={() => setOpenAboutDropdown(!openAboutDropdown)} className="mobile-nav-button-v2">
                  About
                  <span className={`dropdown-icon-v2 ${openAboutDropdown ? "rotate-v2" : ""}`}>
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                  </span>
                </button>
                {openAboutDropdown && (
                  <div className="mobile-dropdown-v2">
                <ul>
  {[
    { name: "About Us", path: "/AboutUs" },
    {
      name: "Careers",
      path: () => window.open("https://cyntra.ai/careers"), // Use window.open directly
    },
  ].map((item, index) => (
    <li key={index}>
      <button
        onClick={() => {
          if (typeof item.path === "function") {
            item.path(); // Call the function if it's for "Careers"
          } else {
            navigate(item.path); // Navigate for other paths
          }
        }}
        className="dropdown-button"
      >
        {item.name}
      </button>
    </li>
  ))}
</ul>

                  </div>
                )}

   {/* Partners Dropdown */}
   <button onClick={() => setOpenPartnersDropdown(!openPartnersDropdown)} className="mobile-nav-button-v2">
                  Our Partners
                  <span className={`dropdown-icon-v2 ${openPartnersDropdown ? "rotate-v2" : ""}`}>
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                  </span>
                </button>
                {openPartnersDropdown && (
                  <div className="mobile-dropdown-v2">
                    <ul>
                      {[
                             { name: "Service Now", path: "/Service-Now" },
                             { name: "Google Cloud", path: "/Google-Cloud" },
                             { name: "Salesforce", path: "/Salesforce" },
                             { name: "SAP Labs", path: "/SAP-Labs" },
                      ].map((item, index) => (
                        <li key={index}>
                          <button onClick={() => { navigate(item.path); setDrawerOpen(false); }} className="dropdown-button-v2">
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}



                {/* Contact & Careers Links */}
                <button onClick={() => { navigate('/Get-In-Touch'); setDrawerOpen(false); }}>Contact</button>
                {/* <button onClick={() => { window.open('https://cyntra.ai/careers', '_blank'); setDrawerOpen(false); }}>Careers</button> */}
                {/* <button onClick={() => { navigate('/AboutUs'); setDrawerOpen(false); }}>About Us</button> */}
              </div>
            </SwipeableDrawer>
          </>
        ) : (
          <>
             <nav className="homepage-nav-v2">
      <Link to="/">
        <img src="/images/CYNTRALABS Logo.png" alt="CyntraLabs Logo" className="mobile-fixed-logo-v2" width={"100%"} />
      </Link>
  
      {/* What we offer Dropdown */}
      <div className="dropdown-container-v2" {...handleDropdownHover(setOpenOfferDropdown)}>
        <a className="nav-link-v2">
          What we offer
          <span className={`dropdown-icon-v2 ${openOfferDropdown ? "rotate-v2" : ""}`}>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </span>
        </a>
        {openOfferDropdown && (
          <div className="dropdown-menu-v2">
            <ul>
              {[
                { name: "Integration Solutions", path: "/Migrating-ERPSolutions" },
                { name: "Enterprise Integration Solutions", path: "/ERP-Solutions" },
                { name: "Cloud-Native Excellence: CI/CD, DevOps & Automation", path: "/Devops-Services" },
                { name: "Application Management & Support", path: "/AMS-Services" },
                { name: "ERP Consulting & End-to-End Solutions", path: "/ERP-Consulting" },
              ].map((item, index) => (
                <li key={index}>
                  <button onClick={() => navigate(item.path)} className="dropdown-button-v2">
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Knowledge Dropdown */}
      <div className="dropdown-container-v2" {...handleDropdownHover(setOpenKnowledgeDropdown)}>
        <a className="nav-link-v2">
          Knowledge 
          <span className={`dropdown-icon-v2 ${openKnowledgeDropdown ? "rotate-v2" : ""}`}></span>
          <span className={`dropdown-icon-v2 ${openOfferDropdown ? "rotate-v2" : ""}`}>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </span>
        </a>
        {openKnowledgeDropdown && (
          <div className="dropdown-menu-v2">
            <ul>
              {[
                { name: "Success Stories", path: "/blogs" },
                { name: "White Papers", path: "/White-Papers" },
              ].map((item, index) => (
                <li key={index}>
                  <button onClick={() => navigate(item.path)} className="dropdown-button-v2">
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* About Dropdown */}
      <div className="dropdown-container-v2" {...handleDropdownHover(setOpenAboutDropdown)}>
        <a className="nav-link-v2">
          About
          <span className={`dropdown-icon-v2 ${openAboutDropdown ? "rotate-v2" : ""}`}></span>
          <span className={`dropdown-icon-v2 ${openOfferDropdown ? "rotate-v2" : ""}`}>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </span>
        </a>
        {openAboutDropdown && (
          <div className="dropdown-menu-v2">
        <ul>
  {[
    { name: "About Us", path: "/AboutUs" },
    {
      name: "Careers",
      path: () => window.open("https://cyntra.ai/careers"), // Use window.open directly
    },
  ].map((item, index) => (
    <li key={index}>
      <button
        onClick={() => {
          if (typeof item.path === "function") {
            item.path(); // Call the function if it's for "Careers"
          } else {
            navigate(item.path); // Navigate for other paths
          }
        }}
        className="dropdown-button"
      >
        {item.name}
      </button>
    </li>
  ))}
</ul>

          </div>
        )}
      </div>

  {/* Partners Dropdown */}
  <div className="dropdown-container-v2">
                <a className="nav-link-v2" onClick={() => setOpenPartnersDropdown(!openPartnersDropdown)}>
               Our Partners
                  <span className={`dropdown-icon-v2 ${openPartnersDropdown ? "rotate-v2" : ""}`}>
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                  </span>
                </a>
                {openPartnersDropdown && (
                  <div className="dropdown-menu-v2">
                    <ul>
                      {[
                      { name: "Service Now", path: "/Service-Now" },
                      { name: "Google Cloud", path: "/Google-Cloud" },
                      { name: "SAP Labs", path: "/SAP-Labs" },
                      { name: "Salesforce", path: "/Salesforce" },
                      ].map((item, index) => (
                        <li key={index}>
                          <button onClick={() => navigate(item.path)} className="dropdown-button-v2">
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

      {/* Contact Link */}
      <a href="/Get-In-Touch" className="nav-link-v2">Contact</a>
      <button
      className="fixed-schedulecall-button"  // Added hide-on-mobile class
      style={{ top: "1rem", position: "absolute" }}  // Inline CSS
      onClick={() => navigate("/Get-In-Touch")}  // Navigate on click
    >
      Schedule a Call
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animated-arrow"
      >
        <path
          d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
          stroke="#010f1a"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </button>

    </nav>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
