import React, { useState, useEffect } from "react";
import "./SystemIntegration.css";
import "./DataIntegration.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import { motion } from "framer-motion";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";

const ERPSolutions = () => {
     const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header />
      {/* System Integration Section with Background */}
      <div
        className="system-integration"
        style={{
          background:
            "url('/images/close-up-woman-hand-table.jpg') no-repeat center center / cover",
        }}
      >
        <div className="header h1">
          <h1>
            Enterprise <br /> Integration Solutions
          </h1>
        </div>
      </div>

      {/* Bridge Diverse Processes Swiftly Section */}
      <motion.div
        className="bridge-container"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: false }}
      >
        {/* Left Section */}
        <motion.div
          className="bridge-left"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        >
          <h1>
            We craft seamless, scalable integrations that keep systems connected
            and workflows effortless. Our expertise ensures secure,
            high-performance data flow across the enterprise—fast, flexible, and
            future-ready.
          </h1>
          <button className="button-Schedule" onClick={() => navigate ('/Get-In-Touch')}>
          Talk to our expert 
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animated-arrow"
  >
    <path
      d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
      stroke="#042E50"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
</button>

        </motion.div>

        {/* Vertical Line */}
        <motion.div
          className="vertical-line"
          style={{
            borderLeft: "0.0625rem solid #797979",
            height: "auto",
            minHeight: "100vh",
            marginRight: "70px",
            marginTop: "50px",
            marginLeft: "50px",
          }}
          initial={{ opacity: 0, scaleY: 0 }}
          whileInView={{ opacity: 1, scaleY: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        ></motion.div>

        {/* Right Content Section */}
        <motion.div
          className="bridge-right"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        >
          {[
            {
              title: "Intelligent API-Driven Integrations",
              text: "We design API-first architectures that enable secure, real-time data exchange between applications, cloud platforms, and legacy systems. Our custom-built and pre-configured connectors ensure faster deployments and enhanced system interoperability.",
            },
            {
              title: "Cloud, Hybrid & On-Prem Integration",
              text: "Whether you're migrating to the cloud, operating in a hybrid environment, or maintaining on-premise systems, our integration solutions unify disparate platforms to create a cohesive digital ecosystem with 99.99% uptime and scalable infrastructure.",
            },
            {
              title: "Workflow Automation for Operational Efficiency",
              text: "Disconnected workflows slow businesses down. We streamline business process automation (BPA) and event-driven integrations to enhance productivity, reduce manual effort, and ensure seamless information flow across your enterprise.",
            },
            {
              title: "Secure & Scalable Integration Frameworks",
              text: "Security and compliance are at the core of our integration strategy. We implement robust governance, monitoring, and performance optimization frameworks to ensure data integrity, regulatory compliance, and continuous improvement.",
            },
          ].map((item, index) => (
            <motion.div
              className="content-box"
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: false }}
            >
              <h2 className="blue-heading">{item.title}</h2>
              <p>{item.text}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      <motion.section
        className="focus-container"
        initial={{ scale: 0.8, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="focus-content">
          <div className="focus-img">
            <img src="/images/CYNTRALABS White Logo.png" alt="Focus Logo" />
          </div>
          <h2 className="focus-title">
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"pink"}}>With our proven </span>track record
                of 50+ successful migrations,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#e8c46b"}} >we turn complexity </span> into
                opportunity,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#71b788"}}>keeping your business</span> ahead
                of the curve.
              </div>
            </div>
          </h2>
        </div>
      </motion.section>

      <motion.div
        className="technologies-container"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="tech-container">
          <div className="tech-left">
            <h1>
              Technologies We Leverage <br />
            </h1>
            <button className="button-about" onClick={() => navigate ('/AboutUs')}>
                      Get to know us &nbsp;&nbsp;&nbsp;
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          <div className="technologies-right">
            {[
              "/images/MuleSoft-Logo-removebg-preview 1.jpg",
              "/images/azure.png",
              "/images/Sap-Logo.png",
              "/images/boomilogo.png",
              "/images/workday-logo.png",
              "/images/Workato-Logo.png",
              "/images/salesforce-logo.png",
              "/images/Dynamics.png",
            ].map((src, index) => (
              <motion.img
                key={index}
                src={src}
                alt="Technology Logo"
                className="tech-logo"
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: false }}
              />
            ))}
          </div>
        </div>
      </motion.div>

   {/* Contact Us */}
  <Footer/>
    </>
  );
};

export default ERPSolutions;
