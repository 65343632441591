import React, { useEffect, useState } from "react";
import { motion , useAnimation } from "framer-motion";
import { Linkedin, Instagram, Youtube, Volume2, VolumeX } from "lucide-react";
import Header from "../Header";
import Footer from "../Footer"
import { useNavigate } from "react-router-dom";
import "./Papers.css";

const WhitePaperOne = () => {
     const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //speech
  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent = document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText = document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation(); // Initialize animation controls for framer-motion

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Ensure this is set to smooth
        block: "start", // Align the section at the top of the viewport
        inline: "nearest", // Prevent horizontal scroll if not needed
      });
    }
  };

  return (
    <div className="case-study-page">
      <Header />
      {/* Banner Section */}
      <motion.section
        className="banner-section"
        style={{
          background:
            "url('/images/freepik__enhance__67791.png') center/cover no-repeat",
        }}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div className="banner-content">
          <a href="/blogs" className="back-to-list">
            Back to list
          </a>
          <motion.h1
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
           Mulesoft to Azure Logic App Migration with AI-powered Automation
          </motion.h1>
           {/* Reading Time & Speaker Button */}
           <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
  <span>{readingMinutes} min read</span>
  <button className="speaker" style={{color:"white", border: "2px solid white"}} onClick={toggleSpeech}>
    {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
  </button>

  <button
  className="button-Schedule"
  style={{ marginTop: "1rem" , border: "2px solid white",
    color: "white"}}
  onClick={() => navigate('/Get-In-Touch')}
>

    Talk to our expert 
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animated-arrow"
    >
      <path
        d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </button>
</div>

        </div>
      </motion.section>

      {/* Main Content Section */}
      <div className="case-study-container">
        {/* Sidebar Navigation */}
        <aside className="case-study-sidebar">
          <ul>
            <li
              className={activeSection === "Executive-Summary" ? "active" : ""}
              onClick={() => handleClick("Executive Summary")}
            >
              Executive Summary
            </li>
            <li
              className={activeSection === "Project-Overview" ? "active" : ""}
              onClick={() => handleClick("Project-Overview")}
            >
              Project Overview 
            </li>
            <li
              className={activeSection === "Migration-Strategy" ? "active" : ""}
              onClick={() => handleClick("Migration-Strategy")}
            >
              Migration Strategy 
            </li>
            <li
              className={activeSection === "Architecture-Redesign" ? "active" : ""}
              onClick={() => handleClick("Architecture-Redesign")}
            >
              Architecture Redesign
            </li>
            <li
              className={activeSection === "CI/CD-Implementation" ? "active" : ""}
              onClick={() => handleClick("CI/CD-Implementation")}
            >
             CI/CD Implementation with Azure DevOps 
            </li>
            <li
              className={activeSection === "Detailed-CaseStudies" ? "active" : ""}
              onClick={() => handleClick("Detailed-CaseStudies")}
            >
              Detailed Case Studies and Use Cases
            </li>
            <li
              className={activeSection === "Security-Considerations" ? "active" : ""}
              onClick={() => handleClick("Security-Considerations ")}
            >
              Security Considerations 
            </li>
          </ul>
        </aside>

        {/* Article Section */}
        <motion.section
          className="article-section"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "easeInOut" }}
        >
          <div className="container">
            <motion.h2
              id="Executive-Summary"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 1, ease: "easeOut" }}
            >
              Executive Summary
            </motion.h2>
            <p>
              This white paper outlines the strategic migration of our integration platform from MuleSoft to 
Azure Logic Apps. The migration aimed to redesign the existing architecture to leverage Azure's 
robust development ecosystem, enhance scalability, and streamline integration processes. Key 
components of the migration included a two-layer architecture comprising Process APIs and 
System APIs, integration with Anaplan via API-based connections, transitioning DataWeave 
transformations to Azure Function Apps, and establishing comprehensive CI/CD pipelines using 
Azure DevOps. This migration not only modernized our integration framework but also provided 
improved flexibility, reduced costs, and facilitated easier maintenance and development. 
            </p>
            <motion.h2
              id="Project-Overview"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
            >
              Project Overview 
            </motion.h2>
            <p>
              <ul>
                <li>
                  <strong >Redesign the Architecture:</strong> Create a more streamlined and maintainable architecture 
                  within Azure. 
                </li>
                <li>
                  <strong>Enhance Development Efficiency:</strong> Leverage Azure's development tools and services to 
                  facilitate easier and faster development cycles. 
                </li>
                <li>
                  <strong>Integrate Core Systems:</strong> Ensure seamless integration with Anaplan, Salesforce, Azure Blob 
                  Storage, and other essential systems. 
                </li>
                <li>
                  <strong>Implement Robust CI/CD Pipelines:</strong> Utilize Azure DevOps to establish continuous 
                  integration and continuous deployment processes, ensuring rapid and reliable releases. 
                </li>
              </ul>
            </p>
            <motion.h2
              id="Migration-Strategy"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.7, duration: 1, ease: "easeOut" }}
            >
              Migration Strategy
            </motion.h2>
            <p>
              <ul>
                <li>
                  <strong  id="Migration-Strategy">Assessment and Planning:</strong> Evaluating the existing MuleSoft integrations, identifying dependencies,
                   and mapping them to Azure services. 
                </li>
                <li>
                  <strong  id="Migration-Strategy">Architecture Redesign:</strong> Developing a new architecture leveraging Azure Logic Apps, Function 
                  Apps, and other Azure services.  
                </li>
                <li>
                  <strong  id="Migration-Strategy">Component Migration:</strong> Transitioning individual integration components, such as APIs and data 
                  transformations, from MuleSoft to Azure. 
                </li>
                <li>
                  <strong  id="Migration-Strategy">Integration Implementation:</strong>  Rebuilding integrations with Anaplan, Salesforce, and Azure 
                  Blob Storage using Azure-native connectors and APIs.
                </li>
                <li>
                  <strong  id="Migration-Strategy">CI/CD Pipeline Setup:</strong>  Establishing automated pipelines in Azure DevOps to manage code deployments and updates. 
                </li>
                <li>
                  <strong  id="Migration-Strategy">Testing and Validation:</strong> Ensuring all integrations function correctly post-migration through rigorous 
                  testing. 
                </li>
                <li>
                  <strong>Deployment and Monitoring:</strong> Rolling out the 
new integrations to production and setting up 
monitoring mechanisms to maintain operational 
integrity.
                </li>
              </ul>
            </p>
            <motion.h2
              id="Architecture-Redesign"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1, duration: 1, ease: "easeOut" }}
            >
              Architecture Redesign
            </motion.h2>
            <img src="/images/Software.png" alt="Architecture Illustration" className="illustration-img" />
            <p className="description-text"><i>
            The architectural redesign focused on creating a scalable, maintainable, and efficient integration framework within Azure. 
            The new architecture is divided into two primary layers: Process APIs and System APIs.
            </i> </p>
                <br/>
            <h3 className="sub-title">1. Two-Layer Architecture</h3>
            <div className="architecture-grid"  id="Architecture-Redesign">
            <div className="api-layer"  id="Architecture-Redesign">
                <img src="/images/IconProcessApi.png" alt="Process API Icon" className="api-icon" />
                <h4>Process API Layer:</h4>
                <p><strong>Purpose:</strong> Orchestrates business processes, handles data transformations, and manages workflow logic.</p>
                <p><strong>Components:</strong> Azure Logic Apps, Azure Function Apps for complex transformations, and Azure API Management for exposing APIs to consumers.</p>
            </div>

            <div className="api-layer"  id="Architecture-Redesign">
                <img src="/images/IconsystemAPI.jpg" alt="System API Icon" className="api-icon" />
                <h4>System API Layer:</h4>
                <p><strong>Purpose:</strong> Interfaces directly with backend systems, abstracting the complexities of underlying systems from the Process API layer.</p>
                <p><strong>Components:</strong> Azure Logic Apps with connectors for systems like Salesforce, Anaplan, and Azure Blob Storage.</p>
            </div>
            </div>
            <h4 className="benefits-title"  id="Architecture-Redesign">Benefits:</h4>
            <ul className="benefits-list">
            <li><strong>Separation of Concerns:</strong> Distinguishes between business logic and system interactions, enhancing maintainability.</li>
            <li><strong>Reusability:</strong> System APIs can be reused across multiple Process APIs, reducing duplication.</li>
            <li><strong>Scalability:</strong> Each layer can be scaled independently based on load and usage requirements.</li>
            </ul>
            <img src="/images/diagram1.png" alt="Architecture Illustration" className="illustration-img" />
            <h3 className="sub-title">2. Integration with Anaplan </h3>
            <div className="architecture-grid"  id="Architecture-Redesign">
            <div className="api-layer"  id="Architecture-Redesign">
                <img src="/images/muleicon.jpg" alt="Process API Icon" className="api-icon" />
                <h4>MuleSoft Approach</h4>
                <p>Utilized a dedicated Anaplan connector for seamless integration.</p>
                  </div>

            <div className="api-layer"  id="Architecture-Redesign">
                <img src="/images/logic-apps.png" alt="System API Icon" className="api-icon" />
                <br/> <br/>
                <h4>Azure Approach</h4>
                <p><strong>API-Based Integration:</strong>  Leveraged Anaplan's API documentation to create custom connectors within Azure Logic Apps.</p>
                 </div>
            </div>
            <h4 className="benefits-title"  id="Architecture-Redesign">Azure Approach:</h4>
            <ul className="benefits-list">
            <li><strong>API-Based Integration:</strong> Leveraged Anaplan's API documentation to create 
            custom connectors within Azure Logic Apps. </li>
            <li><strong>Authentication:</strong> Implemented OAuth 2.0 for secure API access. </li>
            <li><strong>Data Handling:</strong> Used Azure Function Apps to handle data transformations and 
            business logic before interacting with Anaplan APIs.</li>
            <li><strong>Error Handling:</strong> Integrated retry policies and error logging within Logic Apps to 
            manage API call failures gracefully. </li>
            </ul>
            <h4 className="benefits-title"  id="Architecture-Redesign">Benifits:</h4>
            <ul className="benefits-list">
            <li><strong>Flexibility:</strong> Direct API integration allows for more customized interactions 
            tailored to specific business needs. </li>
            <li><strong>Cost-Effectiveness:</strong>Reduces dependency on third-party connectors, 
            potentially lowering licensing costs. </li>
            </ul>
            <br/>
            <h3 className="sub-title">3. Data Transformation with Azure Function Apps  </h3>
            <h4 className="benefits-title"  id="Architecture-Redesign">Transition from DataWeave:</h4>
            <ul className="benefits-list">
            <li><strong>MuleSoft DataWeave:</strong> Utilized for complex data transformations within 
            MuleSoft.</li>
            <li><strong>Azure Function Apps: </strong> Recreated transformation logic using Azure Functions, 
            written in languages such as C#. </li>
            </ul>

            <h4 className="benefits-title"  id="Architecture-Redesign">Implementation Details:</h4>
            <ul className="benefits-list">
            <li><strong>Function App Structure:</strong> Organized multiple DataWeave-like transformation 
            functions within a single Function App for modularity. </li>
            <li><strong>Scalability: </strong>Leveraged Azure's serverless infrastructure to scale transformation 
            functions based on demand.  </li>
            <li><strong>Performance Optimization: </strong> Employed asynchronous processing and optimized 
            code to ensure high performance.  </li>
            </ul>
            <h4 className="benefits-title"  id="Architecture-Redesign">Benifits:</h4>
            <ul className="benefits-list">
            <li><strong>Maintainability:</strong> Easier to manage and update transformation logic within a 
            familiar development environment.  </li>
            <li><strong>Cost Efficiency:</strong> Pay-per-execution pricing model reduces costs during low
            usage periods.  </li>
            </ul>



            <h3 className="sub-title">4. Salesforce, Azure Blob, and Other Integrations   </h3>
            <h4 className="benefits-title"  id="Architecture-Redesign">Salesforce Integration:</h4>
            <ul className="benefits-list">
            <li><strong>Azure Logic Apps Connector:</strong> Utilized the native Salesforce connector for CRUD 
            operations and event-driven triggers</li>
            <li><strong>Authentication: </strong> Implemented OAuth 2.0 for secure access to Salesforce APIs. </li>
            </ul>
            <h4 className="benefits-title"  id="Architecture-Redesign">Azure Blob Storage Integration:</h4>
            <ul className="benefits-list">
            <li><strong>Native Connector:</strong> Used Azure Blob Storage connectors within Logic Apps for 
            file storage and retrieval.  </li>
            <li><strong>Event-Driven Processing: </strong>Triggered Logic Apps workflows based on Blob 
            events, such as file uploads or modifications.  </li>
            <li><strong>Data Management:</strong> Implemented data lifecycle policies to manage storage 
            costs and compliance requirements.   </li>
            </ul>
            <h4 className="benefits-title"  id="Architecture-Redesign">Other Integrations:</h4>
            <ul className="benefits-list">
            <li><strong>Maintainability:</strong> Developed custom connectors for systems without native 
            Azure Logic Apps support, leveraging REST APIs and Azure Function Apps.   </li>
            <li><strong>Cost Efficiency:</strong>Centralized monitoring using Azure Monitor and 
            Application Insights to track integration health and performance. </li>
            </ul>


            <motion.h2
              id="CI/CD-Implementation"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
            >
              CI/CD Implementation with Azure DevOps 
            </motion.h2>
            <div className="icon-grid"  id="CI/CD-Implementation">
        <div className="icon-item">
          <img src="/images/IconProcessApi.png" alt="Source Control" className="icon-img" />
          <p className="icon-text">Source Control Management</p>
        </div>
        <div className="icon-item">
          <img src="/images/BuildPipelines.png" alt="Build Pipelines" className="icon-img" />
          <p className="icon-text">Build<br/> Pipelines</p>
        </div>
        <div className="icon-item">
          <img src="/images/releases.png" alt="Release Pipelines" className="icon-img" />
          <p className="icon-text">Release <br/>Pipelines</p>
        </div>
        <div className="icon-item">
          <img src="/images/technical-expertise.png" alt="Testing Automation" className="icon-img" />
          <p className="icon-text">Testing<br/> Automation</p>
        </div>
        <div className="icon-item">
          <img src="/images/talk.svg" alt="Monitoring and Feedback" className="icon-img" />
          <p className="icon-text">Monitoring and Feedback</p>
        </div>
      </div>
      <br/><br/>
            <p>
            Establishing a robust CI/CD pipeline was critical to ensure seamless deployments and maintain 
high-quality integrations. The implementation involved the following components:
<ul>
  <li>
    <strong>Cost Efficiency:</strong> Azure's pay-as-you-go model
    significantly reduced operational expenses.
  </li>
  <li>
    <strong>Scalability:</strong> The new solution easily adapts
    to increasing data volumes and integration demands.
  </li>
  <li>
    <strong>Enhanced Performance:</strong> Improved data
    processing speeds and system reliability.
  </li>
  <li>
    <strong>CI/CD Implementation:</strong>
    <ul>
      <li>
        <strong>Source Control Management:</strong>
        <ul>
          <li>Azure Repos: Hosted all integration code, including Logic Apps definitions, Function Apps code, and ARM templates.</li>
        </ul>
      </li>
      <li>
        <strong>Build Pipelines:</strong>
        <ul>
          <li>Automated Builds: Configured pipelines to automatically build and validate code upon commits.</li>
          <li>Linting and Code Analysis: Integrated tools to enforce coding standards and detect potential issues early.</li>
        </ul>
      </li>
      <li>
        <strong>Release Pipelines:</strong>
        <ul>
          <li>Environment Management: Defined multiple environments (Development, Staging, Production) with appropriate approval gates.</li>
          <li>Infrastructure as Code (IaC): Utilized ARM templates and Azure Resource Manager to provision and manage Azure resources consistently across environments.</li>
          <li>Automated Deployments: Implemented automated deployment steps to ensure consistency and reduce manual intervention.</li>
        </ul>
      </li>
      <li>
        <strong>Testing Automation:</strong>
        <ul>
          <li>Unit Tests: Developed unit tests for Function Apps and other code components.</li>
          <li>Integration Tests: Automated end-to-end testing of integrations to validate interactions between systems.</li>
          <li>Continuous Testing: Integrated testing stages within the CI/CD pipeline to catch issues early.</li>
        </ul>
      </li>
      <li>
        <strong>Monitoring and Feedback:</strong>
        <ul>
          <li>Azure Monitor Integration: Set up monitoring dashboards and alerting to track deployment health and performance.</li>
          <li>Feedback Loops: Established mechanisms for rapid feedback on deployment successes or failures, enabling quick remediation.</li>
        </ul>
      </li>
    </ul>
  </li>
  <li>
    <strong>Benefits:</strong>
    <ul>
      <li>Speed: Accelerated deployment cycles through automation.</li>
      <li>Reliability: Reduced deployment errors with consistent, repeatable processes.</li>
      <li>Traceability: Maintained comprehensive logs and version histories for auditing and compliance.</li>
    </ul>
  </li>
</ul>
   </p>
   <motion.h2
  id="Detailed-CaseStudies"
  initial={{ opacity: 0, x: -50 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
>
  Detailed Case Studies and Use Cases
</motion.h2>
<p>
  The successful migration led to:
  <ul>
    <li>
      A 50% reduction in integration-related operational costs.
    </li>
    <li>
      Streamlined operations within a unified Azure ecosystem.
    </li>
    <li>
      Positioning Talking Rain for future technological advancements
      and scalability.
    </li>
  </ul>
</p>

<div>
<h3 style={{ fontSize: "2rem" }}>Case Study 1: Anaplan Integration Enhancement</h3>

  <p><strong>Background:</strong></p>
  <p>
    The existing MuleSoft integration with Anaplan utilized a dedicated connector that
    facilitated seamless data exchange. However, as business requirements evolved, the need
    for more customized interactions became apparent.
  </p>
  <p><strong>Migration Approach:</strong></p>
  <ul>
    <li>
      <strong>Custom Connector Development:</strong> Leveraged Anaplan's API documentation to
      build a custom connector within Azure Logic Apps.
    </li>
    <li>
      <strong>Enhanced Data Transformation:</strong> Migrated complex DataWeave transformations to
      Azure Function Apps, allowing for more flexible and scalable data processing.
    </li>
    <li>
      <strong>Error Handling Improvements:</strong> Implemented advanced retry policies and logging
      mechanisms to handle API call failures more gracefully.
    </li>
  </ul>
  <p><strong>Outcome:</strong></p>
  <ul>
    <li>
      <strong>Increased Flexibility:</strong> The custom connector enabled tailored interactions that
      aligned more closely with evolving business needs.
    </li>
    <li>
      <strong>Improved Reliability:</strong> Enhanced error handling reduced data exchange failures,
      ensuring more consistent data integrity.
    </li>
    <li>
      <strong>Cost Reduction:</strong> Eliminated reliance on third-party connectors, resulting in
      significant licensing fee savings.
    </li>
  </ul>
</div>

<div>
  <h3 style={{ fontSize: "2rem" }}>Case Study 2: Salesforce Data Synchronization</h3>
  <p><strong>Background:</strong></p>
  <p>
    The organization required bi-directional synchronization between Salesforce and other
    backend systems to ensure data consistency across platforms.
  </p>
  <p><strong>Migration Approach:</strong></p>
  <ul>
    <li>
      <strong>Azure Logic Apps Salesforce Connector:</strong> Utilized the native Salesforce connector
      to establish CRUD operations and event-driven triggers.
    </li>
    <li>
      <strong>Process API Layer Implementation:</strong> Orchestrated the synchronization workflows
      using the Process API layer, ensuring separation from system-specific logic.
    </li>
    <li>
      <strong>Automated Testing:</strong> Integrated comprehensive unit and integration tests within
      the CI/CD pipeline to validate synchronization processes.
    </li>
  </ul>
  <p><strong>Outcome:</strong></p>
  <ul>
    <li>
      <strong>Real-Time Data Consistency:</strong> Achieved near real-time synchronization between
      Salesforce and backend systems, enhancing data accuracy.
    </li>
    <li>
      <strong>Scalability:</strong> Leveraged Azure's scalable infrastructure to handle increasing data
      volumes without performance degradation.
    </li>
    <li>
      <strong>Maintenance Efficiency:</strong> Simplified maintenance through the modular
      architecture, allowing for independent updates to synchronization workflows.
    </li>
  </ul>
</div>
<img src="/images/illustration.png" alt="Architecture Illustration" className="illustration-img" />

<motion.h2
  id="Security-Considerations"
  initial={{ opacity: 0, x: -50 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
>
Security Considerations 
</motion.h2>
<img src="/images/security.jpg" alt="Architecture Illustration" className="illustration-img" />
<p>
  Ensuring the security of data and integrations was paramount throughout the
  migration process. The following security measures were implemented to safeguard
  the new Azure-based integration platform:
</p>
<ol>
  <li>
    <strong>Authentication and Authorization</strong>
    <ul>
      <li><strong>OAuth 2.0 Implementation:</strong> Utilized OAuth 2.0 protocols for secure API access, ensuring that only authorized applications and users could interact with the integration services.</li>
      <li><strong>Azure Active Directory (AAD):</strong> Leveraged AAD for managing user identities and access controls, implementing role-based access control (RBAC) to restrict permissions based on user roles.</li>
    </ul>
  </li>
  <li>
    <strong>Data Encryption</strong>
    <ul>
      <li><strong>In-Transit Encryption:</strong> Ensured all data transmitted between services was encrypted using HTTPS/TLS protocols to prevent interception and tampering.</li>
      <li><strong>At-Rest Encryption:</strong> Utilized Azure Storage Service Encryption (SSE) to encrypt data stored in Azure Blob Storage and other storage services.</li>
    </ul>
  </li>
  <li>
    <strong>Network Security</strong>
    <ul>
      <li><strong>Virtual Networks (VNet):</strong> Configured VNets to isolate integration services, restricting access to authorized networks and minimizing exposure to potential threats.</li>
      <li><strong>Network Security Groups (NSGs):</strong> Implemented NSGs to define and enforce inbound and outbound traffic rules, enhancing network security.</li>
    </ul>
  </li>
  <li>
    <strong>Threat Detection and Monitoring</strong>
    <ul>
      <li><strong>Azure Security Center:</strong> Deployed Azure Security Center to continuously monitor the security posture of the integration platform, providing threat detection and security recommendations.</li>
      <li><strong>Application Insights:</strong> Integrated Application Insights for real-time monitoring and alerting on suspicious activities or potential security breaches.</li>
    </ul>
  </li>
  <li>
    <strong>Compliance and Governance</strong>
    <ul>
      <li><strong>Regulatory Compliance:</strong> Ensured the integration platform adhered to industry-specific compliance standards such as GDPR, HIPAA, and ISO 27001 by implementing necessary security controls and documentation.</li>
      <li><strong>Policy Enforcement:</strong> Utilized Azure Policy to enforce organizational standards and compliance requirements across all Azure resources.</li>
    </ul>
  </li>
  <li>
    <strong>Secure Development Practices</strong>
    <ul>
      <li><strong>Code Reviews and Audits:</strong> Conducted regular code reviews and security audits to identify and remediate vulnerabilities in the integration codebase.</li>
      <li><strong>Secure CI/CD Pipelines:</strong> Integrated security checks within the CI/CD pipelines to automatically detect and prevent the deployment of insecure code.</li>
    </ul>
  </li>
  <li>
    <strong>Data Privacy</strong>
    <ul>
      <li><strong>Data Masking and Anonymization:</strong> Implemented data masking and anonymization techniques to protect sensitive information during processing and storage.</li>
      <li><strong>Access Logs and Auditing:</strong> Maintained comprehensive access logs and audit trails to monitor data access and modifications, ensuring accountability and traceability.</li>
    </ul>
  </li>
</ol>
<p>
  <strong>Conclusion on Security:</strong> The migration to Azure Logic Apps not only maintained the security standards established with MuleSoft but also enhanced them by leveraging Azure's advanced security features and best practices. This comprehensive security framework ensured the protection of data, compliance with regulatory requirements, and resilience against potential threats.
</p>

          </div>
        </motion.section>
      </div>

      {/* Article Section */}

      <div className="exploreblogs">
        {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
        <div className="explore-blogs">
          <h3>Success Stories</h3>
          <ul className="explore-blogs-list">
            <li>
              <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                Revolutionizing CI/CD for Azure Logic Apps
              </a>
            </li>
            <li>
              <a href="/blogs/Seamless-migration-d365">
                Seamless Migration to D365: Transforming ERP for Future
              </a>
            </li>
            <li>
              <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                Enterprise-Scale Integration Transformation: From iWay to
                MuleSoft
              </a>
            </li>
            <li>
              <a href="/blogs/segment-cdp-data-marketing">
                Driving Digital Transformation: Integrating Segment CDP
              </a>
            </li>

            <li>
              <a href="/blogs/Integrating-QAD-and-EAM">
                Enhancing Eligibility Verification with Dell Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/top-10-best-reads">
                Seamless Order Management with Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/SAP-to-Epicor">
                SAP to Epicor Integration Using Azure Logic Apps
              </a>
            </li>
          </ul>
        </div>
      </div>

{/* Contact Us */}

<Footer/>
    </div>
  );
};

export default WhitePaperOne;
