import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./SystemIntegration.css";
import "./DataIntegration.css";
import Header from "../Header";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";

const DevOps = () => {
     const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header />
      {/* System Integration Section with Background */}
      <div
        className="system-integration"
        style={{
          background:
            "url('/images/image copy.png') no-repeat center center / cover",
        }}
      >
        <div className="header h1">
          <h1>
            Cloud-Native Excellence:
            <br /> CI/CD, DevOps & Automation
          </h1>
        </div>
      </div>

      {/* Bridge Diverse Processes Swiftly Section */}
      <motion.div
        className="bridge-container"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: false }}
      >
        {/* Left Section */}
        <motion.div
          className="bridge-left"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        >
          <h1>
            Whether you need infrastructure as code, automated deployments, or
            resilient cloud-native architectures, we build solutions that scale
            with your business.
          </h1>
          <button className="button-Schedule" onClick={() => navigate ('/Get-In-Touch')}>
          Talk to our expert
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animated-arrow"
  >
    <path
      d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
      stroke="#042E50"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
</button>

        </motion.div>

        {/* Vertical Line */}
        <motion.div
          className="vertical-line"
          style={{
            borderLeft: "0.0625rem solid #797979",
            height: "auto",
            minHeight: "90vh",
            marginRight: "70px",
            marginTop: "50px",
            marginLeft: "50px",
          }}
          initial={{ opacity: 0, scaleY: 0 }}
          whileInView={{ opacity: 1, scaleY: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        ></motion.div>

        {/* Right Content Section */}
        <motion.div
          className="bridge-right"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        >
          {[
            {
              title: "Cloud Strategy & Infrastructure as Code (IaC)",
              text: "We design cloud-first architectures with Infrastructure as Code (IaC) using Terraform, ARM, and CloudFormation for repeatable, scalable, and automated deployments. This ensures cost efficiency, operational consistency, and rapid provisioning across AWS, Azure, and Google Cloud.",
            },
            {
              title: "CI/CD Pipeline Automation for Faster, Secure Releases",
              text: "We implement automated build, test, and deployment pipelines using GitHub Actions, Jenkins, and Azure DevOps to enable 5x faster, zero-downtime deployments. With automated rollback mechanisms, we ensure application stability and seamless updates.",
            },
            {
              title: "Continuous Optimization & Cost Management",
              text: "Cloud efficiency isn’t just about automation—it’s about ongoing optimization. We implement auto-scaling, cost-aware infrastructure provisioning, and security automation to enhance performance while reducing cloud costs by up to 40%.",
            },
          ].map((item, index) => (
            <motion.div
              className="content-box"
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: false }}
            >
              <h2 className="blue-heading">{item.title}</h2>
              <p>{item.text}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      <motion.section
        className="focus-container"
        initial={{ scale: 0.8, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="focus-content">
          <div className="focus-img">
            <img src="/images/CYNTRALABS White Logo.png" alt="Focus Logo" />
          </div>
          <h2 className="focus-title">
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"pink"}}>With our proven </span>track record
                of 50+ successful migrations,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#e8c46b"}} >we turn complexity </span> into
                opportunity,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#71b788"}}>keeping your business</span> ahead
                of the curve.
              </div>
            </div>
          </h2>
        </div>
      </motion.section>

      <motion.div
        className="technologies-container"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="tech-container">
          <div className="tech-left">
            <h1>
              Technologies We Leverage <br />
            </h1>
            <button className="button-about" onClick={() => navigate ('/AboutUs')}>
                      Get to know us &nbsp;&nbsp;&nbsp;
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          <div className="technologies-right">
            {[
              "/images/MuleSoft-Logo-removebg-preview 1.jpg",
              "/images/azure.png",
              "/images/Sap-Logo.png",
              "/images/boomilogo.png",
              "/images/workday-logo.png",
              "/images/Workato-Logo.png",
              "/images/salesforce-logo.png",
              "/images/Dynamics.png",
            ].map((src, index) => (
              <motion.img
                key={index}
                src={src}
                alt="Technology Logo"
                className="tech-logo"
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: false }}
              />
            ))}
          </div>
        </div>
      </motion.div>

     {/* Contact Us */}
<Footer/>
    </>
  );
};

export default DevOps;
