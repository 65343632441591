import { useEffect } from "react";
import { Routes, Route,useLocation } from "react-router-dom";
import Homepage from "./components/Homepage";
import Migration from "./components/What_We_Offer/Migration";
import ERPSolutions from "./components/What_We_Offer/ERP_Solutions";
import AMS from "./components/What_We_Offer/AMS_Support";
import ERPConsulting from "./components/What_We_Offer/ERP_Consulting";
import DevOpsServices from "./components/What_We_Offer/Devops_Services";
import Blogs from "./components/Knowledge/Blogs";
import WhitePapers from "./components/Knowledge/WhitePapers";
import CaseStudyOne from "./components/CaseStudies/CaseStudyOne";
import CaseStudyTwo from "./components/CaseStudies/CaseStudyTwo";
import CaseStudyThree from "./components/CaseStudies/CaseStudyThree";
import CaseStudyFour from "./components/CaseStudies/CaseStudyFour";
import CaseStudyFive from "./components/CaseStudies/CaseStudyFive";
import CaseStudySix from "./components/CaseStudies/CaseStudySix";
import CaseStudySeven from "./components/CaseStudies/CaseStudySeven";
import CaseStudyEight from "./components/CaseStudies/CaseStudyEight";
import CaseStudyNine from "./components/CaseStudies/CaseStudyNine";
import CaseStudyTen from "./components/CaseStudies/CaseStudyTen";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import ServiceNowCard from "./components/PatnerPage/ServiceNowCard";
import GoogleCloudCard from "./components/PatnerPage/GoogleCloudCard";
import SAPLabsCard from "./components/PatnerPage/SAPLabsCard";
import SalesforceCard from "./components/PatnerPage/SalesforceCard";
import WhitePaperOne from "./components/WhitePapers/WhitepaperOne";


function App() {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to the top on every route change
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/Migrating-ERPSolutions" element={<Migration />} />
      <Route path="/ERP-Solutions" element={<ERPSolutions />} />
      <Route path="/AMS-Services" element={<AMS />} />
      <Route path="/ERP-Consulting" element={<ERPConsulting />} />
      <Route path="/Devops-Services" element={<DevOpsServices />} />
      <Route path="/Blogs" element={<Blogs />} />
      <Route path="/White-Papers" element={<WhitePapers />} />
      <Route
        path="/blogs/Migration-from-Mulesoft-to-Azure-Logic-Apps-and-Function-Apps"
        element={<CaseStudyOne />}
      />
      <Route
        path="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps"
        element={<CaseStudyTwo />}
      />
      <Route
        path="/blogs/Seamless-migration-d365"
        element={<CaseStudyThree />}
      />
      <Route
        path="/blogs/Transformation-From-iWay-to-mulesoft"
        element={<CaseStudyFour />}
      />
      <Route
        path="/blogs/segment-cdp-data-marketing"
        element={<CaseStudyFive />}
      />
      <Route
        path="/blogs/ecommerce-marketing-2025"
        element={<CaseStudySix />}
      />
      <Route
        path="/blogs/Integrating-QAD-and-EAM"
        element={<CaseStudyEight />}
      />
      <Route path="/blogs/top-10-best-reads" element={<CaseStudySeven />} />
      <Route path="/blogs/SAP-to-Epicor" element={<CaseStudyNine />} />
      <Route path="/blogs/Boomi-to-SAP" element={<CaseStudyTen /> } />
      <Route path="/Get-In-Touch" element={<Contact />} />
      <Route path="/AboutUs" element={<AboutUs />} />
      <Route path="/Service-Now" element={<ServiceNowCard />} />
      <Route path="/Google-Cloud" element={<GoogleCloudCard />} />
      <Route path="/SAP-Labs" element={<SAPLabsCard />} />
      <Route path="/Salesforce" element={<SalesforceCard />} />


      <Route path="White-Papers/Accelerating-Mulesoft-to-Azure-logic-app-Migration-with-Ai-powered-Automation" element={<WhitePaperOne />} />
    </Routes>
  );
}

export default App;
