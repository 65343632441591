import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Youtube, Instagram, Linkedin } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";
import Footer from "../Footer";

const CaseStudyFive = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );
  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation(); // Initialize animation controls for framer-motion

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Ensure this is set to smooth
        block: "start", // Align the section at the top of the viewport
        inline: "nearest", // Prevent horizontal scroll if not needed
      });
    }
  };

  return (
    <>
      <div className="case-study-page">
        <Header />
        {/* Banner Section */}
        <motion.section
          className="banner-section"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          {/* Blurred Background Layer */}
          <div
            className="banner-bg"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "url('/images/AdobeStock_1040312508.jpeg') center/cover no-repeat",
              filter: "blur(2px)" /* ✅ Background blur applied */,
              zIndex: -1,
            }}
          />

          {/* Content inside the banner section */}
          <div className="banner-content">
            <a href="/blogs" className="back-to-list">
              Back to list
            </a>
            <motion.h1
              initial={{ opacity: 0, scale: 1.1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Driving Digital Transformation: Integrating Segment CDP for
              Data-Driven Marketing
            </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>Estimated reading time: {readingMinutes} minutes</span>
            <button className="speaker" onClick={toggleSpeech} style={{backgroundColor: "#F2F2F2", color:"black"}}>
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{ marginTop: "1rem" ,
                backgroundColor: "#F2F2F2",
    border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert 
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          </div>
        </motion.section>

        {/* Main Content Section */}
        <div className="case-study-container">
          {/* Sidebar Navigation */}
          <aside className="case-study-sidebar">
            <ul>
              <li
                className={activeSection === "business-needs" ? "active" : ""}
                onClick={() => handleClick("business-needs")}
              >
                Business Needs
              </li>
              <li
                className={activeSection === "key-challenges" ? "active" : ""}
                onClick={() => handleClick("key-challenges")}
              >
                Key Challenges in the Transformation
              </li>
              <li
                className={
                  activeSection === "cyntralabs-breakthrough" ? "active" : ""
                }
                onClick={() => handleClick("cyntralabs-breakthrough")}
              >
                CyntraLabs Breakthrough Solution: Intelligent Integration for
                Data-Driven Marketing
              </li>
              <li
                className={
                  activeSection === "transformational-outcomes" ? "active" : ""
                }
                onClick={() => handleClick("transformational-outcomes")}
              >
                Transformational Outcomes
              </li>
              <li
                className={
                  activeSection === "client-testimonial" ? "active" : ""
                }
                onClick={() => handleClick("client-testimonial")}
              >
                Client Testimonial
              </li>
              <li
                className={activeSection === "future-roadmap" ? "active" : ""}
                onClick={() => handleClick("future-roadmap")}
              >
                Future Roadmap
              </li>
              <li
                className={
                  activeSection === "about-talking-rain" ? "active" : ""
                }
                onClick={() => handleClick("about-talking-rain")}
              >
                About Talking Rain Beverage Company
              </li>
            </ul>
          </aside>

          {/* Article Section */}
          <motion.section
            className="article-section"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <div className="container">
              <motion.h2
                id="business-needs"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3, duration: 1, ease: "easeOut" }}
              >
                Business Needs
              </motion.h2>
              <p>
                Mark Anthony Group, one of North America’s largest beverage
                companies, embarked on a digital transformation journey to
                revolutionize its customer engagement, marketing automation, and
                data-driven decision-making. While their legacy systems handled
                basic ETL processes, they lacked the ability to:{" "}
              </p>
              <p>
                To achieve the following goals, Mark Anthony Group decided to
                integrate Segment CDP (Customer Data Platform) with its
                marketing, sales, and customer support tools—but needed a
                robust, real-time integration framework to seamlessly connect
                multiple data sources and enable AI-driven analytics:
              </p>
              <br/><br/>

              <ul>
                <li>
                  Unify customer data across multiple platforms for a 360-degree
                  view.
                </li>
                <li>
                  Leverage AI-driven insights for personalized marketing
                  campaigns.
                </li>
                <li>
                  Improve customer engagement by collecting real-time feedback
                  and responses.
                </li>
                <li>
                  Automate and optimize advertising strategies to drive higher
                  ROI.
                </li>
              </ul> <br/><br/>
              <p>
                To achieve this, Mark Anthony Group decided to integrate Segment
                CDP (Customer Data Platform) with its marketing, sales, and
                customer support tools—but needed a robust, real-time
                integration framework to seamlessly connect multiple data
                sources and enable AI-driven analytics.
              </p>

              <motion.h2
                id="key-challenges"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1, duration: 1, ease: "easeOut" }}
              >
                Key Challenges in the Transformation
              </motion.h2> <br/><br/>
              <h3 style={{fontSize:"1.5rem"}}>Integration Challenges Faced by Mark Anthony Group</h3>
              <br/><br/>
              <ul>
                <li>
                  <strong>Fragmented Customer Data:</strong> Data was scattered
                  across multiple tools (ActiveCampaign, Freshdesk, Google Ads,
                  Facebook Ads, Salesforce, etc.) making it difficult to
                  personalize campaigns.
                </li>
                <li>
                  <strong>Lack of Real-Time Insights:</strong> Marketing teams
                  had to rely on manual data aggregation and delayed reporting,
                  limiting the effectiveness of targeted ads.
                </li>
                <li>
                  <strong>Scaling Issues:</strong> The existing architecture
                  struggled to handle high-volume event-driven data processing
                  required for an enterprise-grade CDP.
                </li>
                <li>
                  <strong>Complex Data Orchestration:</strong> Ensuring seamless
                  data flow across multiple platforms while maintaining
                  accuracy, consistency, and compliance was a major hurdle.
                </li>
              </ul>

              <motion.h2
                id="cyntralabs-breakthrough"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
              >
                CyntraLabs Breakthrough Solution: Intelligent Integration for
                Data-Driven Marketing
              </motion.h2> <br/><br/>
              <p>
                Our team at CyntraLabs designed and implemented a real-time
                integration framework to:
              </p>
              <br/><br/>
              <ul>
                <li>
                  Aggregate customer data from multiple sources (ActiveCampaign,
                  Freshdesk, Google Analytics, CRM, etc.) into Segment CDP.
                </li>
                <li>
                  Automate customer segmentation to enable hyper-personalized
                  targeting and engagement.
                </li>
                <li>
                  Enable AI-driven marketing analytics for precise campaign
                  tracking and reporting.
                </li>
                <li>
                  Ensure seamless data governance and compliance, improving data
                  accuracy and reliability.
                </li>
              </ul>
<br/>
              <h3 style={{fontSize:"2rem"}}>Key Implementation Steps</h3>
<br/>
              <ol>
                <li>
                  <h3 style={{fontSize:"1.4rem"}}>Customer Data Pipeline Architecture</h3>
                  <ul>   <br/>
                    <li>
                      Designed a high-performance, event-driven architecture for
                      data ingestion.
                    </li>
                    <li>
                      Leveraged MuleSoft APIs to standardize and orchestrate
                      data flow.
                    </li>
                  </ul>
                  <br/>
                </li>
                <li>
                  <h3 style={{fontSize:"1.4rem"}}>Integration with Segment CDP</h3>
                  <br/>
                  <ul> 
                    <li>
                      Connected Freshdesk (customer support), ActiveCampaign
                      (email automation), and various ad platforms to Segment
                      CDP.
                    </li>
                    <li>
                      Ensured real-time sync between customer interactions and
                      marketing campaigns.
                    </li>
                  </ul>
                  <br/>
                </li>
                <li>
                  <h3 style={{fontSize:"1.4rem"}}>Automated Customer Segmentation & Engagement</h3>
                   <br/>
                   <ul>  <li>
                      Implemented AI-powered segmentation models to group
                      customers based on behavior, preferences, and engagement
                      levels.
                    </li>
                    <li>
                      Enabled automated campaign triggering based on real-time
                      customer interactions.
                    </li>
                  </ul>
                </li>   <br/>
                <li>
                  <h3 style={{fontSize:"1.4rem"}}>Marketing Reporting & Predictive Analytics</h3>
                  <br/> <ul>
                    <li>
                      Integrated Google Ads, Facebook Ads, and marketing
                      dashboards for real-time ad performance tracking.
                    </li>
                    <li>
                      Built predictive analytics models to optimize ad spend and
                      maximize conversions.
                    </li>
                  </ul>
                </li>   <br/>
                <li>
                  <h3 style={{fontSize:"1.4rem"}}>Data Compliance & Security</h3>
                  <br/> <ul>
                    <li>
                      Implemented role-based access controls (RBAC) and
                      encryption to ensure data security.
                    </li>
                    <li>
                      Ensured GDPR, CCPA compliance for customer data
                      management.
                    </li>
                  </ul>
                </li>
              </ol>

              <motion.h2
                id="transformational-outcomes"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
              >
                Transformational Outcomes
              </motion.h2>
              <ul>
                <li>
                  <strong>120% Increase in Lead Conversions:</strong>{" "}
                  Personalized, AI-driven targeting led to a massive jump in
                  customer engagement and sales.
                </li>
                <li>
                  <strong>40% Faster Time-to-Market for Campaigns:</strong>{" "}
                  Automated workflows reduced manual effort, enabling real-time
                  customer engagement.
                </li>
                <li>
                  <strong>Data-Driven Decision Making:</strong> Marketing teams
                  now leverage AI-powered insights for better ad targeting and
                  higher ROI.
                </li>
                <li>
                  <strong>Seamless Omnichannel Experience:</strong> Customers
                  receive cohesive, personalized offers across email, social
                  media, and paid ads.
                </li>
                <li>
                  <strong>Scalable & Future-Proof Infrastructure:</strong> The
                  new system is built for growth, allowing Mark Anthony to
                  expand its digital reach globally.
                </li>
              </ul>

              <motion.h2
                id="client-testimonial"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
              >
                Client Testimonial
              </motion.h2>
              <p>
                <i>
                  "We never imagined we could unify our marketing, sales, and
                  customer engagement data so seamlessly. CyntraLabs not only
                  integrated Segment CDP with our ecosystem but also enabled us
                  to leverage AI-driven analytics, transforming how we engage
                  with our customers and drive revenue."
                </i>
              </p>

              <motion.h2
                id="future-roadmap"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.9, duration: 1, ease: "easeOut" }}
              >
                Future Roadmap
              </motion.h2>
              <p>
                With the Segment CDP integration fully operational, Mark Anthony
                Group is now exploring:
              </p>

              <ul>
                <li>
                  AI-driven customer journey automation for predictive
                  engagement.
                </li>
                <li>
                  Hyper-personalized real-time offers based on behavioral
                  analytics.
                </li>
                <li>
                  Advanced marketing attribution models to track ROI across
                  digital channels.
                </li>
              </ul>

              <motion.h2
                id="about-talking-rain"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 2.2, duration: 1, ease: "easeOut" }}
              >
                About Talking Rain Beverage Company
              </motion.h2> <br/><br/>
              <p>
                <strong>Mark Anthony Group</strong> is a leader in the beverage
                industry, best known for brands like{" "} <br/>
                <strong>White Claw Hard Seltzer</strong> and{" "} <br/>
                <strong>Mike’s Hard Lemonade</strong>. With a focus on
                innovation and digital transformation, the company is driving
                next-gen customer engagement strategies to fuel its global
                expansion.
              </p>
            </div>
          </motion.section>
        </div>
        {/* Article Section */}

        <div className="exploreblogs">
          {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
          <div className="explore-blogs">
            <h3>Featured Articles</h3>
            <ul className="explore-blogs-list">
              <li>
                <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                  Revolutionizing CI/CD for Azure Logic Apps
                </a>
              </li>
              <li>
                <a href="/blogs/Seamless-migration-d365">
                  Seamless Migration to D365: Transforming ERP for Future
                </a>
              </li>
              <li>
                <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                  Enterprise-Scale Integration Transformation: From iWay to
                  MuleSoft
                </a>
              </li>
              <li>
                <a href="/blogs/segment-cdp-data-marketing">
                  Driving Digital Transformation: Integrating Segment CDP
                </a>
              </li>

              <li>
                <a href="/blogs/Integrating-QAD-and-EAM">
                  Enhancing Eligibility Verification with Dell Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/top-10-best-reads">
                  Seamless Order Management with Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/SAP-to-Epicor">
                  SAP to Epicor Integration Using Azure Logic Apps
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Contact Us */}

 <Footer/>
      </div>
    </>
  );
};

export default CaseStudyFive;
