import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./SystemIntegration.css";
import Header from "../Header";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";

const AMS = () => {
     const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header />
      {/* System Integration Section with Background */}
      <motion.div
        className="system-integration"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          background:
            "url('/images/image (5).jpg') no-repeat center center / cover",
        }}
      >
        <div className="header h1">
          <h1>
            Application Management <br /> & Support
          </h1>
        </div>
      </motion.div>

      {/* Bridge Diverse Processes Swiftly Section */}
      <div className="bridge-container">
        <motion.div
          className="bridge-left"
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: false }}
        >
          <h1>
            Seamless Performance, Rapid Issue Resolution, and Optimized System
            Efficiency
          </h1>
          <button className="button-Schedule" onClick={() => navigate ('/Get-In-Touch')}>
          Talk to our expert 
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animated-arrow"
  >
    <path
      d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
      stroke="#042E50"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
</button>

        </motion.div>

        <div
          className="vertical-line"
          style={{
            borderLeft: "0.0625rem solid #797979",
            height: "auto",
            minHeight: "190vh",
            marginRight: "70px",
            marginTop: "50px",
            marginLeft: "50px",
          }}
        ></div>

        <div className="bridge-right">
          {[
            {
              title: "Continuous System Monitoring & Anomaly Detection",
              content:
                "We provide 24/7 real-time monitoring to track application health, performance, and logs using advanced analytics. Our predictive monitoring systems detect anomalies early, allowing us to resolve issues before they impact performance—ensuring 99.9% system availability.",
            },
            {
              title: "Rapid Issue Resolution with Structured Escalation",
              content:
                "We identify and resolve incidents quickly, leveraging structured ticketing workflows and expert intervention. With 30% faster resolution times, our incident management ensures that applications recover swiftly, reducing downtime and maintaining operational continuity.",
            },
            {
              title:
                "Application & Middleware Health Checks for Peak Efficiency",
              content:
                "Our experts conduct regular system performance audits and middleware optimizations to prevent bottlenecks, inefficiencies, and integration failures. By continuously fine-tuning applications, we ensure they run at peak efficiency and seamlessly connect with enterprise ecosystems.",
            },
            {
              title: "Flexible Engagement",
              content:
                "Need a quick boost or a full-blown transformation? We're adaptable. We join your project at any stage, providing skilled specialists and comprehensive training to empower your team, whether it's Boomi's intuitive interface or SAP's powerful data tools.",
            },
            {
              title: "Data Integrity, Storage Oversight & Compliance",
              content:
                "Protecting business-critical data is a priority. We maintain data accessibility, security, and regulatory compliance across cloud and on-premise storage. Our governance frameworks prevent data loss, corruption, and unauthorized access, ensuring business continuity and risk mitigation.",
            },
            {
              title:
                "Deployment & Post-Go-Live Support for Seamless Transitions",
              content:
                "A smooth transition post-deployment is crucial. We provide hypercare support, bug fixes, and performance tuning to ensure your applications continue to operate optimally. With expert oversight, go-live disruptions are minimized, and system reliability is continuously improved.",
            },
          ].map((item, index) => (
            <motion.div
              key={index}
              className="content-box"
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: false }}
            >
              <h2 className="blue-heading">{item.title}</h2>
              <p>{item.content}</p>
            </motion.div>
          ))}
        </div>
      </div>

      <motion.section
        className="focus-container"
        initial={{ scale: 0.9, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="focus-content">
          <div className="focus-img">
            <img src="/images/CYNTRALABS White Logo.png" alt="Focus Logo" />
          </div>
          <h2 className="focus-title">
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"pink"}}>With our proven </span>track record
                of 50+ successful migrations,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#e8c46b"}} >we turn complexity </span> into
                opportunity,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#71b788"}}>keeping your business</span> ahead
                of the curve.
              </div>
            </div>
          </h2>
        </div>
      </motion.section>

      <motion.section
        className="industries-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="industries-background">
          <img
            src="images/digital-img.jpg.webp"
            alt="Industries"
            className="industries-image"
          />
        </div>
        <div className="offer-bottom"></div>
        <div className="industries-card">
          <h2 className="industries-title">Our Approach</h2>
          <div className="industries-divider"></div>
          <div className="industries-list">
            {[
              "Continuous System Monitoring",
              "Rapid Issue Resolution",
              "Application & Middleware Health Checks",
              "Data Integrity & Storage Oversight",
              "Deployment & Post-Go-Live Support",
            ].map((title, index) => (
              <motion.div
                key={index}
                className="industry-item"
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: false }}
              >
                <h3 className="industry-title">{title}</h3>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      <motion.div
        className="technologies-container"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="tech-container">
          <div className="tech-left">
            <h1>
              Technologies we Leverage <br />
            </h1>
            <button className="button-about" onClick={() => navigate ('/AboutUs')}>
                      Get to know us &nbsp;&nbsp;&nbsp;
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          <div className="technologies-right">
            {[
              "MuleSoft-Logo-removebg-preview 1.jpg",
              "azure.png",
              "Sap-Logo.png",
              "boomilogo.png",
              "workday-logo.png",
              "Workato-Logo.png",
              "salesforce-logo.png",
              "Dynamics.png",
            ].map((img, index) => (
              <motion.img
                key={index}
                src={`/images/${img}`}
                alt={img.split(".")[0]}
                className="tech-logo"
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }}
              />
            ))}
          </div>
        </div>
      </motion.div>

         {/* Contact Us */}
<Footer/>
    </>
  );
};

export default AMS;
