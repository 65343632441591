import React, { useState, useEffect } from "react";
import "./ServiceNowCard.css";
import PartnerPage from "../../assets/images/google.png";
import ServiceNowLogo from "../../assets/images/Google-Cloud-Logo.png";
import Header from "../Header";
import { Facebook, Instagram, Twitter } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";
const content = {
  heroSection: {
    backgroundImage: PartnerPage,
    brand: (
      <img
        src={ServiceNowLogo}
        className="service-now-logo"
        alt="ServiceNow Logo"
        width={"100%"}
      />
    ),
    heading: "Scalable, Secure, and Smart Cloud Solutions",
    description:
      "Power Your Business with Google Cloud’s Cutting-Edge Technology",
    imageBoxText:
      "At Cyntralabs, we help businesses harness the power of Google Cloud to drive agility, security, and innovation. From scalable computing to AI-driven analytics and secure cloud storage, our expertise ensures a smooth cloud transformation. Whether you’re migrating workloads, modernizing applications, or optimizing infrastructure, our seamless Google Cloud integration enhances efficiency, collaboration, and business growth.",
  },
  flexibleModules: {
    text: "Customize Google Cloud solutions to meet your business needs with flexible, scalable, and efficient modules."
  },
  benefits: [
    {
      title: "Scalability & Performance",
      description:
        "Effortlessly scale with demand while maintaining high availability and reliability. Google Cloud’s infrastructure automatically adapts to traffic fluctuations, ensuring seamless operations even during peak loads. With global network capabilities and high-speed computing power, businesses can expand without worrying about downtime or performance bottlenecks.",
    },
    {
      title: "AI & Machine Learning",
      description:
        "Leverage powerful insights and automation to drive innovation. Google Cloud’s AI and ML services process massive datasets, automate complex workflows, and uncover trends that enable smarter decision-making. From predictive analytics to natural language processing, AI-driven solutions boost operational efficiency and customer engagement.",
    },
    {
      title: "Multi-Cloud & Hybrid Support",
      description:
        "Operate across different environments with seamless interoperability. Google Cloud’s flexible architecture supports on-premises, public, and private cloud setups, ensuring smooth workload transitions without vendor lock-in. This hybrid approach optimizes costs, enhances business continuity, and provides greater control over IT infrastructure.",
    },
    {
      title: "Enhanced Security",
      description:
        "Protect your data with Google’s industry-leading security framework. Built-in encryption, advanced identity management, and AI-powered threat detection safeguard sensitive information. With compliance certifications and continuous monitoring, businesses can prevent cyber threats and maintain regulatory compliance with confidence.",
    },
    {
      title: "Data-Driven Decisions",
      description:
        "Use BigQuery and AI-driven insights to unlock real-time analytics for smarter decision-making. Google Cloud enables businesses to process structured and unstructured data at scale, providing deep insights into customer behavior, operations, and market trends. These actionable insights drive efficiency, growth, and competitive advantage.",
    },
    {
      title: "Seamless Integration",
      description:
        "Easily connect Google Cloud with your existing enterprise applications. With pre-built APIs, connectors, and compatibility with platforms like SAP, Salesforce, and Microsoft, businesses can modernize infrastructure without disrupting current workflows. This seamless integration enhances collaboration, automates processes, and accelerates cloud adoption.",
    },
  ],
  cloudIntegration: {
    text: "Whether you need to migrate workloads, optimize cloud resources, or implement AI-driven solutions, Cyntralabs ensures a seamless Google Cloud transformation tailored to your business needs.",
  },
  whyChooseUs: {
    title: "Why Choose us ?",
    points: [
      "Deep expertise in Google Cloud architecture & services",
      "Proven success in cloud migrations & modernization",
      "24/7 support & continuous optimization for cloud efficiency",
      "Customized cloud strategies tailored to your business needs",
      "Security-first approach with compliance & data protection best practices",
      "Seamless integration with existing enterprise applications",
      "Cost-optimized cloud solutions to maximize ROI",
      "End-to-end project management from assessment to deployment",
      "Dedicated team of certified Google Cloud experts",
      "Ongoing training and support to ensure long-term cloud success",
    ],
  },
};
const GoogleCloudCard = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
    <Header />
      {/* Hero Section */}
      <div
        className="card-container"
        style={{
          backgroundImage: `url(${content.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="text-box">
          <h2>
            <span className="brand">{content.heroSection.brand}</span>
          </h2>
          <h3>{content.heroSection.heading}</h3>
          <p>{content.heroSection.description}</p>
        </div>
        <div className="image-box">
          <p className="description">{content.heroSection.imageBoxText}</p>
        </div>
      </div>
      {/* Benefits Section */}
      <div className="business-benefits">
        <div className="benefits-heading">
          <h1 className="main-heading-key">Key Benefits for Your Business</h1>
          <p className="heading-sub">Tailored Solutions for Every Industry</p>
        </div>

        <div className="benefits-content">
          {content.benefits.map((benefit, index) => (
            <div className="benefit" key={index}>
              <h2 className="heading-title">{benefit.title}</h2>
              <p className="heading-descript">{benefit.description}</p>
              {index < content.benefits.length - 1 && (
                <hr className="benefit-divider" />
              )}
            </div>
          ))}
        </div>
      </div>
      {/* Flexible Modules Section */}
      <div className="container">
        <div className="content">{content.flexibleModules.text}</div>
      </div>
      {/* Why Choose Us Section */}
      <div
        style={{
          background:
            "linear-gradient(280.19deg, rgba(191, 167, 35, 0.35) -15.92%, rgba(62, 118, 228, 0.4) 91.37%)",
        }}
        className="why-choose-us"
      >
        <div className="content-choose">
          <h2 className="title-choose">{content.whyChooseUs.title}</h2>
          <div className="points-choose">
            {content.whyChooseUs.points.map((point, index) => (
              <div className="point-choose" key={index}>
                {point}
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Contact Us */}

<Footer/>
    </>
  );
};

export default GoogleCloudCard;
