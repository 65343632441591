import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";
import Footer from "../Footer";

const CaseStudyTwo = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation();

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 80, // Adjust based on your header height
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="case-study-page">
      <Header />
      {/* Banner Section */}
      <motion.section
        className="banner-section"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div
          className="banner-bg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "url('/images/image (7).jpg') center/cover no-repeat",
            filter: "blur(4px)",
            zIndex: -1,
          }}
        />
        <div className="banner-content">
          <a href="/blogs" className="back-to-list">
            Back to list
          </a>
          <motion.h1
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            Revolutionizing CI/CD for Azure Logic Apps
          </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>Estimated reading time: {readingMinutes} minutes</span>
            <button className="speaker" onClick={toggleSpeech} style={{backgroundColor: "#F2F2F2", color:"black"}}>
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{ marginTop: "1rem" ,
                backgroundColor: "#F2F2F2",
    border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert 
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </motion.section>

      {/* Main Content Section */}
      <div className="case-study-container">
        {/* Sidebar Navigation */}
        <aside className="case-study-sidebar">
          <ul>
            <li
              className={activeSection === "business-needs" ? "active" : ""}
              onClick={() => handleClick("business-needs")}
            >
              Business Needs
            </li>
            <li
              className={activeSection === "ci-cd-innovation" ? "active" : ""}
              onClick={() => handleClick("ci-cd-innovation")}
            >
              Unparalleled CI/CD Innovation for Logic Apps
            </li>
            <li
              className={activeSection === "solution" ? "active" : ""}
              onClick={() => handleClick("solution")}
            >
              Solution: Automated Deployment with Intelligent Testing
            </li>
            <li
              className={activeSection === "outcomes" ? "active" : ""}
              onClick={() => handleClick("outcomes")}
            >
              Outcomes: Unmatched Efficiency & Quality Gains
            </li>
            <li
              className={activeSection === "client-testimonial" ? "active" : ""}
              onClick={() => handleClick("client-testimonial")}
            >
              Client Testimonial
            </li>
            <li
              className={activeSection === "future-roadmap" ? "active" : ""}
              onClick={() => handleClick("future-roadmap")}
            >
              Future Roadmap
            </li>
            <li
              className={activeSection === "about-talking-rain" ? "active" : ""}
              onClick={() => handleClick("about-talking-rain")}
            >
              About Talking Rain Beverage Company
            </li>
          </ul>
        </aside>

        {/* Article Section */}
        <motion.section
          className="article-section"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "easeInOut" }}
        >
          <div className="container">
            <h2 id="business-needs">Business Needs</h2>
            <p>
              Following the successful migration from MuleSoft to Azure Logic
              Apps and Function Apps, Talking Rain Beverage Company faced a new
              challenge—deploying, managing, and automating their CI/CD pipeline
              efficiently.
            </p>
            <ul>
              <li>
                Seamless Deployment: Automate the release cycle for Logic Apps
                and Function Apps.
              </li>
              <li>
                Integrated Testing Framework: Validate each deployment against
                predefined test cases.
              </li>
              <li>
                Zero-Downtime Deployment: Ensure business continuity with
                fail-safe rollback mechanisms.
              </li>
              <li>
                Quality & Cost Optimization: Reduce production defects and
                improve time-to-market efficiency.
              </li>
            </ul>

            <h2 id="ci-cd-innovation">
              Unparalleled CI/CD Innovation for Logic Apps
            </h2>
            <p>
              Unlike traditional application CI/CD pipelines, deploying and
              testing Azure Logic Apps and Function Apps in a structured,
              automated manner posed unique complexities:
            </p>
            <ul>
              <li>
                Stateful vs. Stateless Workflows: Logic Apps involve
                long-running workflows, requiring state-aware test executions.
              </li>
              <li>
                Dynamic Infrastructure: Configuration variations across
                environments made standard CI/CD models inefficient.
              </li>
              <li>
                Lack of Native Testing in Pipelines: Unlike standard
                applications, there was no existing testing framework that
                validated deployed Logic Apps before promotion.
              </li>
            </ul>
            <p>
              This is where we pioneered an industry-first solution, integrating
              a custom testing framework within the CI/CD pipeline, redefining
              how Logic Apps are deployed and validated.
            </p>

            <h2 id="solution">
              Solution: Automated Deployment with Intelligent Testing
            </h2>
            <p>
              CyntraLabs partnered with Talking Rain to design and implement a
              first-of-its-kind CI/CD pipeline embedding automated testing.
            </p>
            <p>Key Innovations:</p>
            <ul>
              <li>Custom CI/CD Workflow</li>
              <li>Test-Driven Deployment Strategy</li>
              <li>Smart Defect Prediction & Prevention</li>
              <li>Real-Time Monitoring & Governance</li>
            </ul>

            <h2 id="outcomes">
              Outcomes: Unmatched Efficiency & Quality Gains
            </h2>
            <ul>
              <li>30% Reduction in Production Defects</li>
              <li> 50% Faster Deployment Cycles </li>
              <li>Cost Savings in Debugging & Fixes </li>
              <li>Higher Code Quality & Stability </li>
              <li> Industry-First Innovation</li>
            </ul>

            <h2 id="client-testimonial">Client Testimonial</h2>
            <p>
              "CyntraLabs redefined our integration and deployment strategy.
              Their ability to embed an automated testing framework directly
              within our CI/CD pipeline for Logic Apps was nothing short of
              groundbreaking."
            </p>

            <h2 id="future-roadmap">Future Roadmap</h2>
            <p>
              Encouraged by the success of this initiative, Talking Rain plans
              to extend the CI/CD automation and intelligent testing framework
              to:
            </p>
            <ul>
              <li>Automate API and Microservices Testing</li>
              <li>Leverage AI for Self-Healing Pipelines</li>
              <li>Enhance Security Compliance Automation</li>
            </ul>

            <h2 id="about-talking-rain">About Talking Rain Beverage Company</h2>
            <p>
              Founded in 1987, Talking Rain is a leading U.S. beverage
              manufacturer known for its Sparkling Ice products.
            </p>
          </div>
        </motion.section>
      </div>
      {/* Article Section */}

      <div className="exploreblogs">
        {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
        <div className="explore-blogs">
          <h3>Featured Articles</h3>
          <ul className="explore-blogs-list">
            <li>
              <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                Revolutionizing CI/CD for Azure Logic Apps
              </a>
            </li>
            <li>
              <a href="/blogs/Seamless-migration-d365">
                Seamless Migration to D365: Transforming ERP for Future
              </a>
            </li>
            <li>
              <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                Enterprise-Scale Integration Transformation: From iWay to
                MuleSoft
              </a>
            </li>
            <li>
              <a href="/blogs/segment-cdp-data-marketing">
                Driving Digital Transformation: Integrating Segment CDP
              </a>
            </li>

            <li>
              <a href="/blogs/Integrating-QAD-and-EAM">
                Enhancing Eligibility Verification with Dell Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/top-10-best-reads">
                Seamless Order Management with Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/SAP-to-Epicor">
                SAP to Epicor Integration Using Azure Logic Apps
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Contact Us */}
<Footer/>
    </div>
  );
};

export default CaseStudyTwo;
