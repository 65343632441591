import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";
import Footer from "../Footer";

const CaseStudySix = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation(); // Initialize animation controls for framer-motion

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Ensure this is set to smooth
        block: "start", // Align the section at the top of the viewport
        inline: "nearest", // Prevent horizontal scroll if not needed
      });
    }
  };

  return (
    <>
      <div className="case-study-page">
        <Header />
        {/* Banner Section */}
        <motion.section
          className="banner-section"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          {/* Blurred Background Layer */}
          <div
            className="banner-bg"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "url('/images/freepik__enhance__67791.png') center/cover no-repeat",
              filter: "blur(2px)" /* Background blur applied */,
              zIndex: -1,
            }}
          />

          {/* Content inside the banner section */}
          <div className="banner-content">
            <a href="/blogs" className="back-to-list" style={{color: "#555"}}>
              Back to list
            </a>
            <motion.h1
              initial={{ opacity: 0, scale: 1.1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
              style={{color: "#555"}}
   
            >
              Modernizing EDI Integration with MuleSoft
            </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span style={{color: "#555"}}>Estimated reading time: {readingMinutes} minutes</span>
            <button className="speaker" onClick={toggleSpeech} style={{backgroundColor: "#F2F2F2", color:"black"}}>
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{ marginTop: "1rem" ,
                backgroundColor: "#F2F2F2",
    border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert 
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          </div>
        </motion.section>

        {/* Main Content Section */}
        <div className="case-study-container">
          {/* Sidebar Navigation */}
          <aside className="case-study-sidebar">
            <ul>
              <li
                className={activeSection === "business-needs" ? "active" : ""}
                onClick={() => handleClick("business-needs")}
              >
                Business Needs
              </li>
              <li
                className={activeSection === "solution" ? "active" : ""}
                onClick={() => handleClick("solution")}
              >
                Solution: Precision-Driven ERP Migration to D365
              </li>
              <li
                className={activeSection === "outcomes" ? "active" : ""}
                onClick={() => handleClick("outcomes")}
              >
                Outcomes: Unmatched ERP Transformation & Efficiency
              </li>
              <li
                className={
                  activeSection === "client-testimonial" ? "active" : ""
                }
                onClick={() => handleClick("client-testimonial")}
              >
                Client Testimonial
              </li>
              <li
                className={activeSection === "future-roadmap" ? "active" : ""}
                onClick={() => handleClick("future-roadmap")}
              >
                Future Roadmap
              </li>
              <li
                className={
                  activeSection === "about-talking-rain" ? "active" : ""
                }
                onClick={() => handleClick("about-talking-rain")}
              >
                About Talking Rain Beverage Company
              </li>
            </ul>
          </aside>

          {/* Article Section */}
          <motion.section
            className="article-section"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <div className="container">
              <motion.h2
                id="business-needs"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3, duration: 1, ease: "easeOut" }}
              >
                Business Needs
              </motion.h2>
              <p>
                <strong>Mark Anthony Group</strong>, one of North America's
                largest beverage companies, relied on Electronic Data
                Interchange (EDI) transactions to communicate with vendors,
                suppliers, and partners. Their existing legacy ETL-based EDI
                system was:
              </p>

              <ul>
                <li>
                  <strong>Inflexible & Difficult to Scale:</strong> Adding new
                  vendors or adjusting to evolving business needs was slow and
                  complex.
                </li>
                <li>
                  <strong>Limited in Functionality:</strong> Lacked real-time
                  processing, automation, and integration with modern
                  cloud-based ERP and CRM systems.
                </li>
                <li>
                  <strong>High Maintenance & Operational Costs:</strong>{" "}
                  Required significant manual intervention, leading to
                  inefficiencies and delays.
                </li>
              </ul>

              <p>
                To support rapid global expansion and supply chain efficiency,{" "}
                <strong>Mark Anthony Group</strong> needed a modern, scalable,
                and automated EDI integration framework capable of seamless
                communication across multiple vendors while ensuring real-time
                data processing and compliance.
              </p>

              <motion.h2
                id="solution"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1, duration: 1, ease: "easeOut" }}
              >
                Key Challenges in the Transformation
              </motion.h2>
              <h3>Integration Challenges Faced by Mark Anthony Group</h3>

              <ul>
                <li>
                  <strong>Fragmented Customer Data:</strong> Data was scattered
                  across multiple tools (ActiveCampaign, Freshdesk, Google Ads,
                  Facebook Ads, Salesforce, etc.) making it difficult to
                  personalize campaigns.
                </li>
                <li>
                  <strong>Lack of Real-Time Insights:</strong> Marketing teams
                  had to rely on manual data aggregation and delayed reporting,
                  limiting the effectiveness of targeted ads.
                </li>
                <li>
                  <strong>Scaling Issues:</strong> The existing architecture
                  struggled to handle high-volume event-driven data processing
                  required for an enterprise-grade CDP.
                </li>
                <li>
                  <strong>Complex Data Orchestration:</strong> Ensuring seamless
                  data flow across multiple platforms while maintaining
                  accuracy, consistency, and compliance was a major hurdle.
                </li>
              </ul>

              <motion.h2
                id="outcomes"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
              >
                CyntraLabs' Solution: A Scalable EDI Framework with MuleSoft
              </motion.h2>
              <p>
                CyntraLabs designed and implemented a next-gen EDI integration
                powered by MuleSoft’s Anypoint Platform, ensuring:
              </p>

              <ul>
                <li>
                  <h4 style={{ fontSize: "1.5rem" }}>End-to-End EDI Automation:</h4> Replaced the
                  legacy ETL system with event-driven, real-time processing.
                </li>
                <li>
                   <h4 style={{ fontSize: "1.5rem" }}>Scalability & API-First Approach:</h4> New vendors
                  can be onboarded within days instead of weeks.
                </li>
                <li>
                   <h4 style={{ fontSize: "1.5rem" }}>
                    Seamless Integration with Microsoft D365 & ERP Systems:
                  </h4>{" "}
                  Enabled bi-directional data exchange across the supply chain.
                </li>
                <li>
                <h4 style={{ fontSize: "1.5rem" }}>Robust Error Handling & Compliance:</h4> Automated
                  validation to prevent incorrect transactions and ensure
                  regulatory compliance.
                </li>
              </ul>

              <h3 style={{ fontSize: "2rem" }}>Key Implementation Steps</h3>
              <br/>

              <ol>
                <li>
                 
                  <h3 style={{ fontSize: "1.5rem" }} >Assessment & Strategy</h3>
                  <ul>
                    <li>
                      Analyzed the legacy ETL-based EDI system to identify
                      bottlenecks and inefficiencies.
                    </li>
                    <li>
                      Designed an API-led integration strategy with MuleSoft as
                      the middleware.
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 style={{ fontSize: "1.5rem" }}>Automated EDI Parsing & Processing</h3>
                  <ul>
                    <li>
                      Built custom EDI parsers within MuleSoft to support
                      multiple formats (X12, EDIFACT, XML, JSON).
                    </li>
                    <li>
                      Established event-driven architecture using MuleSoft’s
                      Message Queues and Evented APIs for real-time processing.
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 style={{ fontSize: "1.5rem" }}>Seamless Vendor & Supplier Communication</h3>
                  <ul>
                    <li>
                      Integrated with multiple trading partners via secure APIs,
                      SFTP, AS2, and VAN (Value-Added Networks).
                    </li>
                    <li>Enabled real-time transaction tracking & reporting.</li>
                  </ul>
                </li>
                <li>
                  <h3 style={{ fontSize: "1.5rem" }}>Scalability & Future-Proofing</h3>
                  <ul>
                    <li>
                      Leveraged MuleSoft CloudHub for high availability &
                      auto-scaling.
                    </li>
                    <li>
                      Built a centralized EDI dashboard within Anypoint
                      Monitoring for visibility, alerting, and troubleshooting.
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 style={{ fontSize: "1.5rem" }}>Compliance, Security & Error Handling</h3>
                  <ul>
                    <li>
                      Implemented automated validation & compliance checks to
                      eliminate errors before processing.
                    </li>
                    <li>
                      Designed failover & rollback mechanisms, ensuring zero
                      downtime and seamless recovery in case of issues.
                    </li>
                  </ul>
                </li>
              </ol>

              <motion.h2
                id="client-testimonial"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
              >
                Client Testimonial
              </motion.h2>
              <p>
                CyntraLabs transformed our EDI processing from a slow, rigid
                system to a modern, scalable solution. Their expertise in
                MuleSoft and cloud-based integration has significantly improved
                our supply chain efficiency and vendor collaboration.{" "}
              </p>

              <motion.h2
                id="future-roadmap"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.9, duration: 1, ease: "easeOut" }}
              >
                Future Roadmap
              </motion.h2>
              <p>
                With a successful ERP migration, Talking Rain is now positioned
                to:
              </p>
              <ul>
                <li>
                  Enhance AI-driven analytics for smarter decision-making within
                  D365.
                </li>
                <li>
                  Implement advanced automation for financial forecasting and
                  demand planning.
                </li>
                <li>
                  Extend Power BI integration for real-time business insights.
                </li>
              </ul>

              <motion.h2
                id="about-talking-rain"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 2.2, duration: 1, ease: "easeOut" }}
              >
                About Talking Rain Beverage Company
              </motion.h2>
              <p>
                Founded in 1987, Talking Rain is a leader in the U.S. beverage
                industry, best known for its Sparkling Ice products. As a
                company committed to innovation, Talking Rain continuously
                embraces cutting-edge technology to optimize business operations
                and drive growth.
              </p>
            </div>
          </motion.section>
        </div>

        {/* Article Section */}

        <div className="exploreblogs">
          {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
          <div className="explore-blogs">
            <h3>Featured Articles</h3>
            <ul className="explore-blogs-list">
              <li>
                <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                  Revolutionizing CI/CD for Azure Logic Apps
                </a>
              </li>
              <li>
                <a href="/blogs/Seamless-migration-d365">
                  Seamless Migration to D365: Transforming ERP for Future
                </a>
              </li>
              <li>
                <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                  Enterprise-Scale Integration Transformation: From iWay to
                  MuleSoft
                </a>
              </li>
              <li>
                <a href="/blogs/segment-cdp-data-marketing">
                  Driving Digital Transformation: Integrating Segment CDP
                </a>
              </li>

              <li>
                <a href="/blogs/Integrating-QAD-and-EAM">
                  Enhancing Eligibility Verification with Dell Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/top-10-best-reads">
                  Seamless Order Management with Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/SAP-to-Epicor">
                  SAP to Epicor Integration Using Azure Logic Apps
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Contact Us */}
<Footer/>
      </div>
    </>
  );
};

export default CaseStudySix;
