import React, { useEffect, useState } from "react";

import { Linkedin, Instagram, Youtube, Volume2, VolumeX } from "lucide-react";

import { motion, useAnimation } from "framer-motion";

import Footer from "../Footer"
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import "./CaseStudies.css";

const CaseStudyOne = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //speech
  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation(); // Initialize animation controls for framer-motion

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Ensure this is set to smooth
        block: "start", // Align the section at the top of the viewport
        inline: "nearest", // Prevent horizontal scroll if not needed
      });
    }
  };

  return (
    <div className="case-study-page">
      <Header />
      {/* Banner Section */}
      <motion.section
        className="banner-section"
        style={{
          background:
            "url('/images/AdobeStock_615177263.jpeg') center/cover no-repeat",
        }}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div className="banner-content">
          <a href="/blogs" className="back-to-list" style={{color:"#1f5f7b"}}>
            Back to list
          </a>
          <motion.h1
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            style={{color:"#1f5f7b"}}
          >
            Migration from Mulesoft to Azure Logic Apps
          </motion.h1>
          {/* Reading Time & Speaker Button */}
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span style={{color:"#1f5f7b"}}>Estimated reading time: {readingMinutes} minutes</span>
            <button className="speaker" onClick={toggleSpeech} style={{ color:"#1f5f7b"}}>
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{ marginTop: "1rem" ,
                backgroundColor: "#F2F2F2",
    border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </motion.section>

      {/* Main Content Section */}
      <div className="case-study-container">
        {/* Sidebar Navigation */}
        <aside className="case-study-sidebar">
          <ul>
            <li
              className={activeSection === "business-needs" ? "active" : ""}
              onClick={() => handleClick("business-needs")}
            >
              Business Needs
            </li>
            <li
              className={activeSection === "outcomes" ? "active" : ""}
              onClick={() => handleClick("outcomes")}
            >
              Outcomes
            </li>
            <li
              className={activeSection === "client-testimonial" ? "active" : ""}
              onClick={() => handleClick("client-testimonial")}
            >
              Client Testimonial
            </li>
            <li
              className={activeSection === "solution" ? "active" : ""}
              onClick={() => handleClick("solution")}
            >
              Solution
            </li>
            <li
              className={activeSection === "azure-integration" ? "active" : ""}
              onClick={() => handleClick("azure-integration")}
            >
              Delivering Value Through Azure Integration
            </li>
            <li
              className={activeSection === "future-growth" ? "active" : ""}
              onClick={() => handleClick("future-growth")}
            >
              Results & Future Growth
            </li>
            <li
              className={activeSection === "about-talking-rain" ? "active" : ""}
              onClick={() => handleClick("about-talking-rain")}
            >
              About Talking Rain Beverage Company
            </li>
          </ul>
        </aside>

        {/* Article Section */}
        <motion.section
          className="article-section"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "easeInOut" }}
        >
          <div className="container">
            <motion.h2
              id="business-needs"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 1, ease: "easeOut" }}
            >
              Business Needs
            </motion.h2>
            <p>
              Talking Rain Beverage Company, renowned for its Sparkling Ice
              products, is a leading beverage manufacturer in the United States.
              To maintain its competitive edge, the company relies on seamless
              integration across various systems, including supply chain
              management, sales platforms, and customer relationship management.
              However, the escalating costs and complexities associated with
              their existing MuleSoft integration platform prompted a search for
              a more efficient and cost-effective solution.
            </p>
            <motion.h2
              id="outcomes"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
            >
              Outcomes
            </motion.h2>
            <p>
              <ul>
                <li>
                  <strong>Cost Reduction:</strong> Achieved a 50% decrease in
                  operational costs by migrating to Azure Logic Apps and
                  Function Apps.
                </li>
                <li>
                  <strong>Enhanced Efficiency:</strong> Improved integration
                  performance, leading to faster data processing and reduced
                  latency.
                </li>
                <li>
                  <strong>Unified Ecosystem:</strong> Established a cohesive
                  Azure environment, simplifying management and scalability.
                </li>
                <li>
                  <strong>On-Time, On-Budget Delivery:</strong> Completed the
                  migration within the agreed timeline and budget constraints.
                </li>
              </ul>
            </p>
            <motion.h2
              id="client-testimonial"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.7, duration: 1, ease: "easeOut" }}
            >
              Client Testimonial
            </motion.h2>
            <p>
              "CyntraLabs' expertise was instrumental in our seamless transition
              to the Azure ecosystem. Their strategic approach and technical
              proficiency have significantly optimized our operations."
            </p>
            <motion.h2
              id="solution"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1, duration: 1, ease: "easeOut" }}
            >
              Solution
            </motion.h2>
            <p>
              Facing high costs and complexities with MuleSoft, Talking Rain
              sought a more streamlined integration solution. CyntraLabs
              partnered with the company to design and implement a comprehensive
              migration strategy to Azure Logic Apps and Function Apps.
            </p>
            <motion.h2
              id="azure-integration"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
            >
              Delivering Value Through Azure Integration
            </motion.h2>
            <p>
              By transitioning to Azure Logic Apps and Function Apps, Talking
              Rain benefited from:
              <ul>
                <li>
                  <strong>Cost Efficiency:</strong> Azure's pay-as-you-go model
                  significantly reduced operational expenses.
                </li>
                <li>
                  <strong>Scalability:</strong> The new solution easily adapts
                  to increasing data volumes and integration demands.
                </li>
                <li>
                  <strong>Enhanced Performance:</strong> Improved data
                  processing speeds and system reliability.
                </li>
              </ul>
            </p>
            <motion.h2
              id="future-growth"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
            >
              Results & Future Growth
            </motion.h2>
            <p>
              The successful migration led to:
              <ul>
                <li>
                  A 50% reduction in integration-related operational costs.
                </li>
                <li>
                  Streamlined operations within a unified Azure ecosystem.
                </li>
                <li>
                  Positioning Talking Rain for future technological advancements
                  and scalability.
                </li>
              </ul>
            </p>
            <motion.h2
              id="about-talking-rain"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.9, duration: 1, ease: "easeOut" }}
            >
              About Talking Rain Beverage Company
            </motion.h2>
            <p>
              Founded in 1987 and based in Preston, Washington, Talking Rain
              Beverage Company is renowned for its Sparkling Ice line of
              flavored sparkling waters. With a commitment to innovation and
              quality, the company has established itself as a leader in the
              beverage industry.
            </p>
          </div>
        </motion.section>
      </div>

      {/* Article Section */}

      <div className="exploreblogs">
        {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
        <div className="explore-blogs">
          <h3>Featured Articles</h3>
          <ul className="explore-blogs-list">
            <li>
              <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                Revolutionizing CI/CD for Azure Logic Apps
              </a>
            </li>
            <li>
              <a href="/blogs/Seamless-migration-d365">
                Seamless Migration to D365: Transforming ERP for Future
              </a>
            </li>
            <li>
              <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                Enterprise-Scale Integration Transformation: From iWay to
                MuleSoft
              </a>
            </li>
            <li>
              <a href="/blogs/segment-cdp-data-marketing">
                Driving Digital Transformation: Integrating Segment CDP
              </a>
            </li>

            <li>
              <a href="/blogs/Integrating-QAD-and-EAM">
                Enhancing Eligibility Verification with Dell Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/top-10-best-reads">
                Seamless Order Management with Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/SAP-to-Epicor">
                SAP to Epicor Integration Using Azure Logic Apps
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Contact Us */}
<Footer/>
    </div>
  );
};

export default CaseStudyOne;
