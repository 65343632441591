import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";
import Footer from "../Footer";

const CaseStudyNine = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );
  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation();

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <>
      <div className="case-study-page">
        <Header />
        {/* Banner Section */}
        <motion.section
          className="banner-section"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <div
            className="banner-bg"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "url('/images/freepik__enhance__67791.png') center/cover no-repeat",
              filter: "blur(4px)",
              zIndex: -1,
            }}
          />

          <div className="banner-content">
            <a href="/blogs" className="back-to-list">
              Back to list
            </a>
            <motion.h1
              initial={{ opacity: 0, scale: 1.1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              SAP to Epicor Integration Using Azure Logic Apps
            </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>Estimated reading time: {readingMinutes} minutes</span>
            <button className="speaker" onClick={toggleSpeech} style={{backgroundColor: "#F2F2F2", color:"black"}}>
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{ marginTop: "1rem" ,
                backgroundColor: "#F2F2F2",
    border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert 
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          </div>
        </motion.section>

        {/* Main Content Section */}
        <div className="case-study-container">
          {/* Sidebar Navigation */}
          <aside className="case-study-sidebar">
            <ul>
              {[
                "The Challenge: Bridging Two ERP Ecosystems",
                "The Logic Apps Integration Solution",
                "Key Benefits of the SAP to Epicor Integration",
                "Conclusion: Enabling a Unified ERP Ecosystem",
                "About BOPPY Company",
              ].map((section, index) => (
                <li
                  key={index}
                  className={activeSection === section ? "active" : ""}
                  onClick={() =>
                    handleClick(section.replace(/\s+/g, "-").toLowerCase())
                  }
                >
                  {section}
                </li>
              ))}
            </ul>
          </aside>

          {/* Article Section */}
          <motion.section
            className="article-section"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <div className="container">
              <motion.h2
                id="the-challenge:-bridging-two-erp-ecosystems"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3, duration: 1, ease: "easeOut" }}
              >
                The Challenge: Bridging Two ERP Ecosystems
              </motion.h2>
              <p>
                BOPPY, a leading manufacturing and supply chain solutions
                provider, faced integration challenges between SAP ERP and
                Epicor. The lack of a real-time connection resulted in manual
                data handling, procurement delays, and data inconsistencies.
              </p>
              <ul>
                <li>
                  <b>Manual Processes –</b> PO data had to be manually exported
                  from SAP and imported into Epicor.
                </li>
                <li>
                  <b>Delayed Order Processing –</b> No real-time sync caused
                  procurement bottlenecks.
                </li>
                <li>
                  <b>Data Inconsistencies –</b> BOM updates in SAP weren’t
                  reflected in Epicor, leading to production errors.
                </li>
              </ul>
              <p>
                To solve this, we implemented a serverless Azure Logic Apps
                integration for event-driven, automated data exchange.
              </p>

              <motion.h2
                id="the-logic-apps-integration-solution"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1, duration: 1, ease: "easeOut" }}
              >
                The Logic Apps Integration Solution
              </motion.h2>
              <p>
                With Azure Logic Apps, we designed an integration flow that
                automatically synchronizes purchase orders, BOM updates, and
                inventory data between SAP and Epicor.
              </p>
              <ol>
                <li>
                  <b>Triggering POs from SAP:</b> New purchase orders in SAP
                  trigger a Logic Apps workflow to push data into Epicor via
                  API.
                </li>
                <li>
                  <b>BOM Synchronization:</b> Engineering changes in SAP update
                  Epicor in real-time, preventing mismatches.
                </li>
                <li>
                  <b>Goods Receipts & Inventory Updates:</b> SAP stock movements
                  are mirrored in Epicor, ensuring accurate inventory.
                </li>
                <li>
                  <b>Error Handling:</b> Failed transactions are logged and
                  retried automatically.
                </li>
              </ol>

              <motion.h2
                id="key-benefits-of-the-sap-to-epicor-integration"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.4, duration: 1, ease: "easeOut" }}
              >
                Key Benefits of the SAP to Epicor Integration
              </motion.h2>
              <ul>
                <li>
                  Real-Time Data Sync – No more procurement or production
                  delays.
                </li>
                <li>
                  Eliminated Manual Work – Automated workflows save hours of
                  manual effort.
                </li>
                <li>
                  Accurate Data Exchange – BOMs, purchase orders, and inventory
                  levels always stay in sync.
                </li>
                <li>
                  Cloud-Native Scalability – Logic Apps’ serverless architecture
                  ensures low maintenance and high availability.
                </li>
              </ul>

              <motion.h2
                id="conclusion:-enabling-a-unified-erp-ecosystem"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 2, duration: 1, ease: "easeOut" }}
              >
                Conclusion: Enabling a Unified ERP Ecosystem
              </motion.h2>
              <p>
                By leveraging Azure Logic Apps, we created a scalable,
                event-driven, and real-time integration between SAP and Epicor.
                The project successfully eliminated manual processes, improved
                procurement efficiency, and ensured accurate BOM and inventory
                updates.
              </p>

              <motion.h2
                id="about-boppy-company"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 2.4, duration: 1, ease: "easeOut" }}
              >
                About BOPPY Company
              </motion.h2>
              <p>
                BOPPY specializes in manufacturing, procurement, and supply
                chain solutions, serving automotive, aerospace, and industrial
                sectors. With SAP and Epicor as core systems, BOPPY continuously
                innovates to optimize business operations.
              </p>
            </div>
          </motion.section>
        </div>

        {/* Article Section */}

        <div className="exploreblogs">
          {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
          <div className="explore-blogs">
            <h3>Featured Articles</h3>
            <ul className="explore-blogs-list">
              <li>
                <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                  Revolutionizing CI/CD for Azure Logic Apps
                </a>
              </li>
              <li>
                <a href="/blogs/Seamless-migration-d365">
                  Seamless Migration to D365: Transforming ERP for Future
                </a>
              </li>
              <li>
                <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                  Enterprise-Scale Integration Transformation: From iWay to
                  MuleSoft
                </a>
              </li>
              <li>
                <a href="/blogs/segment-cdp-data-marketing">
                  Driving Digital Transformation: Integrating Segment CDP
                </a>
              </li>

              <li>
                <a href="/blogs/Integrating-QAD-and-EAM">
                  Enhancing Eligibility Verification with Dell Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/top-10-best-reads">
                  Seamless Order Management with Boomi
                </a>
              </li>
              <li>
                <a href="/blogs/SAP-to-Epicor">
                  SAP to Epicor Integration Using Azure Logic Apps
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Contact Us */}

<Footer/>
      </div>
    </>
  );
};

export default CaseStudyNine;
