import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./SystemIntegration.css";
import Header from "../Header";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";

const ERPConsulting = () => {
     const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header />
      <motion.div
        className="system-integration"
        initial={{ opacity: 1 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
        style={{
          background:
            "url('/images/AdobeStock_1032241064 (1).jpeg') no-repeat center center / cover",
        }}
      >
        <div className="header h1">
          <h1>
            ERP Consulting <br /> & End-to-End Solutions
          </h1>
        </div>
      </motion.div>

      {/* Bridge Diverse Processes Swiftly Section */}
      <div className="bridge-container">
        <motion.div
          className="bridge-left"
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        >
          <h1>
            Unlocking Business Potential by optimizing, integratiing, and
            scaling with a Future-Ready ERP System{" "}
          </h1>
          <button className="button-Schedule" onClick={() => navigate ('/Get-In-Touch')}>
          Talk to our expert 
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animated-arrow"
  >
    <path
      d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
      stroke="#042E50"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
</button>

        </motion.div>

        <div
          className="vertical-line"
          style={{
            borderLeft: "0.0625rem solid #797979",
            height: "auto",
            minHeight: "80vh",
            marginRight: "70px",
            marginTop: "50px",
            marginLeft: "50px",
          }}
        ></div>

        {/* Right Content Section */}
        <motion.div
          className="bridge-right"
          initial={{ x: 50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        >
          {[
            {
              title: "Assessment & Strategic ERP Planning",
              text: "Every business is unique, and so is its ERP requirement. We conduct a thorough business analysis, identifying process inefficiencies, data silos, and automation opportunities. Our strategic ERP roadmap ensures your investment aligns with your growth and operational goals.",
            },
            {
              title: "System Selection & Implementation Roadmap",
              text: "Choosing the right ERP system is critical to success. With 30+ years of experience and expertise in SAP, Microsoft Dynamics 365, Oracle ERP, Infor, and NetSuite, we evaluate and recommend the best-fit ERP solution based on your industry, scalability, and business needs.",
            },
            {
              title: "Customization & Seamless Integration",
              text: "An out-of-the-box ERP rarely meets all business requirements. We customize and configure ERP systems to align with specific workflows while ensuring seamless integration with existing applications, databases, and third-party tools for end-to-end automation and real-time data synchronization.",
            },
          ].map((item, index) => (
            <motion.div
              className="content-box"
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: false }}
            >
              <h2 className="blue-heading">{item.title}</h2>
              <p>{item.text}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>

      <motion.section
        className="focus-container"
        initial={{ scale: 0.8, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
         <div className="focus-content">
          <div className="focus-img">
            <img src="/images/CYNTRALABS White Logo.png" alt="Focus Logo" />
          </div>
          <h2 className="focus-title">
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"pink"}}>With our proven </span>track record
                of 50+ successful migrations,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#e8c46b"}} >we turn complexity </span> into
                opportunity,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#71b788"}}>keeping your business</span> ahead
                of the curve.
              </div>
            </div>
          </h2>
        </div>
      </motion.section>

      <motion.div
        className="technologies-container"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="tech-container">
          <div className="tech-left">
            <h1>
              Technologies We Leverage <br />
            </h1>
            <button className="button-about" onClick={() => navigate ('/AboutUs')}>
                      Get to know us &nbsp;&nbsp;&nbsp;
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          <div className="technologies-right">
            {[
              "/images/MuleSoft-Logo-removebg-preview 1.jpg",
              "/images/azure.png",
              "/images/Sap-Logo.png",
              "/images/boomilogo.png",
              "/images/workday-logo.png",
              "/images/Workato-Logo.png",
              "/images/salesforce-logo.png",
              "/images/Dynamics.png",
            ].map((src, index) => (
              <motion.img
                key={index}
                src={src}
                alt="Technology Logo"
                className="tech-logo"
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: false }}
              />
            ))}
          </div>
        </div>
      </motion.div>

      {/* Contact Us */}
 <Footer/>
    </>
  );
};

export default ERPConsulting;
