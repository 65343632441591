import React, { useState, useEffect } from "react";
import "./ServiceNowCard.css";
import PartnerPage from "../../assets/images/SAPLABS.png";
import ServiceNowLogo from "../../assets/images/saplogo.jpg";
import Header from "../Header";
import { Facebook, Instagram, Twitter } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";
const content = {
  heroSection: {
    backgroundImage: PartnerPage,
    brand: (
      <img
        src={ServiceNowLogo}
        className="service-now-logo"
        alt="ServiceNow Logo"
        width={"100%"}
      />
    ),
    heading: "Intelligent Enterprise Solutions for Future-Ready Businesses",
    description:
      "Unlock Business Potential with SAP Integration",
    imageBoxText:
      "At Cyntralabs, we help businesses maximize efficiency and agility with SAP’s industry-leading ERP and business intelligence solutions. Whether it’s streamlining operations, enhancing financial performance, or gaining real-time business insights, our SAP integration services ensure a seamless and future-ready transformation.",
  },
  benefits: [
    {
      title: "End-to-End Business Automation",
      description:
        "Optimize processes across finance, HR, supply chain, and customer service to enhance efficiency and reduce operational costs. Automating repetitive tasks eliminates manual errors, accelerates workflows, and improves cross-department collaboration, leading to better resource utilization and faster decision-making.",
    },
    {
      title: "AI & Predictive Analytics",
      description:
        "Gain real-time insights to drive decision-making and stay ahead of market trends. AI-powered analytics enable businesses to identify patterns, predict demand, and optimize processes proactively. With machine learning-driven insights, organizations can improve forecasting, mitigate risks, and drive strategic growth.",
    },
    {
      title: "Seamless Cloud Connectivity",
      description:
        "Connect SAP with leading cloud platforms for hybrid solutions that enable greater flexibility. Businesses can integrate on-premises systems with public and private clouds, ensuring smooth data flow across environments. This hybrid approach enhances scalability, optimizes costs, and supports digital transformation initiatives.",
    },
    {
      title: "Improved Customer Experiences",
      description:
        "Personalize services with intelligent data-driven insights, creating more engaging and seamless interactions. Businesses can leverage AI to understand customer preferences, automate support processes, and deliver highly tailored solutions. Enhanced personalization boosts customer satisfaction, retention, and brand loyalty.",
    },
    {
      title: "Scalability & Agility",
      description:
        "Future-proof your business with flexible ERP solutions that adapt to changing market demands. With modular and cloud-based ERP capabilities, organizations can scale operations, expand globally, and quickly respond to new opportunities. Agile frameworks enable businesses to innovate faster and maintain a competitive edge.",
    },
    {
      title: "Regulatory Compliance & Security",
      description:
        "Ensure industry standards and data security while meeting global compliance requirements. With built-in governance frameworks, automated audits, and advanced encryption, businesses can safeguard sensitive data and mitigate regulatory risks. Robust security measures protect against cyber threats, ensuring business continuity and trust.",
    },
  ],
  cloudIntegration: {
    text: "Whether you're in retail, finance, healthcare, or manufacturing, our SAP integration expertise ensures tailored solutions that drive business success.",
  },
  flexibleModules: {
    text: "SAP’s enterprise resource planning (ERP) and business intelligence solutions help organizations streamline operations, enhance financial performance, and gain real-time business insights. ",
  },
  whyChooseUs: {
    title: "Why Choose us?",
    points: [
      "Certified SAP consultants with deep industry expertise",
      "Seamless SAP S/4HANA migration & cloud integration",
      "Customizable solutions tailored to your business needs",
      "Optimized SAP workflows for enhanced business efficiency",
      "Secure, compliant, and scalable SAP architecture implementation",
      "End-to-end SAP support, from implementation to post-deployment optimization",
      "Deep expertise in integrating SAP with third-party applications",
      "Comprehensive training and ongoing support for SAP users",
    ],
  },
};

const SAPLabsCard = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
    <Header />
      {/* Hero Section */}
      <div
        className="card-container"
        style={{
          backgroundImage: `url(${content.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="text-box">
          <h2>
            <span className="brand">{content.heroSection.brand}</span>
          </h2>
          <h3>{content.heroSection.heading}</h3>
          <p>{content.heroSection.description}</p>
        </div>
        <div className="image-box">
          <p className="description">{content.heroSection.imageBoxText}</p>
        </div>
      </div>
      {/* Benefits Section */}
      <div className="business-benefits">
        <div className="benefits-heading">
          <h1 className="main-heading-key">Key Benefits for Your Business</h1>
          <p className="heading-sub">Tailored Solutions for Every Industry</p>
        </div>

        <div className="benefits-content">
          {content.benefits.map((benefit, index) => (
            <div className="benefit" key={index}>
              <h2 className="heading-title">{benefit.title}</h2>
              <p className="heading-descript">{benefit.description}</p>
              {index < content.benefits.length - 1 && (
                <hr className="benefit-divider" />
              )}
            </div>
          ))}
        </div>
      </div>
      {/* Flexible Modules Section */}
      <div className="container">
        <div className="content">{content.flexibleModules.text}</div>
      </div>
      {/* Why Choose Us Section */}
      <div
        style={{
          background:
            "linear-gradient(111.18deg, rgba(255, 255, 255, 0.5) 0.78%, rgba(0, 158, 228, 0.12) 97.97%)",
        }}
        className="why-choose-us"
      >
        <div className="content-choose">
          <h2 className="title-choose">{content.whyChooseUs.title}</h2>
          <div className="points-choose">
            {content.whyChooseUs.points.map((point, index) => (
              <div className="point-choose" key={index}>
                {point}
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Contact Us */}
<Footer/>
    </>
  );
};

export default SAPLabsCard;
