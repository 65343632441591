import React , { useState, useEffect }from "react";
import "./ServiceNowCard.css";
import PatnerPage from "../../assets/images/Partnerpage.png";
import ServiceNowLogo from "../../assets/images/ServiceNowLogo.png";
import Header from "../Header";
import { Facebook, Instagram, Twitter } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";
const content = {
  heroSection: {
    backgroundImage: PatnerPage,
    brand: (
      <img
        src={ServiceNowLogo}
        className="service-now-logo"
        alt="ServiceNow Logo"
      />
    ),
    heading: "Intelligent Workflows for the Digital Enterprise",
    description:
      "ServiceNow - Intelligent Workflows for the Digital Enterprise",
    imageBoxText:
      "Navigate the complexities of ERP transformation with our highly experienced team. From upgrading to the latest ERP solutions to adopting cloud, we ensure a smooth and tailored transition.",
  },
  benefits: [
    {
      title: "Unified Platform",
      description:
        "Streamline operations with a single, integrated automation solution. By consolidating tools and workflows, businesses can reduce complexity, improve collaboration, and minimize integration challenges.",
    },
    {
      title: "Tailored Solutions for Every Industry",
      description:
        "Omnichannel Support bridge digital and in-person workflows for a seamless customer experience. Customers can interact with your business through multiple touchpoints—web, mobile, in-store, or customer support—without any disruption.",
    },
    {
      title: "AI-Driven Insights",
      description:
        "Use predictive analytics to enhance decision-making and optimize processes. AI-powered insights help identify trends, detect anomalies, and forecast future outcomes, enabling proactive business strategies.",
    },
    {
      title: "Automated Workflows",
      description:
        "Cost efficiency by automating repetitive tasks across departments. Automation eliminates manual errors, accelerates processing times, and allows employees to focus on high-value tasks. Businesses can streamline approvals, task assignments, and complex operations.",
    },
    {
      title: "Real-Time Analytics",
      description:
        "Track essential business metrics in real time for better strategic decisions. Accessing up-to-the-minute data helps businesses react quickly to market changes, optimize performance, and identify areas for improvement.",
    },
    {
      title: "Cybersecurity Assurance",
      description:
        "Safeguard data, ensure compliance, and protect against cyber threats. Advanced security features, including encryption, access controls, and real-time monitoring, help mitigate risks and prevent data breaches.",
    },
  ],
  flexibleModules: {
    text: "With flexible modules, businesses can customize ServiceNow to address specific challenges, from supply chain management to customer service excellence.",
  },
  whyChooseUs: {
    title: "Why Choose Us",
    points: [
      "Proven expertise in ServiceNow implementation",
      "Seamless integration with your existing systems",
      "End-to-end support, from planning to optimization",
    ],
  },
};

const ServiceNowCard = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
    <Header />
      {/* Hero Section */}
      <div
        className="card-container"
        style={{
          backgroundImage: `url(${content.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="text-box">
          <h2>
            <span className="brand">{content.heroSection.brand}</span>
          </h2>
          <h3>{content.heroSection.heading}</h3>
          <p>{content.heroSection.description}</p>
        </div>
        <div className="image-box">
          <p className="description">{content.heroSection.imageBoxText}</p>
        </div>
      </div>
      {/* Benefits Section */}
      <div className="business-benefits">
        <div className="benefits-heading">
          <h1 className="main-heading-key">Key Benefits for Your Business</h1>
          <p className="heading-sub">Tailored Solutions for Every Industry</p>
        </div>

        <div className="benefits-content">
          {content.benefits.map((benefit, index) => (
            <div className="benefit" key={index}>
              <h2 className="heading-title">{benefit.title}</h2>
              <p className="heading-descript">{benefit.description}</p>
              {index < content.benefits.length - 1 && (
                <hr className="benefit-divider" />
              )}
            </div>
          ))}
        </div>
      </div>
      {/* Flexible Modules Section */}
      <div className="container">
        <div className="content">{content.flexibleModules.text}</div>
      </div>
      {/* Why Choose Us Section */}
      <div
        style={{
          background:
            "linear-gradient(109.43deg, rgba(17, 45, 67, 0.25) 10.49%, rgba(111, 213, 103, 0.2) 96.56%)",
        }}
        className="why-choose-us"
      >
        <div className="content-choose">
          <h2 className="title-choose">{content.whyChooseUs.title}</h2>
          <div className="points-choose">
            {content.whyChooseUs.points.map((point, index) => (
              <div className="point-choose" key={index}>
                {point}
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Contact Us */}
<Footer/>
    </>
  );
};

export default ServiceNowCard;
