import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";

const CaseStudyEight = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );
  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation(); // Initialize animation controls for framer-motion

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Ensure this is set to smooth
        block: "start", // Align the section at the top of the viewport
        inline: "nearest", // Prevent horizontal scroll if not needed
      });
    }
  };

  return (
    <>
      <div className="case-study-page">
        <Header />
        {/* Banner Section */}
        <motion.section
          className="banner-section"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          {/* Blurred Background Layer */}
          <div
            className="banner-bg"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "url('/images/high-angle-boxes-laptop-arrangement.jpg') center/cover no-repeat",
              filter: "blur(4px)" /* ✅ Background blur applied */,
              zIndex: -1,
              transform: "scaleX(-1)",
            }}
          />

          {/* Content inside the banner section */}
          <div className="banner-content">
            <a href="/blogs" className="back-to-list">
              Back to list
            </a>
            <motion.h1
              initial={{ opacity: 0, scale: 1.1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              Seamless Order Management with Boomi
            </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>Estimated reading time: {readingMinutes} minutes</span>
            <button className="speaker" onClick={toggleSpeech} style={{backgroundColor: "#F2F2F2", color:"black", color:"black"}}>
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{ marginTop: "1rem" ,
                backgroundColor: "#F2F2F2",
    border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          </div>
        </motion.section>

        {/* Main Content Section */}
        <div className="case-study-container">
          {/* Sidebar Navigation */}
          <aside className="case-study-sidebar">
            <ul>
              <li
                className={activeSection === "The Challenge" ? "active" : ""}
                onClick={() => handleClick("business-needs")}
              >
                The Challenge: Disconnected Order & Inventory Systems
              </li>
              <li
                className={activeSection === "Our Approach" ? "active" : ""}
                onClick={() => handleClick("solution")}
              >
                The Boomi-Powered Integration Solution
              </li>
              <li
                className={activeSection === "Key Enhancements" ? "active" : ""}
                onClick={() => handleClick("outcomes")}
              >
                Key Benefits of Boomi Integration
              </li>
              <li
                className={
                  activeSection === "The Business Impact" ? "active" : ""
                }
                onClick={() => handleClick("business-impact")}
              >
                Conclusion: Transforming Enterprise Workflows with Boomi
              </li>
              <li
                className={
                  activeSection === "Client Testimonial" ? "active" : ""
                }
                onClick={() => handleClick("client-testimonial")}
              >
                Client Testimonial
              </li>
              <li
                className={activeSection === "Future Roadmap" ? "active" : ""}
                onClick={() => handleClick("future-roadmap")}
              >
                Future Roadmap
              </li>
            </ul>
          </aside>

          {/* Article Section */}
          <motion.section
            className="article-section"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <div className="container">
              <motion.h2
                id="business-needs"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3, duration: 1, ease: "easeOut" }}
              >
                The Challenge: Disconnected Order & Inventory Systems
              </motion.h2>
              <p>
                In today’s fast-paced business environment, integrating
                enterprise systems is no longer a luxury—it’s a necessity.
                Organizations relying on ERP (Enterprise Resource Planning)
                systems like QAD and Enterprise Asset Management (EAM) platforms
                need seamless data synchronization to ensure smooth operations.
                Recently, we implemented a Boomi-powered integration to automate
                the transfer of purchase orders, goods receipts, and order
                updates between QAD and EAM. This solution significantly
                improved data accuracy, reduced manual work, and streamlined
                procurement workflows.
              </p>

              <ul>
                <li>
                  Manual Data Entry: Teams had to manually transfer purchase
                  orders and receipts from QAD to EAM, leading to frequent
                  errors.
                </li>
                <li>
                  Delayed Order Processing: The lack of real-time data
                  synchronization resulted in procurement and inventory teams
                  working with outdated information.
                </li>
                <li>
                  Data Inconsistencies: Purchase order approvals, goods
                  receipts, and inventory updates in QAD weren’t always
                  reflected accurately in EAM, causing discrepancies.
                </li>
              </ul>

              <motion.h2
                id="solution"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1, duration: 1, ease: "easeOut" }}
              >
                The Boomi-Powered Integration Solution
              </motion.h2>
              <p>
                Using Boomi’s iPaaS (Integration Platform as a Service), we
                designed a robust integration that:
              </p>
              <ul>
                <li>
                  Extracts Data from QAD: The integration continuously monitors
                  purchase orders, goods receipts, and order updates in QAD and
                  triggers data extraction when changes occur.
                </li>
                <li>
                  Transforms Data for EAM: The extracted data is cleaned,
                  validated, and formatted to match EAM’s required structure.
                </li>
                <li>
                  Applies Business Rules: Boomi ensures that only approved
                  purchase orders and verified receipts are processed, reducing
                  errors and improving compliance.
                </li>
                <li>
                  Syncs Data in Real-Time: The transformed data is automatically
                  pushed to EAM, updating purchase orders, inventory levels, and
                  asset records instantly.
                </li>
                <li>
                  Logs & Error Handling: The integration includes monitoring and
                  alerting mechanisms to detect and resolve data mismatches
                  proactively.
                </li>
              </ul>

              <motion.h2
                id="outcomes"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
              >
                Key Benefits of Boomi Integration
              </motion.h2>
              <ul>
                <li>
                  <strong>Real-Time Order Processing – </strong> Eliminates
                  manual delays, ensuring procurement teams always have the
                  latest data.
                </li>
                <li>
                  <strong>Error Reduction -</strong> Automated validation
                  minimizes discrepancies and incorrect order entries.
                </li>
                <li>
                  <strong> Improved Inventory Accuracy -</strong> Designed to
                  support thousands of concurrent requests without performance
                  degradation.
                </li>
                <li>
                  <strong>Scalable & Future-Ready – </strong> The integration is
                  designed to handle future enhancements, such as supplier data
                  synchronization and predictive analytics.
                </li>
              </ul>
              <motion.h2
                id="business-impact"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
              >
                Conclusion: Transforming Enterprise Workflows with Boomi
              </motion.h2>
              <p>
                By leveraging Boomi’s cloud-native integration capabilities, we
                successfully eliminated data silos and created a seamless
                workflow between QAD and EAM. The result? Faster procurement
                cycles, improved accuracy, and increased operational efficiency.
              </p>

              <motion.h2
                id="client-testimonial"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.6, duration: 1, ease: "easeOut" }}
              >
                Client Testimonial
              </motion.h2>
              <p>
                <i>
                  The new eligibility verification system has been a
                  game-changer for us. It has significantly improved processing
                  speeds, enhanced accuracy, and provided a seamless experience
                  for our teams. We now have a future-proof solution that can
                  handle growing demands efficiently." – DHS Georgia
                  Representative
                </i>
              </p>

              {/* <motion.h2
                id="future-roadmap"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.9, duration: 1, ease: "easeOut" }}
              >
                Future Roadmap
              </motion.h2>
              <p>
                With a successful ERP migration, Talking Rain is now positioned
                to:
              </p>
              <ul>
                <li>
                  Enhance AI-driven analytics for smarter decision-making within
                  D365.
                </li>
                <li>
                  Implement advanced automation for financial forecasting and
                  demand planning.
                </li>
                <li>
                  Extend Power BI integration for real-time business insights.
                </li>
              </ul> */}

              {/* <motion.h2
                id="about-talking-rain"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 2.2, duration: 1, ease: "easeOut" }}
              >
                Conclusion
              </motion.h2> */}
              <p>
                By implementing this Dell Boomi-powered eligibility verification
                system, DHS Georgia has transformed how it processes and
                validates government assistance eligibility. The new solution
                not only enhances operational efficiency and accuracy but also
                sets a benchmark for modern, cloud-based integration in
                government services.
              </p>
            </div>
          </motion.section>
        </div>
        {/* Article Section */}

        <div className="exploreblogs">
          {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
          <div className="explore-blogs">
            <h3>Featured Articles</h3>
            <ul className="explore-blogs-list">
              <li>
                <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                  Revolutionizing CI/CD for Azure Logic Apps
                </a>
              </li>
              <li>
                <a href="/blogs/Seamless-migration-d365">
                  Seamless Migration to D365: Transforming ERP for Future
                </a>
              </li>
              <li>
                <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                  Enterprise-Scale Integration Transformation: From iWay to
                  MuleSoft
                </a>
              </li>
              <li>
                <a href="/blogs/segment-cdp-data-marketing">
                  Driving Digital Transformation: Integrating Segment CDP
                </a>
              </li>
              <li>
                <a href="/blogs/ecommerce-marketing-2025">
                  Modernizing EDI Integration with MuleSoft
                </a>
              </li>
              <li>
                <a href="/blogs/Integrating-QAD-and-EAM">
                  Enhancing Eligibility Verification with Dell Boomi
                </a>
              </li>

              <li>
                <a href="/blogs/SAP-to-Epicor">
                  SAP to Epicor Integration Using Azure Logic Apps
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Contact Us */}
        <Footer />
      </div>
    </>
  );
};

export default CaseStudyEight;
