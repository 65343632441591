import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Blogs.css";
import "./WhitePapers.css";
import "./BlogsMobile.css";
import { useNavigate, Link } from "react-router-dom";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import Footer from "../Footer"
const whitepaperData = [
  {
    id: 1,
    num: "Whitepapers",
    category: "Cloud Migrations",
    title: "Migrating from MuleSoft to Azure Logic Apps",
    description: "Migration aimed to redesign the existing architecture to leverage Azure's robust development ecosystem...",
    link: "/White-Papers/Accelerating-Mulesoft-to-Azure-logic-app-Migration-with-Ai-powered-Automation"
  }
];
const blogData = [
  {
    id: 1,
    num: "Success Stories",
    category: "DevOps & CI/CD",
    image: "images/freepik__adjust__58299.png",
    title: "Revolutionizing CI/CD for <br/>Azure Logic Apps",
    description: "Following the successful migration from MuleSoft...",
    tags: ["DevOps & CI/CD", "Data & AI-Driven Strategy"],
  },
  {
    id: 2,
    num: "Success Stories",
    category: "Retail & E-commerce",
    image: "images/ecommerce-marketing-consumer-behavior-2025.png.webp",
    title:
      "<span style='font-size: 1.3rem;'>Seamless Migration to D365:</span> <span style='font-size: 1.2rem;'>Transforming ERP for Future</span>",
    description: "As Talking Rain Beverage Company continued to scale...",
    tags: ["Cloud Migrations"],
  },
  {
    id: 3,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "images/programmatic-ott-advertising.png.webp",
    title:
      "Enterprise-Scale Integration <br/>Transformation: From iWay <br/> to MuleSoft",
    description:
      "Mark Anthony Group, one of the largest beverage <br/>companies in North America...",
    tags: ["Integration & Automation", "Finance & Banking"],
  },
  {
    id: 4,
    num: "Success Stories",
    category: "Retail & E-commerce",
    image:
      "images/close-up-laptop-with-creative-big-data-transfer-digital-transformation-hologram-concrete-wall-background-3d-rendering.jpg  ",
    title: "Driving Digital Transformation: Integrating Segment CDP ",
    description:
      "Mark Anthony Group, one of North America’s largest beverage companies",
    tags: ["Supply Chain & Manufacturing"],
  },
  // { id: 5, num: "Blogs", category: "Integration & Automation", image: "images/top-ten-blog-posts.png.webp", title: "Top 10 Roundup: Best Reads from Techint Labs This Year", description: "A look back at the year's top content from Techint Labs...", tags: ["Integration & Automation", "Finance & Banking"] },
  {
    id: 6,
    num: "Success Stories",
    category: "Retail & E-commerce",
    image: "images/calendar-displaying-four-squares.png.webp",
    title: "Modernizing EDI Integration with MuleSoft",
    description:
      "Mark Anthony Group one of North America's largest beverage companies...",
    tags: ["Retail & E-commerce", "DevOps & CI/CD"],
  },
  {
    id: 7,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "images/megaphone-computer-screen.png.webp",
    title: " Enhancing Eligibility Verification with Dell Boomi",
    description:
      "DHS Georgia needed a modern, automated <br/>eligibility verification...",
    tags: ["Government & Public Services"],
  },
  {
    id: 8,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "images/megaphone-computer-screen.png.webp",
    title: "Seamless Order Management with Boomi",
    description:
      "In today’s fast-paced business environment, integrating enterprise systems is no longer a<br/> luxury—it’s...",
    tags: [" Integration & Automation", "Data & AI-Driven Strategy"],
  },
  {
    id: 9,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "images/megaphone-computer-screen.png.webp",
    title: "SAP to Epicor Integration Using Azure Logic Apps",
    description:
      "BOPPY, a leading manufacturing and supply chain solutions provider, faced integration challenges between SAP ERP...",
    tags: ["Data & AI-Driven Strategy"],
  },

];
// Combine blogData and whitepaperData for count only
const combinedData = [...blogData, ...whitepaperData];

const categoryCounts = combinedData.reduce((acc, item) => {
  if (item.num === "Success Stories") {
    acc["Success Stories"] = (acc["Success Stories"] || 0) + 1;
  } else if (item.num === "Whitepapers") {
    acc["Whitepapers"] = (acc["Whitepapers"] || 0) + 1;
  }
  return acc;
}, {});

console.log(categoryCounts);  // Check the count in console


const categories = [
  "All",
  "Cloud Migrations",
  "Enterprise Modernization",
  "Integration & Automation",
  "DevOps & CI/CD",
  "Data & AI-Driven Strategy",
  "ERP & Digital Transformation",
  "Retail & E-commerce",
  "Finance & Banking",
  "Supply Chain & Manufacturing",
  "Government & Public Services",
];

const WhitePapers = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortOrder, setSortOrder] = useState("");

  const filteredBlogs =
    selectedCategory === "All"
      ? blogData
      : blogData.filter(
          (blog) =>
            blog.category === selectedCategory ||
            blog.tags.includes(selectedCategory)
        );

  return (
    <>
      <div className="blogs-container">
        <Header />
        <div className="fordistance">
      {Object.entries(categoryCounts).map(([category, count], index) => (
        <div
          key={index}
          className={`blog-item-whitepaper ${category.toLowerCase()}`}
          onClick={() => {
            if (category === "Whitepapers") navigate("/White-Papers");
            if (category === "Success Stories") navigate("/blogs");
          }}
          style={{ cursor: category === "Blogs" ? "default" : "pointer" }} // Make only Whitepapers & Success Stories clickable
        >
          <h1
            className={
              category === "Whitepapers"
                ? "bold-text" // Bold for Whitepapers
                : category === "Success Stories"
                ? "regular-text" // Regular for Success Stories
                : "bold-text" // Bold for others by default
            }
            style={category === "Blogs" ? { color: "black" } : {}}
          >
            {category}
            <span className="count">{count}</span>
          </h1>
        </div>
      ))}

      <div className="filter-section">
        {/* Filter Buttons */}
        <div className="tabs-wrapper">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`filter-btn ${selectedCategory === category ? "active" : ""}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Sort Dropdown */}
        <div className="sort-section">
          <select
            className="sort-select"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="" disabled>
              Sort
            </option>
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>
      </div>
    </div>
        {(selectedCategory === "Cloud Migrations" ||selectedCategory === "DevOps & CI/CD" ||
          selectedCategory === "All") && (
          <div className="whitepaper-content">
            {/* Categories */}
            <div className="blog-categories">
              <div className="blog-category">Cloud Migrations</div>
              <div className="blog-category">DevOps & CI/CD</div>
            </div>

            {/* Blog Title */}
            <div className="blog-name">
            Migrating from MuleSoft to Azure Logic Apps
            </div>

            {/* Blog Description */}
            <p className="blog-text">
            Migration aimed to redesign the existing architecture to leverage Azure's 
            robust development ecosystem, enhance scalability and streamline integration processes...
            </p>

            {/* Read More Link */}
            <Link
              to="/White-Papers/Accelerating-Mulesoft-to-Azure-logic-app-Migration-with-Ai-powered-Automation"
              className="blog-link"
            >
              Read the story
              <span className="arrow-icon">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0H10.3448V9.17358L1.24219 0.0709888L0.0717958 1.24138L9.17534 10.3448H0V12H12V0Z"
                    fill="#222325"
                  ></path>
                </svg>
              </span>
            </Link>
          </div>
        )}

<section className="newblogs">
  {filteredBlogs
    .filter((blog) => blog.num === "Whitepapers") // Filter for Whitepapers only
    .map((blog) => (
      <>
        <div key={blog.id} className={`blogimg${blog.id}`}>
          <img src={blog.image} alt={blog.title} />
        </div>
        <div className={`blogtxt${blog.id}`}>
          <div className="tags">
            {blog.tags.map((tag, i) => (
              <span key={i}>{tag}</span>
            ))}
          </div>
          <h2 dangerouslySetInnerHTML={{ __html: blog.title }}></h2>
          <p dangerouslySetInnerHTML={{ __html: blog.description }}></p>
          <Link
            to={
              blog.id === 1
                ? "/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps"
                : blog.id === 2
                ? "/blogs/Seamless-migration-d365"
                : blog.id === 3
                ? "/blogs/Transformation-From-iWay-to-mulesoft"
                : blog.id === 4
                ? "/blogs/segment-cdp-data-marketing"
                : blog.id === 6
                ? "/blogs/ecommerce-marketing-2025"
                : blog.id === 7
                ? "/blogs/top-10-best-reads"
                : blog.id === 8
                ? "/blogs/Integrating-QAD-and-EAM"
                : blog.id === 9
                ? "/blogs/SAP-to-Epicor"
                : "/"
            }
          >
            <button>Read the story →</button>
          </Link>
        </div>
      </>
    ))}
</section>


        {/* Contact Us */}
<Footer/>
      </div>
    </>
  );
};

export default WhitePapers;
