import React from "react";
import { motion } from "framer-motion";
import "./Footer.css";

const Footer = () => {
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <section
        style={{
          background: "#181818",
          color: "white",
          textAlign: "center",
          padding: isMobile ? "1rem 1rem" : "3rem 1.5rem",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
     
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: isMobile ? "0.5rem" : "5rem",
            flexWrap: "wrap",
            marginBottom: isMobile ? "1.5rem" : "3.6rem",
            textAlign: "left",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
     
          }}
          className="footer-container"
        >
          <div>
            <p >
              <img
                src="/images/CYNTRALABS White Logo (1).png"
                alt="CyntraLabs Logo"
                width={isMobile ? "97%" : "100%"}
              />
            </p>
          </div>

          <div
            style={{
              display: isMobile ? "grid" : "flex",
              gridTemplateColumns: isMobile ? "1fr 1fr" : "none",
              gap: isMobile ? "1.5rem" : "4rem",
              paddingLeft:isMobile ? "2.45rem":"",
              fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                marginBottom: isMobile ? "0.5rem" : ""
            }}
          >
            {[
              {
                title: "What we offer",
                links: [
                  { label: "Integration solutions", path: "/Migrating-ERPSolutions" },
                  { label: "Enterprise integration solutions", path: "/ERP-Solutions" },
                  { label: "Cloud native experience", path: "/Devops-Services" },
                  { label: "Application Support", path: "/AMS-Services" },
                ],
              },
              {
                title: "Our Partners",
                links: [
                  { label: "Service Now", path: "/Service-Now" },
                  { label: "Google", path: "/Google-Cloud" },
                  { label: "Salesforce", path: "/Salesforce" },
                  { label: "SAP Labs", path: "/Sap-Labs" },
                ],
              },
              {
                title: "Knowledge",
                links: [
                  { label: "Success stories", path: "/Blogs" },
                  { label: "Whitepaper", path: "/White-Papers" },
                ],
              },
            
              {
                title: "About",
                links: [
                  { label: "Contact Us", path: "/Get-In-Touch" },
                  { label: "About Us", path: "/AboutUs" },
                ],
              },
            ].map((section, index) => (
              <div key={index} style={{ width: isMobile ? "100%" : "auto" }}>
                <p
                  style={{
                    fontSize: isMobile ? "1rem" : "1.2rem",
                    fontWeight: "400",
                    color: "#FFFFFF",
                    marginBottom: "0.5rem",
                    marginTop: isMobile ? "0rem" : "1.5rem",
                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
     
                  }}
                >
                  {section.title}
                </p>
                {section.links.map((item, linkIndex) => (
                  <a
                    key={linkIndex}
                    href={item.path}
                    style={{
                      fontSize: isMobile ? "0.8rem" : "0.9rem",
                      color: "#A0A0A0",
                      marginTop: isMobile ? "0.5rem" :"1rem",
                      display: "block",
                      textDecoration: "none",
                      cursor: "pointer",
                      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
     
                    }}
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            ))}
          </div>
        </div>

        <motion.footer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, ease: "easeOut" }}
          style={{
            fontSize: isMobile ? "0.8rem" : "0.9rem",
            color: "#A0A0A0",
            borderTop: "1px solid #333",
            paddingTop: isMobile ? "0.8rem" : "2rem",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            // marginTop:"2rem"
          }}
        >
          © 2025 CyntraLabs. All rights reserved.
        </motion.footer>
      </section>
    </>
  );
};

export default Footer;
