import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";
import Footer from "../Footer";

const CaseStudyFour = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );
  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation();

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <div className="case-study-page">
      <Header />
      <motion.section
        className="banner-section"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div
          className="banner-bg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "url('/images/iway.png') center/cover no-repeat",
            filter: "blur(4px)",
            zIndex: -1,
          }}
        />
        <div className="banner-content">
          <a href="/blogs" className="back-to-list">
            Back to list
          </a>
          <motion.h1
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            Enterprise-Scale Migration: From iWay to MuleSoft
          </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>Estimated reading time: {readingMinutes} minutes</span>
            <button
              className="speaker"
              onClick={toggleSpeech}
              style={{ backgroundColor: "#F2F2F2", color:"black" }}
            >
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{
                marginTop: "1rem",
                backgroundColor: "#F2F2F2",
                border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert 
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </motion.section>

      <div className="case-study-container">
        <aside className="case-study-sidebar">
          <ul>
            <li
              className={activeSection === "business-needs" ? "active" : ""}
              onClick={() => handleClick("business-needs")}
            >
              Business Needs
            </li>
            <li
              className={
                activeSection === "overcoming-challenges" ? "active" : ""
              }
              onClick={() => handleClick("overcoming-challenges")}
            >
              Overcoming Unprecedented Migration Challenges
            </li>
            <li
              className={activeSection === "solution" ? "active" : ""}
              onClick={() => handleClick("solution")}
            >
              Solution: Enterprise-Grade MuleSoft Migration Strategy
            </li>
            <li
              className={
                activeSection === "breakthrough-outcomes" ? "active" : ""
              }
              onClick={() => handleClick("breakthrough-outcomes")}
            >
              Breakthrough Outcomes: Business Expansion & Revenue Surge
            </li>
            <li
              className={activeSection === "client-testimonial" ? "active" : ""}
              onClick={() => handleClick("client-testimonial")}
            >
              Client Testimonial
            </li>
            <li
              className={activeSection === "future-roadmap" ? "active" : ""}
              onClick={() => handleClick("future-roadmap")}
            >
              Future Roadmap
            </li>
            <li
              className={activeSection === "about-mark-anthony" ? "active" : ""}
              onClick={() => handleClick("about-mark-anthony")}
            >
              About Mark Anthony Group
            </li>
          </ul>
        </aside>

        <motion.section
          className="article-section"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "easeInOut" }}
        >
          <div className="container">
            <motion.h2
              id="business-needs"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.8, duration: 1, ease: "easeOut" }}
            >
              Business Needs
            </motion.h2>
            <p>
              Mark Anthony Group, one of the largest beverage companies in North
              America, was relying on an aging iWay integration tool for
              handling data flows across its supply chain, sales, finance, and
              customer engagement platforms.
            </p>
            <p>The key objectives were:</p>
            <ul>
              <li>Enable real-time data exchange across global markets.</li>
              <li>Handle complex integrations beyond simple ETL processing.</li>
              <li>
                Support rapid scalability as they expanded into Europe and South
                America.
              </li>
              <li>
                Reduce maintenance overhead by standardizing integrations.
              </li>
            </ul>
            <motion.h2
              id="solution"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1, duration: 1, ease: "easeOut" }}
            >
              Overcoming Unprecedented Migration Challenges
            </motion.h2>
            <p>
              {" "}
              CyntraLabs designed and implemented a phased, high-speed migration
              strategy, ensuring that Mark Anthony Group not only modernized its
              integration platform but also gained a competitive edge in global
              expansion.
            </p>
            <br></br>

            <h3 style={{ marginBottom: "1rem" }}>
              Key Steps in the Migration Process
            </h3>
            <ol style={{ paddingLeft: "1.5rem", marginBottom: "2rem" }}>
              <li style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>Comprehensive Integration Audit &amp; Strategy</h3>
                <ul style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Mapped out 300+ iWay interfaces and their dependencies.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Identified critical integration bottlenecks affecting
                    performance.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Designed a phased migration roadmap to minimize risk.
                  </li>
                </ul>
              </li>

              <li style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>Re-Architecting Integrations with MuleSoft</h3>
                <ul style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Converted legacy batch-based ETL jobs into event-driven
                    APIs.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Designed microservices-based integration patterns for
                    scalability &amp; reusability.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Optimized API communication between ERP, CRM, and supply
                    chain platforms.
                  </li>
                </ul>
              </li>

              <li style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>
                  Parallel Deployment &amp; Performance Optimization
                </h3>
                <ul style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Deployed hybrid cloud architecture, enabling MuleSoft to run
                    on-prem &amp; in the cloud.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Tuned data pipelines for high-throughput, real-time
                    processing.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Leveraged Anypoint Monitoring to track API performance &amp;
                    error rates.
                  </li>
                </ul>
              </li>

              <li style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>
                  Accelerated Phased Rollout with Automated Testing
                </h3>
                <ul style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Built a CI/CD pipeline to continuously test and deploy
                    integrations.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Created automated rollback mechanisms to eliminate migration
                    risks.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                    Enabled real-time API monitoring &amp; self-healing
                    mechanisms.
                  </li>
                </ul>
              </li>
            </ol>
            <motion.h2
              id="outcomes"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
            >
              Solution: Enterprise-Grade MuleSoft Migration Strategy
            </motion.h2>
            <p>
              CyntraLabs designed and implemented a phased, high-speed migration
              strategy, ensuring that Mark Anthony Group not only modernized its
              integration platform but also gained a competitive edge in global
              expansion.
            </p>
            {/* <h3>Key Steps in the Migration Process</h3>
            <ol>
              <li>
                <strong>Comprehensive Integration Audit & Strategy</strong>
                <ul>
                  <li>
                    Mapped out 300+ iWay interfaces and their dependencies.
                  </li>
                  <li>
                    Identified critical integration bottlenecks affecting
                    performance.
                  </li>
                  <li>Designed a phased migration roadmap to minimize risk.</li>
                </ul>
              </li>
              <li>
                <strong>Re-Architecting Integrations with MuleSoft</strong>
                <ul>
                  <li>
                    Converted legacy batch-based ETL jobs into event-driven
                    APIs.
                  </li>
                  <li>
                    Designed microservices-based integration patterns for
                    scalability & reusability.
                  </li>
                  <li>
                    Optimized API communication between ERP, CRM, and supply
                    chain platforms.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Parallel Deployment & Performance Optimization</strong>
                <ul>
                  <li>
                    Deployed hybrid cloud architecture, enabling MuleSoft to run
                    on-prem & in the cloud.
                  </li>
                  <li>
                    Tuned data pipelines for high-throughput, real-time
                    processing.
                  </li>
                  <li>
                    Leveraged Anypoint Monitoring to track API performance &
                    error rates.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  Accelerated Phased Rollout with Automated Testing
                </strong>
                <ul>
                  <li>
                    Built a CI/CD pipeline to continuously test and deploy
                    integrations.
                  </li>
                  <li>
                    Created automated rollback mechanisms to eliminate migration
                    risks.
                  </li>
                  <li>
                    Enabled real-time API monitoring & self-healing mechanisms.
                  </li>
                </ul>
              </li>
            </ol> */}
            <motion.h2
              id="breakthrough-outcomes"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.8, duration: 1, ease: "easeOut" }}
            >
              Breakthrough Outcomes: Business Expansion & Revenue Surge
            </motion.h2>
            <ul>
              <li>
                <strong>Record-Breaking Migration Speed:</strong> 300+
                integrations migrated in just 20 weeks (industry standard: 12+
                months).
              </li>
              <li>
                <strong>120% Revenue Growth:</strong> Enhanced real-time market
                insights & operational efficiency directly contributed to
                massive revenue gains.
              </li>
              <li>
                <strong>Global Market Expansion:</strong> Mark Anthony Group
                successfully expanded into Europe & South America, leveraging
                its new scalable, API-driven integration framework.
              </li>
              <li>
                <strong>Operational Excellence:</strong> Improved supply chain
                efficiency, reducing order processing time by 35%.
              </li>
              <li>
                <strong> 99.99% Uptime:</strong> MuleSoft’s resilient
                architecture ensured near-zero downtime during and after
                migration.
              </li>
            </ul>
            <motion.h2
              id="client-testimonial"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 2, duration: 1, ease: "easeOut" }}
            >
              Client Testimonial
            </motion.h2>
            <p>
              <i>
                "CyntraLabs delivered a transformation that seemed impossible at
                this scale. Their expertise in MuleSoft, API-first design, and
                rapid migration approach has been a game-changer for us. We are
                now operating at a global scale, with seamless, real-time data
                exchange that drives our business growth."
              </i>{" "}
            </p>
            <motion.h2
              id="future-roadmap"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 2.2, duration: 1, ease: "easeOut" }}
            >
              Future Roadmap
            </motion.h2>
            <ul>
              <li>
                Implement AI-driven supply chain analytics for predictive demand
                forecasting
              </li>
              <li>
                Enhance API-driven partner integrations, allowing frictionless
                expansion into new markets.
              </li>
              <li>
                Automate global compliance reporting using real-time financial &
                operational data flows.
              </li>
            </ul>
            <motion.h2
              id="about-mark-anthony"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 2.4, duration: 1, ease: "easeOut" }}
            >
              About Mark Anthony Group
            </motion.h2>
            <p>
              Mark Anthony Group is a leading beverage company in North America,
              best known for iconic brands such as White Claw Hard Seltzer and
              Mike’s Hard Lemonade.
            </p>
          </div>
        </motion.section>
      </div>

      {/* Article Section */}

      <div className="exploreblogs">
        {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
        <div className="explore-blogs">
          <h3>Featured Articles</h3>
          <ul className="explore-blogs-list">
            <li>
              <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                Revolutionizing CI/CD for Azure Logic Apps
              </a>
            </li>
            <li>
              <a href="/blogs/Seamless-migration-d365">
                Seamless Migration to D365: Transforming ERP for Future
              </a>
            </li>
            <li>
              <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                Enterprise-Scale Integration Transformation: From iWay to
                MuleSoft
              </a>
            </li>
            <li>
              <a href="/blogs/segment-cdp-data-marketing">
                Driving Digital Transformation: Integrating Segment CDP
              </a>
            </li>

            <li>
              <a href="/blogs/Integrating-QAD-and-EAM">
                Enhancing Eligibility Verification with Dell Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/top-10-best-reads">
                Seamless Order Management with Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/SAP-to-Epicor">
                SAP to Epicor Integration Using Azure Logic Apps
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Contact Us */}

 <Footer/>
    </div>
  );
};

export default CaseStudyFour;
