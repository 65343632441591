import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./CaseStudies.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import { Volume2, VolumeX } from "lucide-react";
import Footer from "../Footer";

const CaseStudyTen = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );
  //speech
  const [readingMinutes, setReadingMinutes] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;
  let utterance;

  useEffect(() => {
    // Calculate reading minutes based on 200 words per minute
    const articleContent =
      document.querySelector(".article-section")?.innerText || "";
    const wordCount = articleContent.split(/\s+/).length;
    setReadingMinutes(Math.ceil(wordCount / 200));
  }, []);
  const toggleSpeech = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const articleText =
        document.querySelector(".article-section")?.innerText || "";
      utterance = new SpeechSynthesisUtterance(articleText);
      utterance.lang = "en-US";
      utterance.rate = 1; // Normal speed
      utterance.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };
  //speech

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSection, setActiveSection] = useState("");
  const controls = useAnimation();

  useEffect(() => {
    const sidebar = document.querySelector(".case-study-sidebar");
    const container = document.querySelector(".case-study-container");
    const sections = document.querySelectorAll(".article-section h2");

    if (!sidebar || !container || sections.length === 0) return;

    const topOffset = 80; // Adjust based on your header height
    const sidebarHeight = sidebar.offsetHeight;
    const containerTop = container.offsetTop;
    const containerBottom = containerTop + container.offsetHeight;
    const viewportHeight = window.innerHeight;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Fix Sidebar Position
      if (
        scrollY >= containerTop - topOffset &&
        scrollY + sidebarHeight < containerBottom
      ) {
        sidebar.style.position = "fixed";
        sidebar.style.top = `${topOffset}px`;
      } else if (scrollY < containerTop - topOffset) {
        sidebar.style.position = "absolute";
        sidebar.style.top = "0";
      } else {
        sidebar.style.position = "absolute";
        sidebar.style.top = `${container.offsetHeight - sidebarHeight}px`;
      }

      // Highlight Active Section
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - topOffset - 10;
        if (scrollY >= sectionTop) {
          currentSection = section.id;
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <div className="case-study-page">
      <Header />
      <motion.section
        className="banner-section"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div
          className="banner-bg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "url('/images/individuality-concept-paper-planes.jpg') center/cover no-repeat",
            filter: "blur(4px)",
            zIndex: -1,
          }}
        />
        <div className="banner-content">
          <a href="/blogs" className="back-to-list">
            Back to list
          </a>
          <motion.h1
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            style={{font:"f2f2f2"}}
          >
            Seamless Migration from Dell Boomi to SAP BTP
          </motion.h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span>Estimated reading time: {readingMinutes} minutes</span>
            <button
              className="speaker"
              onClick={toggleSpeech}
              style={{ backgroundColor: "#F2F2F2", color:"black" }}
            >
              {isSpeaking ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>

            <button
              className="button-Schedule"
              style={{
                marginTop: "1rem",
                backgroundColor: "#F2F2F2",
                border: "2px solid #F2F2F2",
              }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Talk to our expert 
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </motion.section>

      <div className="case-study-container">
        <aside className="case-study-sidebar">
          <ul>
            <li
              className={activeSection === "business-needs" ? "active" : ""}
              onClick={() => handleClick("business-needs")}
            >
              Business Needs
            </li>
            <li
              className={
                activeSection === "overcoming-challenges" ? "active" : ""
              }
              onClick={() => handleClick("overcoming-challenges")}
            >
             Overcoming Complex Migration Challenges
            </li>
            <li
              className={activeSection === "solution" ? "active" : ""}
              onClick={() => handleClick("solution")}
            >
              Solution: Enterprise-Grade SAP BTP Migration Strategy
            </li>
            <li
              className={
                activeSection === "breakthrough-outcomes" ? "active" : ""
              }
              onClick={() => handleClick("breakthrough-outcomes")}
            >
              Breakthrough Outcomes: Business Transformation & Efficiency Gains
            </li>
            <li
              className={activeSection === "client-testimonial" ? "active" : ""}
              onClick={() => handleClick("client-testimonial")}
            >
              Client Testimonial
            </li>
            <li
              className={activeSection === "future-roadmap" ? "active" : ""}
              onClick={() => handleClick("future-roadmap")}
            >
              Future Roadmap
            </li>
            <li
              className={activeSection === "about-mark-anthony" ? "active" : ""}
              onClick={() => handleClick("about-mark-anthony")}
            >
              About Mark Anthony Group
            </li>
          </ul>
        </aside>

        <motion.section
          className="article-section"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, ease: "easeInOut" }}
        >
          <div className="container">
            <motion.h2
              id="business-needs"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.8, duration: 1, ease: "easeOut" }}
            >
              Business Needs
            </motion.h2>
            <p>
            A leading global enterprise leveraging SAP SuccessFactors faced significant challenges with its existing Dell Boomi integration platform. The system struggled with real-time data synchronization, high operational costs, and limited scalability as the company expanded its HR operations globally.
            </p>
            <p>The key objectives were:</p>
            <ul>
              <li>Enable seamless, real-time HR data synchronization across global entities..</li>
              <li>Enhance integration scalability to support growing business demands.</li>
              <li>
              Reduce operational costs by consolidating integration management within SAP BTP.
              </li>
              <li>
              Improve system resilience and minimize downtime during HRIS updates.
              </li>
            </ul>
            <motion.h2
              id="solution"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1, duration: 1, ease: "easeOut" }}
            >
              Overcoming Complex Migration Challenges
            </motion.h2>
            <p>
              {" "}
       CyntraLabs designed and executed a high-speed, risk-mitigated migration strategy, ensuring the client not only modernized its HR integrations but also gained operational efficiency and a future-proof architecture.
            </p>
            <br></br>

            <h3 style={{ marginBottom: "1rem" }}>
              Key Steps in the Migration Process
            </h3>
            <ol style={{ paddingLeft: "1.5rem", marginBottom: "2rem" }}>
              <li style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>Comprehensive Integration Assessment &amp; Strategy</h3>
                <ul style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Audited 250+ Dell Boomi interfaces spanning HR, payroll, and compliance workflows.

                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Identified key performance bottlenecks and areas for API-first optimization.
                 
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Designed a structured, phased migration roadmap aligned with business continuity needs.
                  </li>
                </ul>
              </li>

              <li style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>Re-Architecting Integrations with SAP BTP</h3>
                <ul style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Migrated legacy batch integrations to real-time event-driven architecture.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Designed scalable, reusable APIs using SAP Integration Suite.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Optimized end-to-end data flows between SAP SuccessFactors, payroll, and third-party HR systems.
                  </li>
                </ul>
              </li>

              <li style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>
                Hybrid Deployment &amp; Performance Optimization
                </h3>
                <ul style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Implemented a hybrid cloud architecture, enabling SAP BTP to integrate with both on-prem and cloud-based HR solutions.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Optimized API communication to support high-volume employee transactions with minimal latency.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Leveraged SAP API Management for enhanced security, monitoring, and governance.
                  </li>
                </ul>
              </li>

              <li style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>
                Accelerated Phased Rollout with Automated Testing
                </h3>
                <ul style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Built an automated CI/CD pipeline for testing and deploying integrations seamlessly.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Implemented automated rollback mechanisms to eliminate migration risks.
                  </li>
                  <li style={{ marginBottom: "0.5rem", lineHeight: "1.5" }}>
                  Established real-time API monitoring and self-healing capabilities to ensure reliability.
                  </li>
                </ul>
              </li>
            </ol>
            <motion.h2
              id="outcomes"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.3, duration: 1, ease: "easeOut" }}
            >
              Solution: Enterprise-Grade SAP BTP Migration Strategy
            </motion.h2>
            <p>
            CyntraLabs designed and executed a high-performance migration strategy, enabling the client to modernize HR integrations while achieving business agility, cost savings, and operational excellence.
            </p>

            
            <motion.h2
              id="breakthrough-outcomes"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1.8, duration: 1, ease: "easeOut" }}
            >
              Breakthrough Outcomes: Business Transformation & Efficiency Gains
            </motion.h2>
            <ul>
              <li>
                <strong>Record-Breaking Migration Speed:</strong> 250+ integrations successfully migrated in just 18 weeks (industry standard: 10+ months).
              </li>
              <li>
                <strong>30% Cost Reduction:</strong> Consolidated integrations within SAP BTP significantly reduced operational expenses.
              </li>
              <li>
                <strong>Global Market Expansion:</strong> Mark Anthony Group
                successfully expanded into Europe & South America, leveraging
                its new scalable, API-driven integration framework.
              </li>
              <li>
                <strong>99.98% System Uptime:</strong> Enhanced resilience and automated failover mechanisms ensured uninterrupted HR operations.
              </li>
              <li>
                <strong> 40% Faster HR Data Processing:</strong> Real-time integrations reduced payroll and employee data processing times, improving efficiency.
              </li>
              <li>
                <strong>Seamless Global Expansion:</strong> Scalable API-driven architecture facilitated effortless onboarding of new regional HR systems.
              </li>
            </ul>
            <motion.h2
              id="client-testimonial"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 2, duration: 1, ease: "easeOut" }}
            >
              Client Testimonial
            </motion.h2>
            <p>
              <i>
                "CyntraLabs delivered a flawless migration from Dell Boomi to SAP BTP, unlocking new levels of efficiency and scalability for our global HR operations. Their deep expertise in API-first design and rapid execution approach have been instrumental in transforming our HR technology landscape.."
              </i>{" "}
            </p>
            <motion.h2
              id="future-roadmap"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 2.2, duration: 1, ease: "easeOut" }}
            >
              Future Roadmap
            </motion.h2>
            <ul>
              <li>
              Implement AI-driven analytics for predictive workforce planning.
              </li>
              <li>
              Enhance API-driven partner integrations for seamless third-party HR services.
              </li>
              <li>
              Automate compliance reporting using real-time payroll and employee data insights.
              </li>
            </ul>
            <motion.h2
              id="about-mark-anthony"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 2.4, duration: 1, ease: "easeOut" }}
            >
              About the Client
            </motion.h2>
            <p>
            A Fortune 500 enterprise with a global workforce of 50,000+, this company is a leader in leveraging SAP SuccessFactors for its HR transformation journey, ensuring operational excellence and employee satisfaction across multiple geographies.
            </p>
          </div>
        </motion.section>
      </div>

      {/* Article Section */}

      <div className="exploreblogs">
        {/* Featured Articles Section (Styled like the "Explore More Blogs") */}
        <div className="explore-blogs">
          <h3>Featured Articles</h3>
          <ul className="explore-blogs-list">
            <li>
              <a href="/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps">
                Revolutionizing CI/CD for Azure Logic Apps
              </a>
            </li>
            <li>
              <a href="/blogs/Seamless-migration-d365">
                Seamless Migration to D365: Transforming ERP for Future
              </a>
            </li>
            <li>
              <a href="/blogs/Transformation-From-iWay-to-mulesoft">
                Enterprise-Scale Integration Transformation: From iWay to
                MuleSoft
              </a>
            </li>
            <li>
              <a href="/blogs/segment-cdp-data-marketing">
                Driving Digital Transformation: Integrating Segment CDP
              </a>
            </li>

            <li>
              <a href="/blogs/Integrating-QAD-and-EAM">
                Enhancing Eligibility Verification with Dell Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/top-10-best-reads">
                Seamless Order Management with Boomi
              </a>
            </li>
            <li>
              <a href="/blogs/SAP-to-Epicor">
                SAP to Epicor Integration Using Azure Logic Apps
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Contact Us */}

  <Footer/>
    </div>
  );
};

export default CaseStudyTen;
