import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./SystemIntegration.css";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";

const Migration = () => {
  
     const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header />
      {/* System Integration Section with Background */}
      <motion.div
        className="system-integration"
        initial={{ opacity: 1 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
        style={{
          background:
            "url('/images/cloud-computing-is-shown-hand-cloud-technology-storage-internet-service-concept-technology.jpg') no-repeat center center / cover",
        }}
      >
        <div className="header h1">
          <h1>Integration solutions</h1>
        </div>
      </motion.div>

      {/* Bridge Diverse Processes Swiftly Section */}
      <div className="bridge-container">
        <motion.div
          className="bridge-left"
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        >
          <h1>
            We seamlessly migrate and rearchitect enterprise solutions to boost
            performance, cut costs, and enhance scalability—all with minimal
            downtime!
          </h1>
          <button className="button-Schedule" onClick={() => navigate ('/Get-In-Touch')}>
          Talk to our expert 
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animated-arrow"
  >
    <path
      d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
      stroke="#042E50"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
</button>

        </motion.div>

        <div
          className="vertical-line"
          style={{
            borderLeft: "0.0625rem solid #797979",
            height: "auto",
            minHeight: "120vh",
            marginRight: "70px",
            marginTop: "20px",
            marginLeft: "50px",
          }}
        ></div>

        <motion.div
          className="bridge-right"
          initial={{ x: 50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: false }}
        >
          {[
            {
              title: "Legacy System Modernization",
              text: "We re-engineer outdated systems to improve performance, security, and scalability. By leveraging cloud-native architectures, microservices, and API-driven frameworks, we help businesses eliminate inefficiencies and enhance agility.",
            },
            {
              title: "Seamless Data & Application Migration",
              text: "Migrating enterprise data and applications can be complex—we simplify the process with structured migration frameworks, ensuring zero downtime, data integrity, and seamless system transitions. Whether it’s cloud migration, database upgrades, or platform re-hosting, we ensure a frictionless experience.",
            },
            {
              title: "Platform Transformation",
              text: "Stuck in legacy silos? We're your bridge to the future. We seamlessly integrate new solutions with your existing systems, including Boomi, SAP, and Azure Logic Apps, unlocking their true potential and driving operational efficiency. No platform is too complex, no challenge too big.",
            },
            {
              title: "Cloud-First & Scalable Architecture Design",
              text: "Re-architecting isn't just about moving to the cloud—it’s about optimizing for scalability, resilience, and automation. Our experts design cloud-native, serverless, and containerized solutions that enhance efficiency and reduce operational costs.",
            },
            {
              title: "Security & Compliance Optimization",
              text: "Migrating to a new system comes with security challenges—we ensure compliance with industry regulations while enhancing cybersecurity measures, identity management, and risk mitigation strategies to protect your enterprise data.",
            },
          ].map((item, index) => (
            <motion.div
              className="content-box"
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: false }}
            >
              <h2 className="blue-heading">{item.title}</h2>
              <p>{item.text}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>

      <motion.section
        className="focus-container"
        initial={{ scale: 0.8, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="focus-content">
          <div className="focus-img">
            <img src="/images/CYNTRALABS White Logo.png" alt="Focus Logo" />
          </div>
          <h2 className="focus-title">
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"pink"}}>With our proven </span>track record
                of 50+ successful migrations,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#e8c46b"}} >we turn complexity </span> into
                opportunity,
              </div>
            </div>
            <div className="fade-overflow">
              <div className="fade-el">
                <span className="focus-title" style={{color:"#71b788"}}>keeping your business</span> ahead
                of the curve.
              </div>
            </div>
          </h2>
        </div>
      </motion.section>

      <motion.div
        className="technologies-container"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: false }}
      >
        <div className="tech-container">
          <div className="tech-left">
            <h1>
              Technologies We Leverage <br />
            </h1>
            <button className="button-about" onClick={() => navigate ('/AboutUs')}>
                      Get to know us &nbsp;&nbsp;&nbsp;
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated-arrow"
              >
                <path
                  d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                  stroke="#042E50"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
          <div className="technologies-right">
            {[
              "/images/MuleSoft-Logo-removebg-preview 1.jpg",
              "/images/azure.png",
              "/images/sap.png",
              "/images/boomilogo.png",
              "/images/workday-logo.png",
              "/images/Workato-Logo.png",
              "/images/salesforce-logo.png",
              "/images/Dynamics.png",
            ].map((src, index) => (
              <motion.img
                key={index}
                src={src}
                alt="Technology Logo"
                className="tech-logo"
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: false }}
              />
            ))}
          </div>
        </div>
      </motion.div>

   {/* Contact Us */}
<Footer/>
    </>
  );
};

export default Migration;
