import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Blogs.css";
import "./BlogsMobile.css";
import { useNavigate, Link } from "react-router-dom";
import { Linkedin, Instagram, Youtube } from "lucide-react";
import Header from "../Header";
import Footer from "../Footer"

const blogData = [
  {
    id: 1,
    num: "Success Stories",
    category: "DevOps & CI/CD",
    image: "images/image (7).jpg",
    title: "Revolutionizing CI/CD for Azure Logic Apps",
    description: "Following the successful migration from MuleSoft...",
    tags: ["DevOps & CI/CD", "Data & AI-Driven Strategy"],
  },
  {
    id: 2,
    num: "Success Stories",
    category: "Retail & E-commerce",
    image: "images/AdobeStock_72421384.jpeg",
    title:
      "<span style='font-size: 1.3rem;'>Seamless Migration to D365:</span> <span style='font-size: 1.2rem;'>Transforming ERP for Future</span>",
    description: "As Talking Rain Beverage Company continued to scale...",
    tags: ["Cloud Migrations"],
  },
  {
    id: 3,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "images/iway.png",
    title:
      "Enterprise-Scale Integration Transformation",
    description:
      "Mark Anthony Group, one of the largest beverage companies in North America...",
    tags: ["Integration & Automation", "Finance & Banking"],
  },
  {
    id: 4,
    num: "Success Stories",
    category: "Retail & E-commerce",
    image:
      "images/AdobeStock_1158752939.jpeg  ",
    title: "Driving Digital Transformation: Integrating Segment CDP ",
    description:
      "Mark Anthony Group, one of North America’s largest beverage companies",
    tags: ["Supply Chain & Manufacturing"],
  },
  // { id: 5, num: "Blogs", category: "Integration & Automation", image: "images/top-ten-blog-posts.png.webp", title: "Top 10 Roundup: Best Reads from Techint Labs This Year", description: "A look back at the year's top content from Techint Labs...", tags: ["Integration & Automation", "Finance & Banking"] },
  {
    id: 6,
    num: "Success Stories",
    category: "Retail & E-commerce",
    image: "images/AdobeStock_459666237.jpeg",
    title: "Modernizing EDI Integration with MuleSoft",
    description:
      "Mark Anthony Group one of North America's largest beverage companies...",
    tags: ["Retail & E-commerce", "DevOps & CI/CD"],
  },
  {
    id: 7,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "images/people-working-lightbulb-color-year-2023.jpg",
    title: "Enhancing Eligibility Verification with Boomi",
    description:
      "DHS Georgia needed a modern, automated <br/>eligibility verification...",
    tags: ["Government & Public Services"],
  },
  {
    id: 8,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "images/high-angle-boxes-laptop-arrangement.jpg",
    title: "Seamless Order Management with Boomi",
    description:
      "In today’s fast-paced business environment, integrating enterprise systems is no longer a<br/> luxury—it’s...",
    tags: [" Integration & Automation", "Data & AI-Driven Strategy"],
  },
  {
    id: 9,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "images/freepik__enhance__67791.png",
    title: "SAP to Epicor Integration Using Azure Logic Apps",
    description:
      "BOPPY, a leading manufacturing and supply chain solutions provider, faced integration challenges between SAP ERP...",
    tags: ["Data & AI-Driven Strategy"],
  },
  {
    id: 10,
    num: "Success Stories",
    category: "Integration & Automation",
    image: "/images/individuality-concept-paper-planes.jpg",
    title: "Seamless Migration from Dell Boomi to SAP BTP",
    description:
      "Leading SuccessFactors Customerrovider,faced significant challenges with its existing Dell Boomi integration platform...",
    tags: ["Data & AI-Driven Strategy"],
  },
  {
    id: 11,
    num: "Whitepapers",
    category: "DevOps & CI/CD",
    image: "images/AdobeStock_1005867423.jpeg",
    title: "Migrating from MuleSoft to Azure Logic Apps",
    description:
      "Migration aimed to redesign the existing architecture to leverage Azure's robust development ecosystem, enhance scalability and streamline integration processes...",
    tags: ["Data & AI-Driven Strategy"],
  },

];

// Count the number of blogs per category
const categoryCounts = blogData.reduce((acc, blog) => {
  if (blog.num === "Success Stories") {
    acc["Success Stories"] = (acc["Success Stories"] || 0) + 1;
  } else if (blog.num === "Whitepapers") {
    acc["Whitepapers"] = (acc["Whitepapers"] || 0) + 1;
  }
  return acc;
}, {});

console.log(categoryCounts);

const categories = [
  "All",
  "Cloud Migrations",
  "Integration & Automation",
  "DevOps & CI/CD",
  "Data & AI-Driven Strategy",
  "Retail & E-commerce",
  "Finance & Banking",
  "Supply Chain & Manufacturing",
  "Government & Public Services",
];

const Blogs = () => {
  const navigate = useNavigate();
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortOrder, setSortOrder] = useState("");

  const filteredBlogs =
    selectedCategory === "All"
      ? blogData
      : blogData.filter(
          (blog) =>
            blog.category === selectedCategory ||
            blog.tags.includes(selectedCategory)
        );

  return (
    <>
      <div className="blogs-container">
        <Header />
      <div className="fordistance">
      {Object.entries(categoryCounts).map(([category, count], index) => (
        <div
          key={index}
          className={`blog-item-whitepaper ${category.toLowerCase()}`}
          onClick={() => {
            if (category === "Whitepapers") navigate("/White-Papers");
            if (category === "Success Stories") navigate("/blogs");
          }}
          style={{ cursor: "pointer" }} // Makes it look clickable
        >
          <h1
            className={category === "Whitepapers" ? "regular-text" : "bold-text"}
            style={category === "Blogs" ? { color: "black", cursor: "default" } : {}}
          >
            {category}
            <span className="count">{count}</span>
          </h1>
        </div>
      ))}

      <div className="filter-section">
        {/* Filter Buttons */}
        <div className="tabs-wrapper">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`filter-btn ${selectedCategory === category ? "active" : ""}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>


 {/* reepik__adjust__67790.png  */}
        {/* Sort Dropdown */}
        {/* <div className="sort-section">
          <select className="sort-select" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
            <option value="" disabled>Sort</option>
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div> */}
      </div>
    </div>
 

        {(selectedCategory === "Integration & Automation" ||
          selectedCategory === "All") && (
          <div className="blog-content">
            {/* Categories */}
            <div className="blog-categories">
              <div className="blog-category">Cloud Migrations</div>
              <div className="blog-category">DevOps & CI/CD</div>
            </div>

            {/* Blog Title */}
            <div className="blog-name">
              Migration from Mulesoft to Azure Logic Apps and Function Apps
            </div>

            {/* Blog Description */}
            <p className="blog-text">
            Talking Rain cut integration costs by 40% and improved processing speed by 2x—here’s how we did it with Azure Logic Apps and Function Apps...
            </p>

            {/* Read More Link */}
            <Link
              to="/blogs/Migration-from-Mulesoft-to-Azure-Logic-Apps-and-Function-Apps"
              className="blog-link"
            >
               <button className="read">Read the story <span className="arrow-icon">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0H10.3448V9.17358L1.24219 0.0709888L0.0717958 1.24138L9.17534 10.3448H0V12H12V0Z"
                    fill="white"
                  ></path>
                </svg>
              </span></button>
             
            </Link>
          </div>
        )}

<section className="newblogs">
  {filteredBlogs.length === 0 ? (
    <p style={{ textAlign: "center", fontSize: "1.2rem", color: "#333" }}>
      No blogs found for this category.
    </p>
  ) : (
    filteredBlogs.map((blog) => (
      <div key={blog.id} className="blog-item">
        {/* Image Section */}
        <div className="blogimg">
          <img src={blog.image} alt={blog.title} />
        </div>

        {/* Text Section */}
        <div className="blogtxt">
          <div className="tags">
            {blog.tags.map((tag, i) => (
              <span key={i}>{tag}</span>
            ))}
          </div>
          <h2 dangerouslySetInnerHTML={{ __html: blog.title }}></h2>
          <p dangerouslySetInnerHTML={{ __html: blog.description }}></p>

          {/* Button Stays at Bottom */}
          <Link to={
              blog.id === 1
                ? "/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps"
                : blog.id === 2
                ? "/blogs/Seamless-migration-d365"
                : blog.id === 3
                ? "/blogs/Transformation-From-iWay-to-mulesoft"
                : blog.id === 4
                ? "/blogs/segment-cdp-data-marketing"
                : blog.id === 6
                ? "/blogs/ecommerce-marketing-2025"
                : blog.id === 7
                ? "/blogs/top-10-best-reads"
                : blog.id === 8
                ? "/blogs/Integrating-QAD-and-EAM"
                : blog.id === 9
                ? "/blogs/SAP-to-Epicor"
                : blog.id === 10
                ? "/blogs/Boomi-to-SAP"
                : blog.id === 11
                ? "/White-Papers/Accelerating-Mulesoft-to-Azure-logic-app-Migration-with-Ai-powered-Automation"
                : "/"
            }
            >
            <button className="read">Read the story <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0H10.3448V9.17358L1.24219 0.0709888L0.0717958 1.24138L9.17534 10.3448H0V12H12V0Z"
                    fill="black"
                  ></path>
                </svg></button>
          </Link>
        </div>
      </div>
    ))
  )}
</section>



      </div>
      {/* Contact Us */}
      
<Footer/>
    </>
  );
};

export default Blogs;
