import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react"; 
import "./Homepage.css";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Instagram, Linkedin, Youtube } from "lucide-react";
import PartnerImage from "../assets/images/main.png";
import Footer from "./Footer";
const isMac = /Mac/.test(navigator.platform);


const servicesData = [
  {
    id: 1,
    number: "01",
    title: (
      <>
        {window.innerWidth >= 768 ? (
          <>
            Integration
            <br />
            solutions
          </>
        ) : (
          <>Integration solutions</>
        )}
      </>
    ),
    description:
      "Break free from legacy limits—seamless, scalable, and future-proof migrations with zero disruption! Empoower your business with next-gen connectivity and automation. ",
    color: "#F8A007",
  },
  {
    id: 2,
    number: "02",
    title: <>Enterprise <br/> Solutions </>,
    description:
      "Seamless connectivity to streamline your business operations. Integrate faster, scale effortlessly, and also stay ahead of the competition around.",
    color: "#6B6DEC",
  },
  {
    id: 3,
    number: "03",
    title: (
      <>
        Cloud-Native <br/> Excellence
        <span
          style={{ display: "block", fontSize: "40px", margin: 0, padding: 0 }}
        >
          {" "}
        </span>
      </>
    ),
    description:
      "Accelerate software delivery with DevOps automation, CI/CD pipelines, and cloud-native scalability.Boost efficiency, reduce downtime, and innovate faster.",
    color: "#EC6B89",
  },
  {
    id: 4,
    number: "04",
    title: (
      <>
        {window.innerWidth >= 768 ? (
          <>
            Application
            <br /> Support{" "}
          </>
        ) : (
          <>Application Support</>
        )}
      </>
    ),
    description:
      "Ensure seamless performance, rapid issue resolution, and optimized system efficiency.Maximize uptime and keep your applications running at peak performance.",
    color: "#30D8C5",
  },
  // { id: 5, number: '05', title: <>ERP Consulting & End-to-End Solutions </>, description: 'Optimize, Integrate, and Scale with a Future-Ready ERP System .', color: '#30D8C5' },
];

const slides = [
  {
    id: 1,
    text: "“CyntraLabs ensured a seamless Azure migration, reducing costs and optimizing integration efficiency.”",
    position: "Talking Rain Beverage Company",
    author: "Dave Horner ",
    position: "Vice President",
  
    img: "/images/nwww.png",
    bgColor: "#FFDDC1", // Light Orange
  },
  {
    id: 2,
    text: "“CyntraLabs optimized eligibility verification with Dell Boomi, improving speed, accuracy, and scalability”",
    position: "DHS Georgia",
    author: "Mohan S.",
    position: "Assistant Comissioner",
    // author: "Mohan S. (Assistant Comissioner)",
    img: "/images/georgiadhs_logo.jpeg",
    bgColor: "#FFDDC1",
  },
  {
    id: 3,
    text: "“The SAP to Epicor migration using Azure Logic Apps was seamless, efficient, and exceeded our expectations.”",
    author: "Jim Wilkins",
    position: "Head of Boppy NA",
    img: "/images/freepik__background__54022.png",
    bgColor: "#C1E1FF", // Light Blue
  },
  {
    id: 4,
    text: "“CyntraLabs unified data with Segment CDP, enhancing engagement, targeting, and revenue growth.”",
    author: "Ricardo Gonzalez",
    position:
      " Director",
     
    img: "/images/mark_Antony.png",
    bgColor: "#FFDDC1", // Light Orange
  },

  {
    id: 5,
    text: "“Thrilled with the project's progress! Sparking excitement as they prioritize AI initiatives across the project.”",
    author: "Greg Jensen",
    position: "Program Manager",
    img: "/images/freepik__background__31517.png",
    bgColor: "#FFDDC1",
  },

  // {
  //   id: 6,
  //   text: "“With SAP ECC and custom integrations, we provided strategic consulting, precise tool selection, and seamless API-driven development”",
  //   author: "Watches of Switzerland",
  //   position: "Seamless Migration to D365",
  //   img: "/images/wos-logo1.png",
  //   bgColor: "#C1E1FF", // Light Blue
  // },
  {
    id: 7,
    text: "“The SAP S/4 & EDI solution with SAP PI/PO 7.5 enabled seamless integration, rapid deployment, and enhanced efficiency for our customers.”",
    author: "Krishna Kashyap",
    position:  "Vice President",
    img: "/images/Shiseido-logo.png",
    bgColor: "#D1FFC1", // Light Green
  },
  {
    id: 8,
    text: "“CyntraLabs’ API-first approach enabled a smooth MuleSoft migration, enhancing global integration and efficiency.”",
    author: "Ricardo Gonzalez",
    position:
      "Director",
    
    img: "/images/mark_Antony.png",
    bgColor: "#C1E1FF", // Light Blue
  },

  {
    id: 9,
    text: "“With MuleSoft on-premise integration for SAP S/4 & financial systems, we achieved seamless connectivity & rapid deployment using factory model approach”",
    author: "Dmitriy Gerzon",
    position: "Director",
    img: "/images/QVC-Logo.png",
    bgColor: "#D1FFC1", // Light Green
  },
  {
    id: 10,
    text: "“With SAP Ariba, SAP CPI, and MuleSoft, we delivered a strategic, end-to-end integration solution, ensuring seamless API and web services connectivity.”",
    author: "Michael Kepe",
    position: "Vice-President",
    img: "/images/ContractPodAI-Logo.png",
    bgColor: "#D1FFC1", // Light Green
  },
];
const TestimonialSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(3); // Default 3 slides visible

  // Adjust number of visible slides based on screen size
  useEffect(() => {
    const updateVisibleSlides = () => {
      if (window.innerWidth < 768) {
        setVisibleSlides(1); // Mobile: Show 1 card
      } else if (window.innerWidth < 1024) {
        setVisibleSlides(2); // Tablet: Show 2 cards
      } else {
        setVisibleSlides(3); // Desktop: Show 3 cards
      }
    };

    updateVisibleSlides();
    window.addEventListener("resize", updateVisibleSlides);
    return () => window.removeEventListener("resize", updateVisibleSlides);
  }, []);

  const totalSlides = slides.length;

  // Go to next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleSlides >= totalSlides ? 0 : prevIndex + visibleSlides
    );
  };

  // Go to previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalSlides - visibleSlides : prevIndex - visibleSlides
    );
  };

  return (
    <section className="testimonial-section">
      <div className="testimonial-header">
        <p>Testimonials</p>
        <br/>
        <div class="divider"></div>
        {/* <h2>Don’t take our word for it! <br /> Hear it from our partners.</h2> */}
      </div>

      {/* Testimonial Slider */}
      <div className="testimonial-slider-wrapper">
        <button className="slider-btn left" onClick={prevSlide}>
          <ChevronLeft size={32} />
        </button>

        <div className="testimonial-slider">
          <AnimatePresence mode="wait">
            {slides.slice(currentIndex, currentIndex + visibleSlides).map((testimonial) => (
              <motion.div
                key={testimonial.id}
                className="testimonial-card"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                <img src={testimonial.img} alt={testimonial.author} />
                <p className="testimonial-text">{testimonial.text}</p>
                <h4 className="testimonial-author">{testimonial.author}</h4>
                <p className="testimonial-position">{testimonial.position}</p>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        <button className="slider-btn right" onClick={nextSlide}>
          <ChevronRight size={32} />
        </button>
      </div>

      {/* Dots Navigation */}
      <div className="dots-container">
        {Array.from({ length: Math.ceil(totalSlides / visibleSlides) }).map((_, index) => (
          <div
            key={index}
            className={`dot ${Math.floor(currentIndex / visibleSlides) === index ? "active" : ""}`}
            onClick={() => setCurrentIndex(index * visibleSlides)}
          ></div>
        ))}
      </div>
    </section>
  );
};

const articles = [
  {
    id: 1,
    category: "DevOps & CI/CD",
    image: "images/image (7).jpg",
    title: "Revolutionizing CI/CD for Azure Logic Apps",
    description: "Following the successful migration from MuleSoft...",
  },
  {
    id: 2,
    category: "Retail & E-commerce",
    image: "images/AdobeStock_72421384.jpeg",
    title: "Seamless Migration to D365",
  },
  {
    id: 3,
    category: "Integration & Automation",
    image: "images/iway.png",
    title: "Enterprise-Scale Integration Transformation",
  },
  {
    id: 4,
    category: "Retail & E-commerce",
    image: "images/AdobeStock_1158752939.jpeg",
    title: "Driving Digital Transformation: Integrating Segment CDP ",
  },
  {
    id: 5,
    category: "Retail & E-commerce",
    image: "images/AdobeStock_459666237.jpeg",
    title: "Modernizing EDI Integration with MuleSoft",
  },
  {
    id: 6,
    category: "Integration & Automation",
    image: "images/people-working-lightbulb-color-year-2023.jpg",
    title: "Enhancing Eligibility Verification with Dell Boomi",
  },
  {
    id: 7,
    category: "Integration & Automation",
    image: "images/high-angle-boxes-laptop-arrangement.jpg",
    title: "Seamless Order Management with Boomi",
  },
  {
    id: 8,
    category: "Integration & Automation",
    image: "images/freepik__enhance__67791.png",
    title: "SAP to Epicor Integration Using Azure Logic Apps",
  },
];



const ArticlesSection = () => {
  const navigate = useNavigate();
const getPath = (id) => {
  switch (id) {
    case 1:
      return "/blogs/Revolutionizing-CI/CD-for-Azure-Logic-Apps";
    case 2:
      return "/blogs/Seamless-migration-d365";
    case 3:
      return "/blogs/Transformation-From-iWay-to-mulesoft";
    case 4:
      return "/blogs/segment-cdp-data-marketing";
    case 5:
      return "/blogs/ecommerce-marketing-2025";
    case 6:
      return "/blogs/top-10-best-reads";
    case 7:
      return "/blogs/Integrating-QAD-and-EAM";
    case 8:
      return "/blogs/SAP-to-Epicor";
    default:
      return "/";
  }
};
  const [startIndex, setStartIndex] = useState(0);
  const articlesPerPage = 6;
  
  const nextSlide = () => {
    setStartIndex((prev) => (prev + 3) % articles.length);
  };

  const prevSlide = () => {
    setStartIndex((prev) => (prev - 3 + articles.length) % articles.length);
  };

  return (
    <section className="articles-section px-8 py-12">
<div className="w-full px-8">
<p className="what-we-do-title">Our</p>
<h2 className="text-4xl font-bold text-[#042e50] ml-6">Success Stories</h2>

  <div class="divider"></div>
  <div className="border-b border-gray-300 mt-2 w-full"></div>
</div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
        {articles.slice(startIndex, startIndex + articlesPerPage).map((article) => (
          <motion.div
            key={article.id}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            onClick={() => navigate(getPath(article.id))}
          >
            <img
              src={article.image}
              alt={article.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <span className="text-sm bg-gray-100 px-2 py-1 rounded-md">
                {article.category}
              </span>
              <h3 className="text-lg font-semibold mt-2">{article.title}</h3>
            </div>
          </motion.div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <button onClick={prevSlide} className="px-4 py-2 bg-gray-300 rounded-md mr-2"><ChevronLeft size={32} /></button>
        <button onClick={nextSlide} className="px-4 py-2 bg-gray-300 rounded-md"><ChevronRight size={32} /></button>
      </div>
    </section>
  );
};

const Homepage = () => {
  const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [openOfferDropdown, setOpenOfferDropdown] = useState(false);
  const [openKnowledgeDropdown, setOpenKnowledgeDropdown] = useState(false);
  const [openPartnerDropdown, setOpenPartnerDropdown] = useState(false);
  const [openAboutDropdown, setOpenAboutDropdown] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [startIndex, setStartIndex] = useState(0);
  const articlesPerPage = 6;
  
  
  const nextSlide = () => {
    setStartIndex((prev) => (prev + 3) % articles.length);
  };

  const prevSlide = () => {
    setStartIndex((prev) => (prev - 3 + articles.length) % articles.length);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // ✅ Dynamically load CSS based on screen size
  useEffect(() => {
    if (isMobile) {
      import("./HomepageMobile.css");
      document.body.style.overflowX = "hidden";
    } else {
      import("./Homepage.css");
      document.body.style.overflowX = "none";
    }
  }, [isMobile]);

  const [anchorElOffer, setAnchorElOffer] = useState(null);
  const [anchorElKnowledge, setAnchorElKnowledge] = useState(null);
  const [anchorElPartner, setAnchorElPartner] = useState(null);
  const [anchorElAbout, setAnchorElAbout] = useState(null);

  const handleOpenOfferMenu = (event) => {
    setAnchorElOffer(anchorElOffer ? null : event.currentTarget);
  };

  const handleCloseOfferMenu = () => {
    setAnchorElOffer(null);
  };

  const handleOpenKnowledgeMenu = (event) => {
    setAnchorElKnowledge(anchorElKnowledge ? null : event.currentTarget);
  };

  const handleCloseKnowledgeMenu = () => {
    setAnchorElKnowledge(null);
  };

  const handleOpenPartnerMenu = (event) => {
    setAnchorElPartner(anchorElPartner ? null : event.currentTarget);
  };

  const handleClosePartnerMenu = () => {
    setAnchorElPartner(null);
  };

  const handleOpenAboutMenu = (event) => {
    setAnchorElAbout(anchorElAbout ? null : event.currentTarget);
  };

  const handleCloseAboutMenu = () => {
    setAnchorElAbout(null);
  };

  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(null); // Stores the currently open dropdown

  const handleToggleMenu = (menu) => {
    setOpenDropdown((prev) => (prev === menu ? null : menu)); // Toggle the clicked menu
  };

  const handleMouseEnter = (menu) => {
    setOpenDropdown(menu); // Open on hover
  };

  const handleMouseLeave = () => {
    setOpenDropdown(null); // Close when not hovering
  };

  const menuItems = {
    "What We Offer": [
      { name: "Integration solutions", path: "/Migrating-ERPSolutions" },
      { name: "Enterprise Integration Solutions", path: "/ERP-Solutions" },
      { name: "Cloud-Native Excellence: CI/CD, DevOps & Automation", path: "/Devops-Services" },
      { name: "Application Management & Support", path: "/AMS-Services" },
      { name: "ERP Consulting & End-to-End Solutions", path: "/ERP-Consulting" },
    ],
    Knowledge: [
      { name: "Success Stories", path: "/blogs" },
      { name: "White Papers", path: "/White-Papers" },
    ],
    About: [
      { name: "About Us", path: "/AboutUs" },
      { name: "Careers", path: () => window.open("https://cyntra.ai/careers") },
    ],
    "Our Partners": [
      { name: "Service Now", path: "/Service-Now" },
      { name: "Google Cloud", path: "/Google-Cloud" },
      { name: "Salesforce", path: "/Salesforce" },
      { name: "SAP Labs", path: "/SAP-Labs" },
    ],
  };
  // Define the paths based on service ID
  const servicePaths = {
    1: "/Migrating-ERPSolutions",
    2: "/ERP-Solutions",
    3: "/Devops-Services",
    4: "/AMS-Services",
  };

  const handleNavigation = (id) => {
    if (servicePaths[id]) {
      navigate(servicePaths[id]);
      window.scrollTo(0, 0); // Scrolls to the top after navigation
    } else {
      alert("Invalid Service ID");
    }
  };

  //Testimonials slider
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        return nextIndex < slides.length ? nextIndex : 0;
      });
    }, 9000); // Change slide every 4 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 440) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div
        className="homepage-container"
        style={{
          minHeight: isMobile ? "100vh" : "100vh", // ✅ Auto height for mobile
          backgroundImage:
            "url('https://eadn-wc03-14239622.nxedge.io/wp-content/uploads/2024/04/hero-background.jpg.webp')",

          // background: 'linear-gradient(90deg, rgba(91, 165, 233, 0.231) 13.33%, rgba(4, 46, 80, 0.264) 82.29%)',
        }}
      >
        {/* Centered Text */}
        <motion.div
          className={`homepage-title ${isScrolled ? "fixed-header" : ""}`}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Ensure space between elements
            marginRight: !isScrolled ? "9rem" : "0rem", // Adjust margin on scroll
            transition: "margin-right 0.3s ease-in-out", // Smooth transition
            gap: "1rem",
            marginTop: "-1rem",
            ...(isScrolled
              ? {
                  background:
                    "linear-gradient(90deg, rgba(91, 165, 233, 0.231) 13.33%, rgba(4, 46, 80, 0.264) 82.29%), rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(10px)",
                  WebkitBackdropFilter: "blur(10px)", // Safari support
                }
              : {}),
          }}
        >
          {/* Logo and Title */}
          {isScrolled ? (
            <>
              {/* Fixed Logo on the Left */}
              <Link to="/" style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/images/CYNTRALABS Logo.png"
                  alt="CyntraLabs Logo"
                  className="fixed-logo"
                />
              </Link>

              {/* Fixed "Schedule a Call" Button (Visible in Non-Mobile Views) */}
              {/* {!isMobile && (
                <button
                  className="fixed-call-button"
                  onClick={() => navigate("/Get-In-Touch")}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#4A90D9")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#5BA5E9")
                  }
                >
                  Schedule a Call
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animated-arrow"
                  >
                    <path
                      d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                      stroke="#010f1a"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              )} */}

              {/* Fixed "Schedule a Call" Button on the Right */}
              <button
                className="fixed-call-button"
                onClick={() => navigate("/Get-In-Touch")}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#4A90D9")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#5BA5E9")
                }
              >
                Schedule a Call
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animated-arrow"
                >
                  <path
                    d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                    stroke="#010f1a"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </>
          ) : (
            <>
              {/* Non-Scrolled State: Show CyntraLabs Text */}

              <span style={{ fontSize: isMobile ? "2rem" : "inherit" }}>
                CyntraLabs
              </span>

              {/* "Schedule" Button (Visible Only on Mobile) */}
              {/* <button
  className="schedule-button mobile-only"

  onMouseEnter={(e) => (e.target.style.backgroundColor = "#4A90D9")}
  onMouseLeave={(e) => (e.target.style.backgroundColor = "#5BA5E9")}
>
Schedule a Call
<svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="animated-arrow"
        >
          <path
            d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
            stroke="#010f1a"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
</button> */}
            </>
          )}
        </motion.div>

        <header
          className={`homepage-header ${isScrolled ? "fixed-header" : ""}`}
        >
          {isMobile ? (
            <>
              {/* Mobile Menu Icon */}
              <IconButton
                onClick={toggleDrawer(true)}
                className={!isScrolled ? "menu-button" : ""}
                style={
                  isScrolled
                    ? {
                        position: "fixed",
                        top: "-0.0625rem",
                        right: "0rem",
                        transition: "all 0.3s ease-in-out",
                        zIndex: 1000,
                        color: "white",
                      }
                    : {}
                }
              >
                <MenuIcon fontSize="large" />
              </IconButton>

              {/* Mobile Drawer */}
              <SwipeableDrawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                <div className="mobile-nav">
                  {/* What We Offer Dropdown */}
                  <button
                    onClick={() => setOpenOfferDropdown(!openOfferDropdown)}
                    className="mobile-nav-button"
                  >
                    What We Offer{" "}
                    <span
                      className={`dropdown-icon ${
                        openOfferDropdown ? "rotate" : ""
                      }`}
                    >
                      <KeyboardArrowDownIcon />
                    </span>
                  </button>
                  {openOfferDropdown && (
                    <div className="mobile-dropdown">
                      <ul>
                        {[
                          {
                            name: "Integration solutions",
                            path: "/Migrating-ERPSolutions",
                          },
                          {
                            name: "Enterprise Integration Solutions",
                            path: "/ERP-Solutions",
                          },
                          {
                            name: "Cloud-Native Excellence: CI/CD, DevOps & Automation",
                            path: "/Devops-Services",
                          },
                          {
                            name: "Application Management & Support",
                            path: "/AMS-Services",
                          },
                          {
                            name: "ERP Consulting & End-to-End Solutions",
                            path: "/ERP-Consulting",
                          },
                        ].map((item, index) => (
                          <p key={index}>
                            <button
                              onClick={() => {
                                navigate(item.path);
                                setDrawerOpen(false);
                              }}
                              className="dropdown-button"
                            >
                              {item.name}
                            </button>
                          </p>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Knowledge Dropdown */}
                  <button
                    onClick={() =>
                      setOpenKnowledgeDropdown(!openKnowledgeDropdown)
                    }
                    className="mobile-nav-button"
                  >
                    Knowledge{" "}
                    <span
                      className={`dropdown-icon ${
                        openKnowledgeDropdown ? "rotate" : ""
                      }`}
                    >
                      <KeyboardArrowDownIcon />
                    </span>
                  </button>
                  {openKnowledgeDropdown && (
                    <div className="mobile-dropdown">
                      <ul>
                        {[
                          { name: "Success Stories", path: "/blogs" },
                          { name: "White Papers", path: "/White-Papers" },
                        ].map((item, index) => (
                          <li key={index}>
                            <button
                              onClick={() => {
                                navigate(item.path);
                                setDrawerOpen(false);
                              }}
                              className="dropdown-button"
                            >
                              {item.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* About Dropdown */}
                  <button
                    onClick={() => setOpenAboutDropdown(!openAboutDropdown)}
                    className="mobile-nav-button"
                  >
                    About{" "}
                    <span
                      className={`dropdown-icon ${
                        openAboutDropdown ? "rotate" : ""
                      }`}
                    >
                      <KeyboardArrowDownIcon />
                    </span>
                  </button>
                  {openAboutDropdown && (
                    <div className="mobile-dropdown">
                      <ul>
                        {[
                          { name: "About Us", path: "/AboutUs" },
                          {
                            name: "Careers",
                            path: () =>
                              window.open("https://cyntra.ai/careers"),
                          },
                        ].map((item, index) => (
                          <li key={index}>
                            <button
                              onClick={() => {
                                if (typeof item.path === "function") {
                                  item.path();
                                } else {
                                  navigate(item.path);
                                }
                              }}
                              className="dropdown-button"
                            >
                              {item.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Partner Dropdown */}
                  <button
                    onClick={() => setOpenPartnerDropdown(!openPartnerDropdown)}
                    className="mobile-nav-button"
                  >
                    Our Partners{" "}
                    <span
                      className={`dropdown-icon ${
                        openPartnerDropdown ? "rotate" : ""
                      }`}
                    >
                      <KeyboardArrowDownIcon />
                    </span>
                  </button>
                  {openPartnerDropdown && (
                    <div className="mobile-dropdown">
                      <ul>
                        {[
                          { name: "Service Now", path: "/Service-Now" },
                          { name: "Google Cloud", path: "/Google-Cloud" },
                          { name: "Salesforce", path: "/Salesforce" },
                          { name: "SAP Labs", path: "/SAP-Labs" },
                        ].map((item, index) => (
                          <li key={index}>
                            <button
                              onClick={() => {
                                navigate(item.path);
                                setDrawerOpen(false);
                              }}
                              className="dropdown-button"
                            >
                              {item.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Other Links */}
                  <button
                    onClick={() => {
                      navigate("/Get-In-Touch");
                      setDrawerOpen(false);
                    }}
                  >
                    Contact
                  </button>
                </div>
              </SwipeableDrawer>
            </>
          ) : (
            /* Desktop Navigation */
            <nav className="homepage-nav">
            {Object.entries(menuItems).map(([menu, links]) => (
              <div
                key={menu}
                className="dropdown-container"
                onMouseEnter={() => handleMouseEnter(menu)}
                onMouseLeave={handleMouseLeave}
              >
                <a className="nav-link" onClick={() => handleToggleMenu(menu)}>
                  {menu}
                  <span className={`dropdown-icon ${openDropdown === menu ? "rotate" : ""}`}>
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 1L7.707 6.293C7.317 6.683 6.683 6.683 6.293 6.293L1 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                  </span>
                </a>
      
                {openDropdown === menu && (
                  <div className="dropdown-menu">
                    <ul>
                      {links.map((item, index) => (
                        <li key={index}>
                          <button
                            onClick={() => {
                              if (typeof item.path === "function") {
                                item.path(); // Open careers link
                              } else {
                                navigate(item.path); // Navigate to route
                              }
                              setOpenDropdown(null); // Close dropdown after clicking
                            }}
                            className="dropdown-button"
                          >
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
      
            {/* Contact Link */}
            <a href="/Get-In-Touch" className="nav-link">
              Contact
            </a>
      
            {/* Floating Button for Scheduling a Call */}
            <button
              className="fixed-call-button"
              style={{ position: "absolute", top: "15%", right: "1vw", transition: "all 0.3s ease" }}
              onClick={() => navigate("/Get-In-Touch")}
            >
              Schedule a Call
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-arrow">
                <path d="M1 1L14 14M15 3V13.586C15 14.367 14.367 15 13.586 15H3" stroke="#042e50" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </button>
          </nav>
          )}
        </header>
        <div className="partner-and-main-container">
          <div className="partner-image-container">
            <img
              src={PartnerImage}
              alt="Partner Image"
              className="partner-image"
            />
          </div>

          {/* "Schedule" Button (Visible Only on Mobile) */}
          {/* {isMobile && (
  <button
    className="schedule-button mobile-only"
    onMouseEnter={(e) => (e.target.style.backgroundColor = "#4A90D9")}
    onMouseLeave={(e) => (e.target.style.backgroundColor = "#5BA5E9")}
  >
    Schedule a Call
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animated-arrow"
    >
      <path
        d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
        stroke="#010f1a"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </button>
)} */}

          <main className="homepage-main">
            <p className="homepage-section">
              <motion.div
                className="homepage-heading"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 2.2, ease: "easeOut" }}
              >
                Unleash your
                <br />
                Innovation.Excellence.Expertise
                <br />
                <span
                  className={isMobile ? "homepage-heading" : "powered-by-ai"}
                  style={isMobile ? { color: "#A6F8F8" } : {}}
                >
                  → Proven results in weeks,
                  {isMobile && <br />}
                  not years
                </span>
              </motion.div>
            </p>
          </main>
          {/* "Schedule" Button (Visible Only on Mobile) */}
          {isMobile && (
            <div className="schedule-button-container">
              <button
                className="schedule-button for-mobile-only"
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#4A90D9")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#5BA5E9")
                }
                onClick={() => navigate("/Get-In-Touch")}
              >
                Schedule a Call
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animated-arrow"
                >
                  <path
                    d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                    stroke="#010f1a"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Industries Section */}
      <section className="industries-section">
        {/* Background Wrapper with Image Animation */}
        <motion.div
          className="industries-background"
          initial={{ opacity: 0, scale: 1.1 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
          viewport={{ amount: 0.2 }} // ✅ Replays animation when visible again
        >
          <img
            src="images/industriesnew.jpg"
            alt="Industries"
            className="industries-image"
          />
        </motion.div>

        {/* Bottom Half - Solid Color */}
        <div className="industries-bottom"></div>

        {/* Content Card with Animations */}
        <motion.div
          className="industries-card"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          viewport={{ amount: 0.2 }} // ✅ Allows animation on scroll back
        >
          {/* Title Animation */}
          <motion.h2
            className="industries-title"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
            viewport={{ amount: 0.2 }}
          >
            Industries We Serve
          </motion.h2>

          {/* Centered Divider Line Animation */}
          <motion.div
            className="industries-divider"
            initial={{ scaleX: 0 }}
            whileInView={{ scaleX: 1 }}
            transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
            viewport={{ amount: 0.2 }}
            style={{ transformOrigin: "center" }}
          ></motion.div>

          {/* Industry List Animation */}
          <div className="industries-list">
            {[
              { title: "Retail & E-commerce" },
              { title: "Finance & Banking" },
              { title: "Healthcare & Life Sciences" },
              { title: "Manufacturing & Supply Chain" },
              { title: "Technology & SaaS" },
            ].map((industry, index) => (
              <motion.div
                key={index}
                className="industry-item"
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.6,
                  delay: 0.2 * index,
                  ease: "easeOut",
                }}
                viewport={{ amount: 0.2 }} // ✅ Re-triggers animation on scroll up/down
              >
                <h3 className="industry-title">{industry.title}</h3>
                <p className="industry-description">{industry.description}</p>
              </motion.div>
            ))}
          </div>

          {/* Know Us More Button */}
          <button className="button-KnowUs" onClick={() => navigate("/blogs")}>
            Know us more
            {!isMobile && <span>&nbsp;&nbsp;&nbsp;</span>}{" "}
            {/* Show spacing only if not mobile */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animated-arrow"
            >
              <path
                d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                stroke="#010f1a"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </motion.div>
      </section>
      <div className="services-header">
        <p className="what-we-do-title">Our</p>
        <h2 className="services-title">Services</h2>
        <div className="divider" style={{ width: "90%" }}></div>
      </div>

      {/* Services section */}
      <div className="services-container">
        {servicesData.map((service, index) => (
          <motion.div
            key={service.id}
            className="service-item"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1, ease: "easeOut" }}
            viewport={{ once: true }}
          >
            {/* Service Header Row: Number + Title */}
            <div className="service-header-row">
              <div className="service-content">
                <h3 style={{ color: service.color }}>{service.title}</h3>
              </div>
              <div className="service-number" style={{ color: service.color }}>
                {service.number}
              </div>
            </div>

            {/* Service Description and Button Row */}
            <div className="service-content">
              <p>{service.description}</p>
              <button
                className="button-custom"
                onClick={() => navigate(servicePaths[service.id] || "/")}
              >
                Explore our offerings
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animated-arrow"
                >
                  <path
                    d="M1 1L14 14M15 3V13.5858C15 14.3668 14.3668 15 13.5858 15H3"
                    stroke="#010f1a"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
          </motion.div>
        ))}
      </div>

      <ArticlesSection />
      <TestimonialSection />

      {/* Testimonials Crousal */}
 

      <Footer />

    </>
  );
};

export default Homepage;
