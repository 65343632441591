import React , { useState,useEffect } from 'react'
import { motion ,AnimatePresence} from 'framer-motion';
import { ChevronLeft, ChevronRight } from "lucide-react"; 
import "./Homepage.css";
import Footer from "./Footer"
import './AboutUs.css';
import Header from "./Header";
import { Linkedin, Instagram, Youtube } from 'lucide-react';
import { useNavigate, Link } from "react-router-dom";
const AboutUs = () => {
  const navigate = useNavigate();
     const MOBILE_SCREEN_SIZE = 768; // ✅ Define the mobile breakpoint
    
      const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_SCREEN_SIZE);
      
      useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
        };
      
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
     
      const slides = [
        {
          id: 1,
          text: "“CyntraLabs ensured a seamless Azure migration, reducing costs and optimizing integration efficiency.”",
          position: "Talking Rain Beverage Company",
          author: "Ricardo Gonzalez ",
          position: "Director",
        
          img: "/images/nwww.png",
          bgColor: "#FFDDC1", // Light Orange
        },
        {
          id: 2,
          text: "“CyntraLabs optimized eligibility verification with Dell Boomi, improving speed, accuracy, and scalability”",
          position: "DHS Georgia",
          author: "Mohan S.",
          position: "Assistant Comissioner",
          // author: "Mohan S. (Assistant Comissioner)",
          img: "/images/georgiadhs_logo.jpeg",
          bgColor: "#FFDDC1",
        },
        {
          id: 3,
          text: "“The SAP to Epicor migration using Azure Logic Apps was seamless, efficient, and exceeded our expectations.”",
          author: "Jim Wilkins",
          position: "Head of Boppy NA",
          img: "/images/freepik__background__54022.png",
          bgColor: "#C1E1FF", // Light Blue
        },
        {
          id: 4,
          text: "“CyntraLabs unified data with Segment CDP, enhancing engagement, targeting, and revenue growth.”",
          author: "Ricardo Gonzalez",
          position:
            " Director",
           
          img: "/images/mark_Antony.png",
          bgColor: "#FFDDC1", // Light Orange
        },
      
        {
          id: 5,
          text: "“Thrilled with the project's progress! Sparking excitement as they prioritize AI initiatives across the project.”",
          author: "Greg Jensen",
          position: "Program Manager",
          img: "/images/freepik__background__31517.png",
          bgColor: "#FFDDC1",
        },
      
        // {
        //   id: 6,
        //   text: "“With SAP ECC and custom integrations, we provided strategic consulting, precise tool selection, and seamless API-driven development”",
        //   author: "Watches of Switzerland",
        //   position: "Seamless Migration to D365",
        //   img: "/images/wos-logo1.png",
        //   bgColor: "#C1E1FF", // Light Blue
        // },
        {
          id: 7,
          text: "“The SAP S/4 & EDI solution with SAP PI/PO 7.5 enabled seamless integration, rapid deployment, and enhanced efficiency for our customers.”",
          author: "Krishna Kashyap",
          position:  "Vice President",
          img: "/images/Shiseido-logo.png",
          bgColor: "#D1FFC1", // Light Green
        },
        {
          id: 8,
          text: "“CyntraLabs’ API-first approach enabled a smooth MuleSoft migration, enhancing global integration and efficiency.”",
          author: "Ricardo Gonzalez",
          position:
            "Director",
          
          img: "/images/mark_Antony.png",
          bgColor: "#C1E1FF", // Light Blue
        },
      
        {
          id: 9,
          text: "“With MuleSoft on-premise integration for SAP S/4 & financial systems, we achieved seamless connectivity & rapid deployment using factory model approach”",
          author: "Dmitriy Gerzon",
          position: "Director",
          img: "/images/QVC-Logo.png",
          bgColor: "#D1FFC1", // Light Green
        },
        {
          id: 10,
          text: "“With SAP Ariba, SAP CPI, and MuleSoft, we delivered a strategic, end-to-end integration solution, ensuring seamless API and web services connectivity.”",
          author: "Michael Kepe",
          position: "Vice-President",
          img: "/images/ContractPodAI-Logo.png",
          bgColor: "#D1FFC1", // Light Green
        },
      ];
      const TestimonialSection = () => {
        const [currentIndex, setCurrentIndex] = useState(0);
        const [visibleSlides, setVisibleSlides] = useState(3); // Default 3 slides visible
      
        // Adjust number of visible slides based on screen size
        useEffect(() => {
          const updateVisibleSlides = () => {
            if (window.innerWidth < 768) {
              setVisibleSlides(1); // Mobile: Show 1 card
            } else if (window.innerWidth < 1024) {
              setVisibleSlides(2); // Tablet: Show 2 cards
            } else {
              setVisibleSlides(3); // Desktop: Show 3 cards
            }
          };
      
          updateVisibleSlides();
          window.addEventListener("resize", updateVisibleSlides);
          return () => window.removeEventListener("resize", updateVisibleSlides);
        }, []);
      
        const totalSlides = slides.length;
      
        // Go to next slide
        const nextSlide = () => {
          setCurrentIndex((prevIndex) =>
            prevIndex + visibleSlides >= totalSlides ? 0 : prevIndex + visibleSlides
          );
        };
      
        // Go to previous slide
        const prevSlide = () => {
          setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? totalSlides - visibleSlides : prevIndex - visibleSlides
          );
        };
      
        return (
          <section className="testimonial-section">
            <div className="testimonial-header">
              <p>Testimonials</p>
              <br/>
              <div class="divider"></div>
              {/* <h2>Don’t take our word for it! <br /> Hear it from our partners.</h2> */}
            </div>
      
            {/* Testimonial Slider */}
            <div className="testimonial-slider-wrapper">
              <button className="slider-btn left" onClick={prevSlide}>
                <ChevronLeft size={32} />
              </button>
      
              <div className="testimonial-slider">
                <AnimatePresence mode="wait">
                  {slides.slice(currentIndex, currentIndex + visibleSlides).map((testimonial) => (
                    <motion.div
                      key={testimonial.id}
                      className="testimonial-card"
                      initial={{ opacity: 0, x: 100 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -100 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img src={testimonial.img} alt={testimonial.author} />
                      <p className="testimonial-text">{testimonial.text}</p>
                      <h4 className="testimonial-author">{testimonial.author}</h4>
                      <p className="testimonial-position">{testimonial.position}</p>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
      
              <button className="slider-btn right" onClick={nextSlide}>
                <ChevronRight size={32} />
              </button>
            </div>
      
            {/* Dots Navigation */}
            <div className="dots-container">
              {Array.from({ length: Math.ceil(totalSlides / visibleSlides) }).map((_, index) => (
                <div
                  key={index}
                  className={`dot ${Math.floor(currentIndex / visibleSlides) === index ? "active" : ""}`}
                  onClick={() => setCurrentIndex(index * visibleSlides)}
                ></div>
              ))}
            </div>
          </section>
        );
      };

      const sections = [
        {
          title: 'Founded on Innovation',
          content: 'CyntraLabs was built with a mission—to simplify complex digital transformations and enable businesses to scale with ease.',
          image: 'https://eadn-wc03-14239622.nxedge.io/wp-content/uploads/2024/05/team1.jpg.webp',
        },
        {
          title: 'Driven by Expertise',
          content: 'Our team of seasoned professionals brings decades of hands-on experience in enterprise technology, cloud solutions, and automation.',
          image: 'https://eadn-wc03-14239622.nxedge.io/wp-content/uploads/2024/05/team2.jpg.webp',
        },
        {
          title: 'Focused on Impact',
          content: ' We don’t just build solutions—we deliver measurable results that enhance business growth, operational efficiency, and competitive advantage.',
          image: 'https://eadn-wc03-14239622.nxedge.io/wp-content/uploads/2024/05/team3.jpg.webp',
        },
      ];
    
    //Testimonials slider
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          return nextIndex < slides.length ? nextIndex : 0;
        });
        
      }, 9000);
  
      return () => clearInterval(interval); 
    }, []);
     
       const goToSlide = (index) => {
         setCurrentIndex(index);
       };
       const [isScrolled, setIsScrolled] = useState(false);
       useEffect(() => {
         const handleScroll = () => {
           if (window.scrollY > 440) {
             setIsScrolled(true);
           } else {
             setIsScrolled(false);
           }
         };
     
         window.addEventListener("scroll", handleScroll);
         return () => window.removeEventListener("scroll", handleScroll);
       }, []);
       const mobileStyles = isMobile
       ? {
           width: "100%",
           height: "auto",
           padding: "1.5rem",
           textAlign: "left",
         }
       : {};
  return (
    <>
     <Header/>

     <section className="about-us">
      <div className="about-us-container">
        <h1 className="about-title">About Us</h1>
        <p className="about-subtitle">

        </p>
      </div>
      <hr className="about-divider" />
      <img 
        className="about-image"
        src="images\About_us-img.png"
        alt="About Us"
    />
    </section>

      {/* Our MISSION */}
      <section
        style={{
          position: "relative",
          minHeight: isMobile ? "auto" : "80vh", 
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "flex-start",
          justifyContent: "center",
          background: "#042E50",
          padding: isMobile ? "2.5rem 1.25rem" : "5rem 6.25rem", 
        }}
      >
        {/* Heading with Arrow */}
        <motion.div
          style={{
            position: "relative",
            width: "100%",
            textAlign: isMobile ? "center" : "left",
          }}
          initial={{ opacity: 0, y: 50, scale: 0.95 }}
          whileInView={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
          viewport={{ amount: 0.2 }}
        >
          <motion.h1
            style={{
              fontFamily: "Neue Haas Grotesk Display Pro;",
              fontWeight: 700,
              fontSize: isMobile ? "50px" : "12rem", 
              lineHeight: isMobile ? "60px" : "207.5px",
              letterSpacing: "0%",
              color: "#FFFFFF66",
              margin: 0,
              textAlign: "center",
            }}
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2, ease: "easeOut" }}
            viewport={{ amount: 0.2 }}
          >
            Our Mission
          </motion.h1>

          {/* Arrow Icon Animation */}
          <motion.img
            src="images/Icon.svg"
            alt="Arrow Icon"
            style={{
              position: "absolute",
              left: isMobile ? "5%" : "1.25rem", // ✅ Center arrow on mobile
              top: isMobile ? "55%" : "100%",
              width: isMobile ? "3.125rem" : "6.25rem", // ✅ Resize icon for mobile
              height: isMobile ? "4.0625rem" : "8.125rem",
              transform: isMobile ? "translateX(-50%)" : "none", // ✅ Adjust centering
              paddingTop: isMobile ? "1.25rem" : "4.375rem",
              paddingLeft: isMobile ? "0" : "9.375rem",
            }}
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
            viewport={{ amount: 0.2 }}
          />
        </motion.div>

        {/* Text Content Animation */}
        <motion.div
          style={{
            maxWidth: isMobile ? "100%" : "37.5rem", // ✅ Allow full width for mobile
            marginTop: "1.25rem",
            alignSelf: isMobile ? "center" : "flex-end", // ✅ Center text for mobile
            textAlign: isMobile ? "center" : "left", // ✅ Align text properly
          }}
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.8, ease: "easeOut" }}
          viewport={{ amount: 0.2 }}
        >
          <p
            style={{
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              fontWeight: 400,
              fontSize: isMobile ? "18px" : "35px", // ✅ Reduce font size on mobile
              lineHeight: isMobile ? "28px" : "45.9px",
              color: "#FFFFFF",
              textAlign: isMobile ? "left" : "left",
              marginTop: isMobile ? "20px" : "80px",
              marginLeft: isMobile ? "80px" : "80px",
            }}
          >
            At CyntraLabs, we are committed to
            <span style={{ fontWeight: 600, color: "#5BA5E9" }}>
              {" "}
              empowering businesses through innovation.
            </span>
            <br /> Our mission is to simplify complex systems, streamline
            operations, and enable enterprises to
            <span style={{ fontWeight: 600, color: "#5BA5E9" }}>
              {" "}
              adapt, scale, and lead in the digital-first world.
            </span>
          </p>
        </motion.div>
      </section>
 <motion.div 
          className="technologies-container"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: false }}
        >
          <div className="tech-container">
            <div className="tech-left">
            <h1>Our Partners <br /></h1>
            <h4
  style={{
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: "20px",
  }}
>
  We collaborate with leading technology providers to deliver best-in-class solutions:
</h4>

            </div>
            <div className="technologies-rightside">
              {[ "/images/Google-Cloud-Logo.png", "/images/salesforce-logo.png","/images/ServiceNow-Logo.png",, "/images/Sap-Logo.png"].map((src, index) => (
                <motion.img
                  key={index}
                  src={src} 
                  alt="Technology Logo" 
                  className="tech-logo"
                  initial={{ scale: 0.8, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  viewport={{ once: false }}
                />
              ))}
            </div>
          </div>
        </motion.div>

   {/* Grid for text and imgs */}
   <div className="text-block-wrapper">
      {/* Mobile Title */}
      <h3 className="text-block-title text-block-title__mobile">Founded on Innovation</h3>

      {/* Image Section */}
      <div className="text-block-image">
        <img
          src="/images/AboutUsOne.jpg"
          alt="Cyntra Labs Team"
        />
      </div>

      {/* Content Section */}
      <div className="text-block-content">
        <h3 className="text-block-title">Driven by Expertise</h3>
        <p className="text-block-text p-l">
        CyntraLabs was built with a mission—to simplify complex digital transformations and enable businesses to scale with ease.
        </p>
      </div>
    </div>
    <div className="text-grey-wrapper">
      {/* Content Section */}
      <div className="text-grey-content">
        <h4 className="text-grey-title">
        Focused on Impact
        </h4>
        <p className="text-grey-text">
        Our team of seasoned professionals brings decades of hands-on experience in enterprise technology, cloud solutions, and automation.
        </p>
      </div>

      {/* Image Section */}
      <div className="text-grey-image">
        <img
          src="/images/AboutUsTwo.jpg"
          alt="Cyntra Labs Team"
        />
      </div>
    </div>
    <div className="text-square-wrapper">
      {/* Image Section */}
      <div className="text-square-image">
        <img
            src="/images/AboutUsThree.jpg"
          alt="Innovation and Collaboration"
        />
      </div>

      {/* Content Section */}
      <div className="text-square-content">
        <h4 className="text-square-title">
          Culture of Innovation and Collaboration
        </h4>
        <p className="text-square-text">
        We don’t just build solutions—we deliver measurable results that enhance business growth, operational efficiency, and competitive advantage.
        </p>
      </div>
    </div>
 
    <TestimonialSection />

     
{/* Contact Us */}

<Footer/>
    </>
  );
};

export default AboutUs;