import React from "react";
import "./ServiceNowCard.css";
// import PartnerPage from "../../assets/images/Boomi.png";
import Header from "../Header";
import ServiceNowLogo from "../../assets/images/salesforce-logo.png";
import Footer from "../Footer";

const content = {
  heroSection: {
    // backgroundImage: PartnerPage,
    brand: (
      <img
        src={ServiceNowLogo}
        className="salesforce-logo"
        alt="Salesforce Logo"
        width={"100%"}
      />
    ),
    heading: "Elevate Customer Experiences with Intelligent CRM",
    description:
      "Seamlessly Connect, Automate, and Innovate with Salesforce and Cyntralabs",
    imageBoxText:
      "At Cyntralabs, we help businesses unlock the full potential of Salesforce, driving customer engagement, automation, and data-driven decision-making. Our expertise in Salesforce implementation, customization, and integration ensures organizations can scale effortlessly and optimize their CRM strategy for success.",
  },
  benefits: [
    {
      title: "Seamless CRM Integration",
      description:
        "Connect Salesforce with ERP, marketing automation, e-commerce, and other business applications to unify customer data and create a 360-degree view of your customers. Our integration solutions enhance operational efficiency and improve customer interactions.",
    },
    {
      title: "Low-Code Customization",
      description:
        "Customize Salesforce with minimal coding effort using pre-built modules, workflows, and automation tools. Empower your teams to tailor dashboards, reports, and processes to match unique business needs without complex development cycles.",
    },
    {
      title: "AI-Powered Insights with Salesforce Einstein",
      description:
        "Leverage Salesforce Einstein AI to drive intelligent decision-making, automate predictions, and personalize customer interactions. AI-driven analytics help businesses understand customer behaviors, optimize sales strategies, and boost overall revenue.",
    },
    {
      title: "Multi-Cloud & Hybrid Support",
      description:
        "Seamlessly integrate Salesforce across multiple environments, including cloud, on-premises, and hybrid infrastructures. Our solutions ensure data flows securely between Salesforce and existing enterprise applications, maintaining consistency and accuracy.",
    },
    {
      title: "Scalability for Growing Businesses",
      description:
        "Expand Salesforce capabilities as your business grows. Whether you need additional integrations, new automation workflows, or enhanced reporting, we ensure your CRM adapts to your evolving business landscape.",
    },
    {
      title: "Enterprise-Grade Security & Compliance",
      description:
        "Ensure data privacy, secure transactions, and regulatory compliance with Salesforce’s robust security features. With advanced encryption, role-based access controls, and compliance adherence, businesses can confidently manage customer data without risks.",
    },
  ],
  crmIntegration: {
    text: "Salesforce empowers businesses with a connected CRM ecosystem, enabling streamlined workflows, real-time data access, and enhanced customer engagement.",
  },
  flexibleModules: {
    text: "With Salesforce’s powerful cloud-based CRM platform, businesses can unify customer interactions, automate processes, and drive sales growth effortlessly.",
  },
  whyChooseUs: {
    title: "Why choose us?",
    points: [
      "Certified Salesforce professionals with deep industry expertise",
      "Seamless integration of Salesforce with existing enterprise systems",
      "Custom CRM solutions tailored to business goals",
      "AI-powered insights to drive automation and smarter decision-making",
      "Optimized workflows for sales, marketing, and customer service",
      "Faster time-to-market with low-code and pre-built modules",
      "Scalable solutions to match growing business needs",
      "24/7 support for CRM implementation, troubleshooting, and enhancements",
    ],
  },
};

const SalesforceCard = () => {
  return (
    <>
    <Header />
      {/* Hero Section */}
      <div
        className="card-container"
        style={{
          backgroundImage: `url(${content.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="text-box">
          <h2>
            <span className="brand">{content.heroSection.brand}</span>
          </h2>
          <h3>{content.heroSection.heading}</h3>
          <p>{content.heroSection.description}</p>
        </div>
        <div className="image-box">
          <p className="description">{content.heroSection.imageBoxText}</p>
        </div>
      </div>
      {/* Benefits Section */}
      <div className="business-benefits">
        <div className="benefits-heading">
          <h1 className="main-heading-key">Key Benefits for Your Business</h1>
          <p className="heading-sub">Tailored Solutions for Every Industry</p>
        </div>

        <div className="benefits-content">
          {content.benefits.map((benefit, index) => (
            <div className="benefit" key={index}>
              <h2 className="heading-title">{benefit.title}</h2>
              <p className="heading-descript">{benefit.description}</p>
              {index < content.benefits.length - 1 && (
                <hr className="benefit-divider" />
              )}
            </div>
          ))}
        </div>
      </div>
      {/* Flexible Modules Section */}
      <div className="container">
        <div className="content">{content.flexibleModules.text}</div>
      </div>
      {/* Why Choose Us Section */}
      <div
        style={{
          background:
            "linear-gradient(111.18deg, rgba(14, 5, 36, 0.15) 0.78%, rgba(177, 0, 72, 0.25) 97.97%)",
        }}
        className="why-choose-us"
      >
        <div className="content-choose">
          <h2 className="title-choose">{content.whyChooseUs.title}</h2>
          <div className="points-choose">
            {content.whyChooseUs.points.map((point, index) => (
              <div className="point-choose" key={index}>
                {point}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default SalesforceCard;
